import ExtendTableCard from 'components/extendableCard';
import UpDown from 'components/upDown';
import React from 'react';
import commas from '../../utils/numberWithCommas';
import useAnalyzer from '../../hooks/useAnalyzer';

const ROI = () => {
    const { totalCostReturn } = useAnalyzer();
    return (
        <div>
            <ExtendTableCard isOpen={true} className='dealinformation__1' title='TOTAL COSTS RETURN ON INVESTMENT (ROI'>
                <div className='p-40 flex items-center justify-between'>
                    <UpDown isUp={totalCostReturn() < 1 ? false : true} />
                    <div className=' brand-text font-bold' style={{ fontSize: '30px' }}>
                        {commas(totalCostReturn())}%
                    </div>
                </div>
            </ExtendTableCard>
        </div>
    );
};

export default ROI;
