import Download from "assets/svg/download.svg";
import axios from "axios";
import BatchUploadModal from "components/BatchUploadModal";
import Empty from "components/empty";
import LoadingSkeleton from "components/loadingSkeleton";
import Navbar from "components/navbar";
import PaginationLink from "components/paginationLink";
import TableCompoent from "components/table/index.js";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { setCsv } from "reducers/csv";
import { setModal } from "reducers/subscriptionModal";
import { userState } from "reducers/user";
import TableHeader from "./batchHeader";

//Analytics
import ReactGA from "react-ga";

var fileDownload = require("js-file-download");
const BatchUpload = () => {
	const tableHead = ["Date", "Count", "Name", "Download"];
	const [loading, setLoading] = React.useState(true);
	const [batch, setBatch] = React.useState<{ data: any; meta: any }>({
		data: [],
		meta: {}
	});
	const query = new URLSearchParams(window.location.search);
	const page = parseInt(query.get("page") || "1", 10);
	const [csvs, setCsvs] = React.useState<any>({
		name: ""
	});
	const { subs_info } = useSelector(userState);

	const dispatch = useDispatch();

	const [modalOpen, setModall] = React.useState<boolean>(false);

	const onClose = () => {
		setCsvs({ name: "" });
		setModall(false);
	};
	const batchUpload = () => {
		if (subs_info.batch) {
			setModall(true);
		} else {
			dispatch(setModal(true));
		}
	};
	const token = Cookies.get("token");
	React.useEffect(() => {
		ReactGA.event({
			category: "Batch Upload Page",
			action: "Page " + page
		});
		setLoading(true);
		const getBathcList = async () => {
			setLoading(true);
			const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/general/batch/get-batch-list?perpage=10&page=${page}`, {
				headers: { Authorization: `Bearer ${token}` }
			});
			setLoading(false);
			setBatch({ ...response.data });
		};
		getBathcList();
	}, [page]);

	const donwnloadBatch = async (id: any, name: any) => {
		axios
			.get(`${process.env.REACT_APP_BASE_URL}/api/v1/general/address/batch-download/${id}`, {
				headers: { Authorization: `Bearer ${token}` },
				responseType: "blob"
			})
			.then((res) => {
				fileDownload(res.data, `${id}-${name}`);
			});
	};

	const handleClick = () => {
		ReactGA.event({
			category: "Batch Upload Page",
			action: "Download Button"
		});
	};

	return (
		<div className="dashboard--wraper pt-15 pl-15 pr-15 pb-15">
			<BatchUploadModal modalOpen={modalOpen} setBatch={setBatch} batch={batch.data} onClose={onClose} csvs={csvs} setCsvs={setCsvs} setCsv={setCsv} setModall={setModall} />
			<Navbar />
			<div className="bg-white mt-20 table--top--wraper">
				<TableHeader batchUpload={batchUpload} />
				{loading ? (
					<>
						<div className="main-table-loader">
							<LoadingSkeleton Head={tableHead} />
						</div>
						<div className="responsive-table-loader">
							<LoadingSkeleton Head={["", ""]} />
						</div>
					</>
				) : !!batch.data.length ? (
					<div>
						<div className="table-container">
							<TableCompoent Th={tableHead}>
								{batch.data.map((value: any) => {
									return (
										<tr key={value.id}>
											<td>{dayjs(value.created_at).format("MMM D, YYYY")}</td>
											<td>{value.property_count}</td>
											<td>
												<Link to={`/dashboard/batch/${value.id}/${value.batch_name}/view`}>{value.batch_name} </Link>
											</td>
											<td className="flex items-center justify-center">
												<button
													title="Download"
													className="w-32 h-32 p-8 ml-8 rounded-full "
													style={{ background: "#fcede4" }}
													onClick={() => {
														handleClick();
														donwnloadBatch(value.id, value.batch_name);
													}}>
													<img src={Download} alt="Dowload batch" className="w-full h-full" />
												</button>
											</td>
										</tr>
									);
								})}
							</TableCompoent>
						</div>
						<div className="secondary-table-container">
							{batch.data.map((value: any) => {
								return (
									<div className="secondary-table-content">
										<NavLink to={`/dashboard/batch/${value.id}/${value.batch_name}/view`}>
											<div className="left">
												<p>{value.batch_name}</p>
												<p>
													Date: <span>{dayjs(value.created_at).format("MMM D, YYYY")}</span>
												</p>
											</div>
											<div className="right">
												<p>
													Count: <span>{value.property_count}</span>
												</p>
												<button
													title="Download"
													className="w-32 h-32 p-8 ml-8 rounded-full "
													style={{ background: "#fcede4" }}
													onClick={() => donwnloadBatch(value.id, value.batch_name)}>
													<img src={Download} alt="Dowload batch" className="w-full h-full" />
												</button>
											</div>
										</NavLink>
									</div>
								);
							})}
						</div>
						{batch.meta.last_page !== 1 ? (
							<div className="py-25 flex items-center justify-center">
								<PaginationLink page={page} count={batch.meta.last_page} link="/batch-upload?" />
							</div>
						) : null}
					</div>
				) : (
					<Empty />
				)}
			</div>
		</div>
	);
};

export default BatchUpload;
