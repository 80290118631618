import React from 'react';
import Streetview from 'react-google-streetview';
import { useParams } from 'react-router-dom';
function DetailsWraperImg({ center }) {
    const { search } = useParams();

    return (
        <div
            className='w-6/12 px-15 property-image'
            style={{
                minHeight: '440px',
                maxHeight: '440px',
                overflow: 'hidden',
            }}
        >
            <img
                src={`https://maps.googleapis.com/maps/api/streetview?size=600x800&location=${search}&pitch=2&fov=90&key=AIzaSyDuwIlBZp-IHpgRPVleqnDvxzzkAKC4oZs`}
                alt=''
            />
        </div>
    );
}

export default DetailsWraperImg;
