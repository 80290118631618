import React from "react";
import styled from "styled-components";
import IconFill from "./heart-fill.svg";
import Icon from "./heart.svg";

//Analytics
import ReactGA from "react-ga";

const Img = styled.img`
	margin: 0rem auto;
	cursor: pointer;
`;

function HeartIcon({ filled, changeFavourite, id, style = {} }) {
	const hanldeClick = (e) => {
		ReactGA.event({
			category: "Dashboard",
			action: e ? "Remove Favorite button" : "Favorite button"
		});
	};
	return (
		<Img
			onClick={() => {
				hanldeClick(filled);
				changeFavourite(id);
			}}
			className="w-22 h-22"
			src={filled ? IconFill : Icon}
			alt="Fovourite"
			style={style}
		/>
	);
}

export default HeartIcon;
