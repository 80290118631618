import React, { useState } from "react";
import { ExtendTableCardStyle } from "styles/Card";
import SVGicon from "../../assets/img/dropdown-arrow.svg";

//Analytics
import ReactGA from "react-ga";

function ExtendTableCard(props) {
	const [extend, setextend] = useState(props.isOpen === true ? true : false);
	const handleClick = () => {
		if (extend === false) {
			ReactGA.event({
				category: "Property Details Page",
				action: props.title
			});
		}
	};
	return (
		<ExtendTableCardStyle extend={extend} className={props.className}>
			<div
				onClick={() => {
					handleClick();
					setextend(!extend);
				}}
				className="extend--bar flex justify-between items-center h-50 px-20">
				<h4 className="fs-20">{props.title}</h4>
				<div className="extend--img h-25 w-25 flex items-center justify-center p-6">
					<img src={SVGicon} alt="extend" />
				</div>
			</div>
			<div className="extend--card">{props.children}</div>
		</ExtendTableCardStyle>
	);
}

export default ExtendTableCard;
