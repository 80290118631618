import React from 'react';

import ExtendTableCard from 'components/extendableCard';
import DealTRv3 from 'components/DealTR/dealTRv3';
import numberWithCommas from '../../utils/numberWithCommas';
import { devided, multiply } from '../../utils/math';

import useAnalyzer from '../../hooks/useAnalyzer';
const HoldingCost = () => {
    const { propertyTaxes, HOACondoFees, insurance, utilities, totalMonthlyHoldingCosts } = useAnalyzer();
    return (
        <div className='w-6/12 px-15'>
            <ExtendTableCard className='dealinformation__1' title='HOLDING COSTS' isOpen={true}>
                <div className='border'>
                    <div>
                        <table className='fs-14' style={{ width: '100%' }}>
                            <tr className='h-30 brand-text bg-white'>
                                <th className='w-6/12'></th>
                                <th>Annually</th>

                                <th>Monthly</th>
                            </tr>
                            <DealTRv3
                                right='Property Taxes'
                                middle={<input type='text' value={'$' + numberWithCommas(propertyTaxes)} />}
                                input={<input type='text' value={'$' + numberWithCommas(devided(propertyTaxes, 12, 2))} />}
                            />
                            <DealTRv3
                                right='HOA & Condo Fees'
                                middle={<input type='text' value={'$' + numberWithCommas(multiply(HOACondoFees, 12, 2))} />}
                                input={<input type='text' value={'$' + numberWithCommas(HOACondoFees)} />}
                            />
                            <DealTRv3
                                right='Insurance Costs'
                                middle={<input type='text' value={'$' + numberWithCommas(insurance)} />}
                                input={<input type='text' value={'$' + numberWithCommas(devided(insurance, 12, 2))} />}
                            />
                            <DealTRv3
                                right='Utility Costs'
                                middle={<input type='text' value={'$' + numberWithCommas(multiply(utilities, 12, 2))} />}
                                input={<input type='text' value={'$' + numberWithCommas(utilities)} />}
                            />
                        </table>
                        <div className='h-50 flex justify-between items-center bg_brand2 text-white'>
                            <div className='fs-18 w-7/12 text-right pr-15 '>Total Monthly Holding Costs</div>
                            <div className='fs-20 w-5/12 text-center'>${numberWithCommas(totalMonthlyHoldingCosts())}</div>
                        </div>
                    </div>
                </div>
            </ExtendTableCard>
        </div>
    );
};

export default HoldingCost;
