import { DataCard, DataCardList } from "components/datacard";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { multiply } from "utils/math";
import numberWithCommas from "utils/numberWithCommas";
function DetailsWraperDataCard({
	loading,
	estated: { valuation },
	airDna: {
		property_stats: { adr, occupancy, revenue },
	},
	realtyMole: { rent }
}) {
	const ViewSkaleton = (value) => {
		return loading ? (
			<div style={{ width: "70px" }}>
				<Skeleton width="100" />
			</div>
		) : (
			value
		);
	};
	return (
		<div className="flex -mx-10 details-card-container">
			<DataCard barColor="#3863A5" color="white" barTitle="Value" class="w-4/12 px-10 details-card">
				<div className="card--inner-box">
					<DataCardList title="Estimated Value" value={valuation.value ? ViewSkaleton("$" + numberWithCommas(valuation.value)) : "-"} className="px-20 h-42" />
					<DataCardList title="Estimated Value High" value={valuation.high ? ViewSkaleton("$" + numberWithCommas(valuation.high)) : "-"} className="px-20 h-42" />
					<DataCardList title="Estimated Value Low" value={valuation.low ? ViewSkaleton("$" + numberWithCommas(valuation.low)) : "-"} className="px-20 h-42" />
				</div>
			</DataCard>
			<DataCard barColor="#8983FF" color="white" barTitle="Traditional Rental" class="w-4/12 px-10 details-card">
				<div className="card--inner-box">
					<DataCardList title="Annual Revenue" value={ViewSkaleton("$" + numberWithCommas(multiply(rent, 12, 2)))} className="px-20 h-42" />
					<DataCardList title="Monthly Rental" value={ViewSkaleton("$" + numberWithCommas(rent))} className="px-20 h-42" />
					<DataCardList title="Rental Cap" value={ViewSkaleton(valuation.value ? (((rent * 12) / valuation.value) * 100).toFixed(2) : 0 + "%")} className="px-20 h-42" />
				</div>
			</DataCard>
			<DataCard barColor="#50BFD8" color="white" barTitle="Short-term Rental" class="w-4/12 px-10 details-card">
				<div className="card--inner-box">
					<DataCardList title="STR Annual Revenue" value={ViewSkaleton("$" + numberWithCommas(revenue.ltm))} className="px-20 h-42" />
					<DataCardList title="Average Daily Rate" value={ViewSkaleton("$" + numberWithCommas(adr.ltm))} className="px-20 h-42" />
					<DataCardList title="Occupancy" value={ViewSkaleton((occupancy.ltm * 100).toFixed(2) + "%")} className="px-20 h-42" />
					<DataCardList title="STR Cap" value={ViewSkaleton(valuation.value ? (((adr.ltm * 365) / valuation.value) * 100).toFixed(2) : 0 + "%")} className="px-20 h-42" />
				</div>
			</DataCard>
		</div>
	);
}

export default DetailsWraperDataCard;
