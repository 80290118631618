import Incisible from 'assets/svg/invisible.svg';
import View from 'assets/svg/view.svg';
import React from 'react';

type Props = {
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    value: string;
    placeholder?: string;
    name: string;
};

type InputType = 'password' | 'text';

const PasswordInput: React.FC<Props> = ({ onChange, value, placeholder, name }) => {
    const [type, setType] = React.useState<InputType>('password');

    return (
        <div className='signin-input flex w-full h-50 pl-26 overflow-hidden'>
            <div className='input--img'>
                <img
                    className='w-20 h-20 mr-15 '
                    src='https://cdn.zeplin.io/5ef9b0be7b4748819ae0decf/assets/ce466f72-9b3b-44d3-bf2e-338937d5fc98.svg'
                    alt=''
                />
            </div>
            <input
                name={name}
                onChange={onChange}
                value={value}
                className='w-full h-full outline-none'
                type={type}
                placeholder={placeholder}
                required={true}
            />
            <div className='input--img cursor-pointer' onClick={() => setType(type === 'password' ? 'text' : 'password')}>
                <img className='w-20 h-20 mr-15 ' src={type === 'password' ? View : Incisible} alt='view' />
            </div>
        </div>
    );
};

export default PasswordInput;
