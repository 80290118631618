import React from "react";
import Chart from "react-apexcharts";

export default class BarChart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			series: [
				{
					name: props.title,
					data: props.data
				}
			],
			options: {
				chart: {
					zoom: {
						enabled: false
					},
					toolbar: {
						show: false
					}
				},
				fill: {
					colors: props.colors
				},
				plotOptions: {
					bar: {
						borderRadius: 4,
						horizontal: true,
						distributed: true
					}
				},
				dataLabels: {
					enabled: false
				},
				xaxis: {
					categories: props.labels
				},
				grid: {
					show: false
				},
				legend: {
					show: false
				},
				responsive: [
					{
						breakpoint: 468,
						options: {
							chart: {
								width: "100%",
								height: 300
							},
							plotOptions: {
								bar: {
									horizontal: false
								}
							}
						}
					}
				]
			}
		};
	}

	render() {
		return (
			<div className="line-chart-container">
				<Chart options={this.state.options} series={this.state.series} type="bar" height={this.props.height} width={this.props.width} />
			</div>
		);
	}
}
