import React from 'react';
import DealTR from 'components/DealTR/dealTR';
import ExtendTableCard from 'components/extendableCard';
import numberWithCommas from '../../utils/numberWithCommas';
import useAnalyzer from '../../hooks/useAnalyzer';
const PropertyValues = () => {
    const { afterRepairValue, estimatedRepairs, purchasePrice, holdTime, totalPurchaseAndRepairCost } = useAnalyzer();
    return (
        <div className='w-6/12 px-15'>
            <ExtendTableCard className='dealinformation__1' title='PROPERTY VALUES & PRICING' isOpen={true}>
                <div className='border'>
                    <div>
                        <table className='fs-14' style={{ width: '100%' }}>
                            <DealTR
                                right='After Repair Value'
                                input={<input type='text' value={'$' + numberWithCommas(afterRepairValue)} />}
                            />
                            <DealTR
                                right='Current "As Is" Value'
                                input={
                                    <input
                                        type='text'
                                        value={'$' + numberWithCommas((afterRepairValue * 0.7 - estimatedRepairs).toFixed(0))}
                                    />
                                }
                            />
                            <DealTR
                                right='Estimated Repair Costs'
                                input={<input type='text' value={'$' + numberWithCommas(estimatedRepairs)} />}
                            />
                            <DealTR right='Purchase Price ' input={<input type='text' value={'$' + numberWithCommas(purchasePrice)} />} />
                            <DealTR right='Estimated Hold Time (months)' border={true} input={<input type='text' value={holdTime} />} />
                        </table>
                        <div className='h-50 flex justify-between items-center bg_brand2 text-white'>
                            <div className='fs-18 w-7/12 text-right pr-15 '>Total Purchase & Repair Cost</div>
                            <div className='fs-20 w-5/12 text-center'>${numberWithCommas(totalPurchaseAndRepairCost())}</div>
                        </div>
                    </div>
                </div>
            </ExtendTableCard>
        </div>
    );
};

export default PropertyValues;
