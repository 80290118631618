import { createSlice } from '@reduxjs/toolkit';
export const propertyInformation = createSlice({
    name: 'propertyInformation',
    initialState: {
        strAnnaulRevenue: 29000,
        strMonthly: 2000,
        totalSqft: 18550,
        purchasePrice: 210000,
        rentalIncome: 1200,
        exitStrategy: 0,
        afterRepairValue: 300000,
        holdTime: 6,
        estimatedRepairs: 30000,
        closingCosts: 4000,
        loantoValue: 80,
        loanTerminMonths: 360,
        mortgageInterest: 4.5,
        capitalExpenses: 5,
        estimatedMaintenanceCosts: 5,
        rentalEstimatedVacancyRate: 7,
        rentalManagementFee: 15,
        STRManagementFee: 20,
        STRFurnishings: 14000,
        titleInsurance: 0.25,
        miscellaneousBuyingCosts: 0.0,
        escrowAttorneyFees: 500,
        propertyTaxes: 1340,
        insurance: 500,
        utilities: 200,
        HOACondoFees: 0.0,
        firstMortgagePoints: 80,
        firstMortgagePoints2: 1,
        firstMortgageInterest: 40,
        secondMortgagePoints: 2,
        secondMortgageInterest: 15,
        miscMortgageLienAmount: 0,
        miscMortgagePoints: 0,
        miscMortgageInterest: 0,
        miscellaneousFinancingCosts: 0,
        escrowAttorneyFees: 500,
        sellingRecordingFees: 350,
        realtorFees: 2,
        transferConveyanceFees: 0.12,
        homeWarranty: 500,
        stagingCosts: 0,
        marketingCosts: 500,
        miscellaneousSellingCosts: 0,
        managementPercent : 6
    },
    reducers: {
        setInformation: (state, action) => {
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { setInformation } = propertyInformation.actions;
export const PropertyInformationState = (state) => state.propertyInformationReducer;
export default propertyInformation.reducer;
