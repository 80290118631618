import HearIcon from "components/HeartIcon";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import numberWithCommas from "utils/numberWithCommas";
const PropertyTR = ({ value, changeFavourite }: { value: any; changeFavourite: Function }) => {
	return (
		<>
			<tr key={value.id}>
				<td>
					<HearIcon filled={value.favorite} changeFavourite={changeFavourite} id={value.id} />
				</td>
				<td>{dayjs(value.date).format("MMM D, YYYY")}</td>
				<td>
					{value.str_cpa_used ? <span className="str__tag">STR</span> : null}
					<Link to={`/dashboard/details/${value.mainAddress}/${value.id}/view/`}>{value.address} </Link>
				</td>
				{/* <td className='text-center'>{value.bed}</td>
                <td>{value.barth}</td> */}
				<td>${numberWithCommas(value.est)}</td>
				<td>${numberWithCommas(value.rent)}</td>
				<td>${numberWithCommas(value.str_year)}</td>
				<td>{value.rent_cpa == "00" ? 0 : value.rent_cpa.toFixed(2)}%</td>
				<td>{value.str_cpa == "00" ? 0 : value.str_cpa.toFixed(2)}%</td>
			</tr>
		</>
	);
};

export default PropertyTR;
