import { DataCardList } from "components/datacard";
import ExtendTableCard from "components/extendableCard";
import React from "react";
import Deeds from "./Deeds/deeds";
import Financials from "./Financials/financials";

function DetailsWraperInformation({
	structure: {
		construction_type,
		heating_type,
		basement_type,
		parking_type,
		parking_spaces_count,
		air_conditioning_type
		// basement_type,
	},
	owner,
	parcel,
	taxes,
	assessments,
	market_assessments,
	deeds
}) {
	return (
		<div className="mt-20 extend-card-group">
			<ExtendTableCard title="Property Information" className="mb-15">
				<DataCardList title="Parking Type" value={parking_type || ""} className="px-20 h-42" />
				<DataCardList title="Parking Spaces Count" value={parking_spaces_count || ""} className="px-20 h-42" />
				<DataCardList title="Air Conditioning Type" value={air_conditioning_type || ""} className="px-20 h-42" />
				<DataCardList title="Basement Type" value={basement_type || ""} className="px-20 h-42" />
				{construction_type && <DataCardList title="Construction Type" value={construction_type || ""} className="px-20 h-42" />}
				<DataCardList title="Heating Type" value={heating_type || ""} className="px-20 h-42" />
			</ExtendTableCard>
			<ExtendTableCard title="County Info" className="mb-15">
				<DataCardList title="County Name" value={parcel?.county_name} className="px-20 h-42" />
				<DataCardList title="County Land Use Code" value={parcel?.county_land_use_code} className="px-20 h-42" />
				<DataCardList title="County Land Use Drescription" value={parcel?.county_land_use_description} className="px-20 h-42" />
				<DataCardList title="Zoning" value={parcel?.zoning} className="px-20 h-42" />
				<DataCardList title="Apn Origin" value={parcel?.apn_original} className="px-20 h-42" />
			</ExtendTableCard>
			<ExtendTableCard title="Owner" className="mb-15">
				<DataCardList title="Owner Occupied" value={owner?.owner_occupied} className="px-20 h-42" />
				<DataCardList title="Owner Name" value={owner?.name} className="px-20 h-42" />
				<DataCardList title="Owner Formatted Street Address" value={owner?.formatted_street_address} className="px-20 h-42" />
				<DataCardList title="Owner City" value={owner?.city} className="px-20 h-42" />
				<DataCardList title="Owner State" value={owner?.state} className="px-20 h-42" />
				<DataCardList title="Owner Zip Code" value={owner?.zip_code} className="px-20 h-42" />
			</ExtendTableCard>
			<ExtendTableCard title="Deeds" className="mb-15">
				{deeds.length > 0 ? <Deeds deed={deeds[deeds.length - 1]} /> : null}
			</ExtendTableCard>
			<ExtendTableCard title="Financials" className="mb-15">
				<Financials taxes={taxes} assessments={assessments} market_assessments={market_assessments} />
			</ExtendTableCard>
		</div>
	);
}

export default DetailsWraperInformation;
