import BreadCumb from "components/BreadCumb";
import HeadLine from "components/details/HeadLine";
import Navbar from "components/navbar";
import React from "react";
import { useSelector } from "react-redux";
// import axios from 'axios';
import { useParams } from "react-router-dom";
import { singlePropertyState } from "reducers/singleProperty";
import { ContainerBox } from "styles/Box";
import DetailsBody from "./DetailsWraper";

function Details() {
	const {
		estated: { data }
	} = useSelector(singlePropertyState);
	const { search } = useParams();
	return (
		<div className="dashboard--wraper pt-15 pl-15 pr-15 pb-15">
			<Navbar />
			<BreadCumb
				breadCumb={[
					{ name: "New Listing", link: "/profile" },
					{ name: "Property List View", link: "/dashboard" }
				]}
			/>
			<ContainerBox className="p-35 page-main-cotainer">
				<HeadLine name={data.address.formatted_street_address} city={data.address.city} state={data.address.state} zip={data.address.zip_code} />
				<DetailsBody />
			</ContainerBox>
		</div>
	);
}

export default Details;
