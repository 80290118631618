import "./marketSaleListing.css";
import numberWithCommas from "utils/numberWithCommas";
import TableCompoent from "../../../../components/table/index.js";
import dayjs from "dayjs";

const MarketSaleListing = (props) => {
	const saleListings = JSON.parse(props.data.sale_listing);
	const TableHead = ["Address", "Bedroom", "Bathroom", "Square foot", "Price", "Days on Market", "Property Type", "Listed Date"];

	const Table = () => {
		return (
			<div className="market-sale-listing-main-container">
				<div className="market-sale-listing-container">
					{saleListings && saleListings.length > 0 ? (
						<>
							<div className="table-container">
								<TableCompoent Th={TableHead}>
									{saleListings.map((list: any, index) => {
										if (list.propertyType !== "Land") {
											return (
												<tr key={index}>
													<td>{list.formattedAddress}</td>
													<td>{list.bedrooms ? list.bedrooms : 0}</td>
													<td>{list.bathrooms ? list.bathrooms : 0}</td>
													<td>{numberWithCommas(list.squareFootage ? list.squareFootage : 0)}</td>
													<td>{"$" + numberWithCommas(list.price ? list.price : 0)}</td>
													<td>{list.daysOnMarket}</td>
													<td>{list.propertyType}</td>
													<td>{dayjs(list.listedDate).format("DD-MM-YYYY")}</td>
												</tr>
											);
										}
									})}
								</TableCompoent>
							</div>
							<div className="secondary-table-container">
								{saleListings.map((list: any) => {
									return (
										<div className="secondary-table-content">
											<div className="top">
												<div className="left">
													<p>{list.formattedAddress}</p>
												</div>
												<div className="right">
													<p>
														Beds: <span>{list.bedrooms ? list.bedrooms : 0}</span>
													</p>
													<p>
														Bathrooms: <span>{list.bathrooms ? list.bathrooms : 0}</span>
													</p>
												</div>
											</div>
											<div className="bottom">
												<div className="left">
													<p>
														Square foot: <span>{numberWithCommas(list.squareFootage ? list.squareFootage : 0)}</span>
													</p>
												</div>
												<div className="right">
													<p>
														Price: <span>{"$" + numberWithCommas(list.price ? list.price : 0)}</span>
													</p>
												</div>
											</div>
											<div className="top">
												<div className="left">
													<p>Property Type: {list.propertyType}</p>
												</div>
												<div className="right">
													<p>
														Date: <span>{dayjs(list.listedDate).format("DD-MM-YYYY")}</span>
													</p>
													<p>
														Market Days: <span>{list.daysOnMarket}</span>
													</p>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</>
					) : (
						<p>No data found</p>
					)}
				</div>
			</div>
		);
	};
	return (
		<div style={{ overflowX: "scroll", transform: "rotateX(180deg)" }}>
			<div style={{ transform: "rotateX(180deg)" }}>
				<Table />
			</div>
		</div>
	);
};

export default MarketSaleListing;
