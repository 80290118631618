import React from 'react';
import ExtendTableCard from 'components/extendableCard';
import DealTRv3 from 'components/DealTR/dealTRv3';
// import CalculationInput from 'components/calculationInput/calculationInput';

import numberWithCommas from '../../utils/numberWithCommas';
import useAnalyzer from '../../hooks/useAnalyzer';
const BuyingCost = () => {
    const { escrowAttorneyFees, titleInsurance, miscellaneousBuyingCosts, gettitleInsurance, totalBuyingTransactionCost } = useAnalyzer();

    return (
        <div>
            <ExtendTableCard className='dealinformation__2' title='Buying Transaction Costs' isOpen={true}>
                <div className='border'>
                    <div>
                        <table className='fs-14' style={{ width: '100%' }}>
                            <tr className='h-30 brand-text bg-white'>
                                <th className='w-6/12'></th>
                                <th>% of Purchase</th>

                                <th>Total</th>
                            </tr>
                            <DealTRv3
                                right='Escrow / Attorney Fees '
                                middle={<span />}
                                input={<input type='text' value={'$' + numberWithCommas(escrowAttorneyFees)} />}
                            />
                            <DealTRv3
                                right='Title Insurance / Search Costs'
                                middle={<input type='text' value={titleInsurance + '%'} />}
                                input={<input type='text' value={'$' + numberWithCommas(gettitleInsurance())} />}
                            />
                            <DealTRv3
                                right='Miscellaneous Buying Costs'
                                middle={<span />}
                                input={<input type='text' value={'$' + numberWithCommas(miscellaneousBuyingCosts)} />}
                            />
                        </table>
                        <div className='h-50 flex justify-between items-center  text-white' style={{ background: '#7b87ae' }}>
                            <div className='fs-18 w-7/12 text-right pr-15 '>Total Buying Transaction Cost</div>
                            <div className='fs-20 w-5/12 text-center'>${numberWithCommas(totalBuyingTransactionCost())}</div>
                        </div>
                    </div>
                </div>
            </ExtendTableCard>
        </div>
    );
};

export default BuyingCost;
