import styled from 'styled-components';

export const DataCardStyle = styled.div`
    .data--card--bar {
        background: ${(props) => props.barColor};
        color: ${(props) => props.color};
        height: 5rem;
        padding: 0rem 2rem;
        border-top-left-radius: 1.2rem;
        border-top-right-radius: 1.2rem;
    }
`;

export const CardListStyle = styled.div`
    .card--list-title {
        font-size: ${(props) => (props.titleSize ? props.titleSize : '1.6rem')};
        color: ${(props) => (props.titleColor ? props.titleColor : '#6c79a3')};
    }
    .card--list-value {
        font-size: ${(props) => (props.titleSize ? props.titleSize : '1.6rem')};
        color: ${(props) => (props.titleColor ? props.titleColor : 'black')};
    }
`;
export const ExtendTableCardStyle = styled.div`
    .extend--bar {
        cursor: pointer;
        background: #eaefff;
        border-radius: 1.3rem;
        .extend--img {
            border-radius: 50%;
            transform: ${(props) => (props.extend ? ' rotate(180deg)' : 'rotate(360deg)')};
            background: #bcc8f2;
            transition: ease-in-out;
            transition-duration: 0.5s;
            transition-delay: 0s;
            transition-property: transform;
        }
    }
    .extend--card {
        background: #fafbff;
        overflow: hidden;
        max-height: ${(props) => (props.extend ? '900px' : '0px')};
        transition: ease-in-out;
        transition-duration: 1s;
        transition-delay: 0s;
        transition-property: max-height;
    }
`;
