import CalculationInput from 'components/calculationInput/calculationInput';
import DealTR from 'components/DealTR/dealTR';
import ExtendTableCard from 'components/extendableCard';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropertyInformations, setInformation } from '../../reducers/projectDetials';

const ProjectDetails = () => {
    const dispatch = useDispatch();
    const PropertyInformation = useSelector(PropertyInformations);

    const onChange = (event: any) => {
        dispatch(setInformation({ name: event.target.name, value: event.target.value }));
    };

    return (
        <div className='w-6/12 px-15'>
            <ExtendTableCard className='dealinformation__1' title='Project Details' isOpen={true}>
                <div className='border'>
                    <table className='fs-14' style={{ width: '100%' }}>
                        <DealTR right='Date' input={<input type='text' value='12-12-2020' />} />
                        <DealTR
                            right='Vacant'
                            input={
                                <select value={PropertyInformation?.vacant} name='vacant' className='analyzer__input' onChange={onChange}>
                                    <option value=''></option>
                                    <option value='yes'>Yes</option>
                                    <option value='no'>No</option>
                                </select>
                            }
                        />
                        <DealTR
                            right='Evaluator'
                            input={
                                <input
                                    onChange={onChange}
                                    type='text'
                                    name='evaluator'
                                    value={PropertyInformation?.evaluator}
                                    className='analyzer__input'
                                />
                            }
                        />
                        <DealTR
                            right='Closing Date'
                            input={
                                <input
                                    onChange={onChange}
                                    type='date'
                                    name='closingDate'
                                    value={PropertyInformation?.closingDate}
                                    className='analyzer__input'
                                />
                            }
                        />
                        <DealTR right='Exit Strategy' border={true} input={<CalculationInput name='exitStrategy' type='text' />} />
                    </table>
                </div>
            </ExtendTableCard>
        </div>
    );
};

export default ProjectDetails;
