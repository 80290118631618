import React from 'react';
import TableHeaderBar from '../../components/tableHeaderBar';

function TableHeaderRightComponent() {
    return <button></button>;
}

function TableHeader(props) {
    return (
        <div>
            <TableHeaderBar title={props.name} HeaderRightComponent={TableHeaderRightComponent} />
        </div>
    );
}

export default TableHeader;
