import React from 'react';

export default function Recent() {
    return (
        <div>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='21.074'
                height='21.1'
                viewBox='0 0 21.074 21.1'
            >
                <g
                    id='_004-loupe'
                    data-name='004-loupe'
                    transform='translate(-8.4 -10.4)'
                >
                    <path
                        id='Path_1280'
                        d='M22.156 28.216a5.357 5.357 0 0 0-1.528 4.3.805.805 0 0 0 .777.7h.078a.784.784 0 0 0 .7-.855 3.88 3.88 0 0 1 1.061-3.061.751.751 0 0 0 0-1.088.827.827 0 0 0-1.088.004z'
                        className='cls-1'
                        data-name='Path 1280'
                        transform='translate(-9.035 -13.038)'
                    />
                    <path
                        id='Path_1281'
                        d='M29.24 30.15l-5.113-5.139a8.909 8.909 0 1 0-1.116 1.116l5.139 5.139a.753.753 0 0 0 1.09 0 .782.782 0 0 0 0-1.116zm-6.7-5.71l-.052.052-.052.052a7.341 7.341 0 1 1 2.211-5.244 7.42 7.42 0 0 1-2.102 5.141z'
                        className='cls-1'
                        data-name='Path 1281'
                    />
                </g>
            </svg>
        </div>
    );
}
