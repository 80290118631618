import React, { Component } from "react";
import "./financials.css";

export default class Financials extends Component {
	exemptionList = (e) => {
		var dataToShow = "";
		e.map((ex, index) => {
			if (index < e.length - 1) {
				dataToShow = dataToShow + " " + ex + ",";
			} else {
				dataToShow = dataToShow + " " + ex + ".";
			}
		});
		if (dataToShow.length === 0) {
			dataToShow = "-";
		}
		return dataToShow;
	};

	render() {
		var { taxes, assessments, market_assessments } = this.props;
		return (
			<div className="financials-container">
				{taxes.length > 0 ? (
					<div className="financials-section">
						<h1>Taxes</h1>
						<div className="financials-section-content-main">
							<div className="financials-section-content-header">
								<p>Year</p>
								<p>Amount</p>
								<p>Exemptions</p>
								<p>Rate Code Area</p>
							</div>
							{taxes.map((t, index) => {
								return (
									<div className="financials-section-content-body" key={index}>
										<p>{t.year}</p>
										<p>{t.amount ? "$" + t.amount : "-"}</p>
										<p>{this.exemptionList(t.exemptions)}</p>
										<p>{t.rate_code_area}</p>
									</div>
								);
							})}
						</div>
						<div className="financials-section-content-secondary">
							{taxes.map((t, index) => {
								return (
									<div className="financials-section-content-body" key={index}>
										<div className="financials-section-content-portion">
											<div className="financials-section-content-portion-left">Year</div>
											<div className="financials-section-content-portion-right">{t.year}</div>
										</div>
										<div className="financials-section-content-portion">
											<div className="financials-section-content-portion-left">Amount</div>
											<div className="financials-section-content-portion-right">{t.amount ? "$" + t.amount : "-"}</div>
										</div>
										<div className="financials-section-content-portion">
											<div className="financials-section-content-portion-left">Exemptions</div>
											<div className="financials-section-content-portion-right">{this.exemptionList(t.exemptions)}</div>
										</div>
										<div className="financials-section-content-portion">
											<div className="financials-section-content-portion-left">Rate Code Area</div>
											<div className="financials-section-content-portion-right">{t.rate_code_area}</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				) : null}
				{market_assessments.length > 0 ? (
					<div className="financials-section">
						<h1>Market Assessments</h1>
						<div className="financials-section-content-main">
							<div className="financials-section-content-header">
								<p>Year</p>
								<p>Land Value</p>
								<p>Improvement Value</p>
								<p>Total Value</p>
							</div>
							{market_assessments.map((m, index) => {
								return (
									<div className="financials-section-content-body" key={index}>
										<p>{m.year}</p>
										<p>{m.land_value ? "$" + m.land_value : "-"}</p>
										<p>{m.improvement_value ? "$" + m.improvement_value : "-"}</p>
										<p>{m.total_value ? "$" + m.total_value : "-"}</p>
									</div>
								);
							})}
						</div>
						<div className="financials-section-content-secondary">
							{market_assessments.map((m, index) => {
								return (
									<div className="financials-section-content-body" key={index}>
										<div className="financials-section-content-portion">
											<div className="financials-section-content-portion-left">Year</div>
											<div className="financials-section-content-portion-right">{m.year}</div>
										</div>
										<div className="financials-section-content-portion">
											<div className="financials-section-content-portion-left">Land Value</div>
											<div className="financials-section-content-portion-right">{m.land_value ? "$" + m.land_value : "-"}</div>
										</div>
										<div className="financials-section-content-portion">
											<div className="financials-section-content-portion-left">Improvement Value</div>
											<div className="financials-section-content-portion-right">{m.improvement_value ? "$" + m.improvement_value : "-"}</div>
										</div>
										<div className="financials-section-content-portion">
											<div className="financials-section-content-portion-left">Total Value</div>
											<div className="financials-section-content-portion-right">{m.total_value ? "$" + m.total_value : "-"}</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				) : null}
				{assessments.length > 0 ? (
					<div className="financials-section">
						<h1>Assessments</h1>
						<div className="financials-section-content-main">
							<div className="financials-section-content-header">
								<p>Year</p>
								<p>Land Value</p>
								<p>Improvement Value</p>
								<p>Total Value</p>
							</div>
							{assessments.map((a, index) => {
								return (
									<div className="financials-section-content-body" key={index}>
										<p>{a.year}</p>
										<p>{a.land_value ? "$" + a.land_value : "-"}</p>
										<p>{a.improvement_value ? "$" + a.improvement_value : "-"}</p>
										<p>{a.total_value ? "$" + a.total_value : "-"}</p>
									</div>
								);
							})}
						</div>
						<div className="financials-section-content-secondary">
							{assessments.map((a, index) => {
								return (
									<div className="financials-section-content-body" key={index}>
										<div className="financials-section-content-portion">
											<div className="financials-section-content-portion-left">Year</div>
											<div className="financials-section-content-portion-right">{a.year}</div>
										</div>
										<div className="financials-section-content-portion">
											<div className="financials-section-content-portion-left">Land Value</div>
											<div className="financials-section-content-portion-right">{a.land_value ? "$" + a.land_value : "-"}</div>
										</div>
										<div className="financials-section-content-portion">
											<div className="financials-section-content-portion-left">Improvement Value</div>
											<div className="financials-section-content-portion-right">{a.improvement_value ? "$" + a.improvement_value : "-"}</div>
										</div>
										<div className="financials-section-content-portion">
											<div className="financials-section-content-portion-left">Total Value</div>
											<div className="financials-section-content-portion-right">{a.total_value ? "$" + a.total_value : "-"}</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				) : null}
			</div>
		);
	}
}
