import CalculationInput from 'components/calculationInput/calculationInput';
import DealTR from 'components/DealTR/dealTR';
import ExtendTableCard from 'components/extendableCard';
import React from 'react';
import { useSelector } from 'react-redux';
import { PropertyInformationState } from '../../reducers/propertyInformation';
import { devided } from '../../utils/math';
import numberWithCommas from '../../utils/numberWithCommas';
const DealFactors = () => {
    const { rentalIncome, strAnnaulRevenue } = useSelector(PropertyInformationState);
    return (
        <div className='w-4/12 px-15'>
            <ExtendTableCard className='dealinformation__2' title='DEAL FACTORS' isOpen={true}>
                <div className='border'>
                    <h3 className='bg-white border-bottom fs-16 py-4 px-10 brand-text fw-500'> TRADITIONAL RENTAL </h3>

                    <div>
                        <table className='fs-14' style={{ width: '100%' }}>
                            <DealTR right='Rental Income (Monthly)' input={<CalculationInput type='currency' name='rentalIncome' />} />
                            <DealTR
                                right='Rental Income (Annually)'
                                input={<input type='text' value={numberWithCommas(rentalIncome * 12)} />}
                            />
                            <DealTR
                                right='Rental Estimated Vacancy Rate'
                                input={<CalculationInput type='percentage' name='rentalEstimatedVacancyRate' />}
                            />
                            <DealTR
                                border={true}
                                right='Rental Management Fee (% of Rent):  '
                                input={<CalculationInput type='percentage' name='rentalManagementFee' />}
                            />
                        </table>
                    </div>
                    <h3 className='bg-white border-top border-bottom fs-16 py-4 px-10 brand-text fw-500'> VACATION RENTAL </h3>
                    <table className='fs-14' style={{ width: '100%' }}>
                        <DealTR
                            right='STR Average Monthly'
                            input={<input type='text' value={'$' + numberWithCommas(devided(strAnnaulRevenue, 12))} />}
                        />

                        <DealTR right='STR Annual Revenue  ' input={<CalculationInput type='currency' name='strAnnaulRevenue' />} />
                        <DealTR
                            right='STR Management Fee (% of Rent)'
                            input={<CalculationInput type='percentage' name='STRManagementFee' />}
                        />
                        <DealTR right='STR Furnishings' border={true} input={<CalculationInput name='STRFurnishings' />} />
                    </table>
                </div>
            </ExtendTableCard>
        </div>
    );
};

export default DealFactors;
