import React from 'react';
import FreshChat from 'react-freshchat';
import { useSelector } from 'react-redux';
import { userState } from 'reducers/user';
const FreshChatDom = () => {
    const user = useSelector(userState);

    return (
        <div>
            <FreshChat
                token={'283e832b-1c81-4b8e-99e4-3674f526ec63'}
                email={user.email}
                first_name={user.first_name}
                id={user.id}
                onInit={(widget) => {
                    widget.setExternalId(user.id);

                    widget.user.setFirstName(user.first_name);

                    widget.user.setEmail(user.first_name);

                    widget.user.setProperties({
                        email: user.email,
                        first_name: user.first_name,
                        last_name: user.last_name,
                        phone: user.phone,
                        status: 'Active',
                        plan: user?.subs_info?.subs_package,
                    });
                }}
            />
        </div>
    );
};
export default FreshChatDom;
