import axios from 'axios';
import Cookies from 'js-cookie';
import React from 'react';
const CurrentUsages = () => {
    const token = Cookies.get('token');
    const [history, setHistory] = React.useState({
        comp_usages_cost: 0,
        over_usages_cost: 0,
        totall_bill_amount: 0,
        usages_detals: {
            search_use: 0,
            total_cost: 0,
            comp_use: 0,
        },
    });

    React.useEffect(() => {
        const getHistory = async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/api/v1/general/usages/current/get
            `,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            setHistory(response.data.data);
        };
        getHistory();
    }, []);
    return (
        <div className='pakages mt-10 mb-10'>
            <div className='pakages--wraper'>
                <div className='pakages--tab h-54 fs-16 fw-500 f-p '>
                    <div>Current Usage</div>
                </div>
                <div className='p-20'>
                    <div>
                        <div className='flex items-center justify-between mb-15'>
                            <div className='fs-16'>Properties Searched</div>
                            <div className='fs-16 brand-text'>{history.usages_detals.search_use}</div>
                        </div>

                        <div className='flex items-center justify-between mb-15'>
                            <div className='fs-16'>Overage Cost</div>
                            <div className='fs-16 brand-text'>${history.over_usages_cost}</div>
                        </div>
                        <div className='flex items-center justify-between mb-15'>
                            <div className='fs-16'># of Comps </div>
                            <div className='fs-16 brand-text'>{history.usages_detals.comp_use}</div>
                        </div>
                        <div className='flex items-center justify-between mb-15'>
                            <div className='fs-16'>Comp Cost</div>
                            <div className='fs-16 brand-text'>${history.comp_usages_cost}</div>
                        </div>
                        <div className='flex items-center justify-between mb-15'>
                            <div className='fs-16'>Current Total </div>
                            <div className='fs-16 brand-text'>${history.totall_bill_amount}</div>
                        </div>

                        {/* <div className='flex items-center justify-between mb-15'>
                            <div className='fs-16'>Total Cost</div>
                            <div className='fs-16 brand-text'>${history.usages_detals.total_cost}</div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CurrentUsages;
