import { Dialog } from "@material-ui/core";
import axios from "axios";
import Cookies from "js-cookie";
import React from "react";
import { useDispatch } from "react-redux";
import { addCsv, deleteCsv } from "reducers/csv.js";
import { setNumberBatch, setTotalProperty, setTotalSearch } from "reducers/matix";
import { setSnackError } from "reducers/subscriptionModal";
import styled from "styled-components";
import { Button } from "styles/Buttons";
import uuidv4 from "utils/uuid";

//Analytics
import ReactGA from "react-ga";

const ChooseButton = styled(Button)`
	color: #4e77b6;
	height: 3.5rem;
	width: 12.8rem;
	position: relative;
`;
const ImportButton = styled(Button)`
	color: white;
	background-color: #50bfd8;
	height: 3.5rem;
	width: 12.8rem;
`;
const BatchUploadModal: React.FC<{
	setCsvs: Function;
	setModall: Function;
	csvs: any;
	setCsv: Function;
	setBatch: Function;
	onClose: any;
	batch: any;
	modalOpen: boolean;
}> = ({ setBatch, setCsvs, setModall, onClose, batch, csvs, modalOpen }) => {
	const dispatch = useDispatch();

	const token: string = Cookies.get("token") || "";

	const onChange = (event: any) => {
		// import Csv from '../../assets/csv/example.xlsx';
		setCsvs(event.target.files[0]);
	};

	const onUpload = async () => {
		const uuid = uuidv4();
		const newForm = new FormData();
		newForm.append("file", csvs);
		newForm.append("token", token);
		ReactGA.event({
			category: "Dashboard",
			action: "Batch Upload"
		});
		if (csvs.name) {
			try {
				setModall(false);
				setCsvs({ name: "" });
				// const csvArray = Object.keys(csv);

				dispatch(addCsv({ [uuid]: { name: csvs.name } }));

				const uploadFile = await axios({
					baseURL: process.env.REACT_APP_NODE_BASE_URL,
					url: `/upload`,
					method: "post",
					data: newForm
				});

				setBatch((data: any) => {
					return {
						data: [uploadFile.data.data, ...batch],
						meta: data.meta
					};
				});
				dispatch(setTotalProperty(uploadFile.data.data.property_count));
				dispatch(setTotalSearch(uploadFile.data.data.property_count));
				dispatch(setNumberBatch(1));

				dispatch(deleteCsv(uuid));
			} catch (error) {
				console.log("83", error);
				dispatch(addCsv({ [uuid]: { name: csvs.name, error: true } }));

				// setTimeout(() => {
				//     dispatch(deleteCsv(uuid));
				// }, 5500);
				try {
					dispatch(setSnackError(error.response.data.message));
				} catch (error) {
					console.log(error);
				}
			}
		}
	};

	return (
		<Dialog open={modalOpen} onClose={onClose} maxWidth={"sm"}>
			<div className="upload-csv p-20">
				<h3 className="fs-16 brand-text-color mb-30 fw-600">Batch Upload</h3>
				<p className="fs-16 mb-20">Analyze multiple properties at once. Upload a CSV or XLML file with all your properties and we’ll do the rest.</p>
				<a href="../../assets/csv/example.xlsx" download="Batch Upload Example.xlsx">
					<p style={{ textDecoration: "underline", textDecorationColor: "#50bfd8" }} className="fs-16 mb-20 brand-text">
						Download this file as a template.
					</p>
				</a>
				<ul className="mb-30 " style={{ minHeight: "50px", listStyleType: "disc" }}>
					{csvs.name && <li className="fs-16 ml-20 ">{csvs.name}</li>}
				</ul>
				<div className="flex justify-between">
					<ChooseButton className="cursor-pointer">
						<input
							onChange={onChange}
							className="absolute opacity-0 h-full w-full cursor-pointer"
							type="file"
							accept=".csv, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
						/>
						Choose File
					</ChooseButton>
					<ImportButton onClick={onUpload}>Import File</ImportButton>
				</div>
			</div>
		</Dialog>
	);
};

export default BatchUploadModal;
