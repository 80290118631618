import React, { Component } from "react";
import "./marketOccupancy.css";
import Chart from "react-apexcharts";
import back from "../../../../assets/img/left-arrow.png";

export default class MarketOccupancy extends Component {
	constructor(props) {
		super(props);
		var labels = [];
		var da = {
			ep: JSON.parse(this.props.data.occupancy_entire_place).data.occupancy
			// pr: JSON.parse(this.props.data.occupancy_private_room).data.occupancy
			// sr: JSON.parse(this.props.data.occupancy_shared_room).data.occupancy
		};
		var data1 = [],
			data2 = [],
			data3 = [];
		da.ep.calendar_months.map((d) => {
			var date = d.month + "/01/" + d.year;
			labels.push(date);
			data1.push(d.percentiles["90"] * 100);
		});
		// da.pr.calendar_months.map((d) => {
		// 	data2.push(d.percentiles["90"] * 100);
		// });
		// da.sr.calendar_months.map((d) => {
		// 	data3.push(d.percentiles["90"] * 100);
		// });
		this.state = {
			series: [
				{
					name: "Entire Place",
					data: data1
				}
				// {
				// 	name: "Private Room",
				// 	data: data2
				// },
				// {
				// 	name: "Shared Room",
				// 	data: data3
				// }
			],
			options: {
				chart: {
					type: "line",
					zoom: {
						enabled: false
					},
					toolbar: {
						show: false
					}
				},
				labels: labels,
				stroke: {
					show: true,
					curve: "smooth",
					colors: ["#25A0FC", "#FED88D", "#3FEAB0"],
					width: 3
				},
				dataLabels: {
					show: false
				},
				legend: {
					show: false
				},
				xaxis: {
					type: "datetime"
				},
				yaxis: {
					labels: {
						formatter: function (value) {
							if (value) {
								return Math.round(value) + "%";
							} else {
								return "0%";
							}
						}
					}
				}
			}
		};
	}
	render() {
		return (
			<div className="market-occupancy-main-container">
				<div className="header">
					<img onClick={this.props.handleBack} src={back} alt="back-key" />
					<h1>STR Summary</h1>
				</div>
				<div className="market-occupancy-container">
					<h1>Occupancy</h1>
					<Chart options={this.state.options} series={this.state.series} height={360} type="line" />
				</div>
			</div>
		);
	}
}
