import React from 'react';
import DealTR from 'components/DealTR/dealTR';
import ExtendTableCard from 'components/extendableCard';
import useAnalyzer from '../../hooks/useAnalyzer';
import commas from '../../utils/numberWithCommas';
// import { devided } from '../../utils/math';
const SummaryOfDeal = () => {
    const { closingCosts, strCasOutlay, strNetExpenses } = useAnalyzer();
    return (
        <div className=''>
            <ExtendTableCard className='dealinformation__2' title='SUMMARY OF DEAL' isOpen={true}>
                <div className='border'>
                    <div>
                        <table className='fs-14' style={{ width: '100%' }}>
                            <DealTR right='Estimated Closing Costs' input={<input value={'$' + commas(closingCosts)} />} />
                            <DealTR right='Total Cash Outlay at Closing' input={<input value={'$' + commas(strCasOutlay())} />} />
                            <DealTR
                                border={true}
                                right='Estimated Annual Net Expenses'
                                input={<input value={'$' + commas(strNetExpenses())} />}
                            />
                        </table>
                    </div>
                </div>
            </ExtendTableCard>
        </div>
    );
};

export default SummaryOfDeal;
