import React from "react";
import Sidebar from "../../components/sidebar";
import Estimator from "./RepairEstimator";

import MobileDashboardNav from "../../components/mobileDashboardNav/mobileDashboardNav";

function index() {
	return (
		<>
			<MobileDashboardNav />
			<div className="flex">
				<div className="main-sidebar-container">
					<Sidebar />
				</div>
				<Estimator />
			</div>
		</>
	);
}

export default index;
