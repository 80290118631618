import axios from "axios";
import Cookies from "js-cookie";
const storeData = async (data) => {
	const token = Cookies.get("token");
	try {
		const response = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/api/v1/general/address/store`,
			{
				address: data.address,
				lat: data.lat,
				lng: data.lng,
				airdna: data.details.airDna,
				estated: data.details.estated,
				realtyMole: data.details.realtyMole,
				realtyMoleProperty: data.details.realtyMoleProperty
			},
			{ headers: { Authorization: `Bearer ${token}` } }
		);
		return response.data;
	} catch (error) {
		console.log(error);
		return false;
	}
};

export default storeData;
