import React from "react";
import Pdf from "react-to-pdf";
import { ContainerBox } from "styles/Box";
import DealTab from "../../components/DealTab/DealTab";
import Navbar from "../../components/navbar";
import BuyingCost from "./BuyingCost";
import FInancingCost from "./FInancingCost";
import HoldingCost from "./HoldingCost";
import NetProfit from "./netProfit";
import PotentialAnalysis from "./PotentialAnalysis";
import PropertyValues from "./PropertyValues";
import PurchaseAnalysis from "./PurchaseAnalysis";
import ROI from "./ROI";
import SellingCost from "./SellingCost";

//Analytics
import ReactGA from "react-ga";

function Analyzer() {
	const ref = React.createRef();
	const options = {
		unit: "in",
		format: [1400, 1800]
	};

	React.useEffect(() => {
		ReactGA.event({
			category: "Dashboard",
			action: "Deal Analyzer Rehab"
		});
	}, []);

	return (
		<div className="dashboard--wraper pt-15 pl-15 pr-15 pb-15">
			<Navbar />

			<ContainerBox className="mt-20 ">
				<Pdf targetRef={ref} filename="DealAnalyzer.pdf" options={options} scale={1} x={0.1} y={0.5}>
					{({ toPdf }) => <DealTab tabName={"DealAnalyzer"} onClick={toPdf} />}
				</Pdf>

				<div className="px-20 pb-20" ref={ref}>
					<div className="flex -mx-15 mb-20">
						<PropertyValues />
						<HoldingCost />
					</div>

					<h4 className="fs-20 brand-text-color">ARV</h4>
					<div className="flex -mx-15 my-20">
						<FInancingCost />
						<div className="w-6/12 px-15">
							<BuyingCost />
							<SellingCost />
						</div>
					</div>
					<h4 className="fs-20 brand-text-color">ESTIMATED NET PROFIT & ROI SNAPSHOT</h4>
					<div className="flex -mx-15 mb-20 mt-20">
						<div className="w-6/12 px-15">
							<NetProfit />
						</div>
						<div className="w-6/12 px-15">
							<ROI />
						</div>
					</div>
					<div className="flex -mx-15 mb-20 mt-20">
						<div className="w-6/12 px-15">
							<PurchaseAnalysis />
						</div>
						<div className="w-6/12 px-15">
							<PotentialAnalysis />
						</div>
					</div>
				</div>
			</ContainerBox>
		</div>
	);
}

export default Analyzer;
