import axios from "axios";
import Empty from "components/empty";
import LoadingSkeleton from "components/loadingSkeleton";
import TableCompoent from "components/table";
import TableHeaderBar from "components/tableHeaderBar";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import React from "react";

//Analytics
import ReactGA from "react-ga";

// batch: 0
// comp_pro: 1
// cost_per_comp: 5
// default: null
// limit_per_month: 500
// over_rate: "0.25"
// regular_rate: "0.25"
// status: "active"
// subs_end_date: "2029-03-10 10:05:19"
// subs_package: "Pay Per Use"
// subs_package_id: 2
// subs_start_date: "2020-12-22 10:05:19"

const searchHead = ["Package Name", "Total Searched", "Comp Reports", "Total Cost", "Billing Date"];
const BillingHistoryTable = () => {
	const [reacentSearch, setSearch] = React.useState({
		data: [],
		meta: {}
	});
	const [loading, setLoading] = React.useState(true);
	const token = Cookies.get("token");
	// const [loading,setLoading] = React.useState(true);
	React.useEffect(() => {
		ReactGA.event({
			category: "Profile",
			action: "Click_Billing_History"
		});
		const getRecentSearch = async () => {
			const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/general/usages/history/get`, {
				headers: { Authorization: `Bearer ${token}` }
			});

			setSearch({ ...response.data });
			setLoading(false);
		};

		getRecentSearch();
	}, []);
	if (loading === true) {
		return (
			<div className="bg-white mt-20 table--top--wraper">
				<TableHeaderBar title="Billing History" HeaderRightComponent={() => <span></span>} />
				<div className="main-table-loader">
					<LoadingSkeleton Head={searchHead} />
				</div>
				<div className="responsive-table-loader">
					<LoadingSkeleton Head={["", ""]} />
				</div>
			</div>
		);
	}
	return (
		<div>
			<TableHeaderBar title="Billing History" HeaderRightComponent={() => <span></span>} />
			{!!reacentSearch.data.length ? (
				<div>
					<div className="table-container">
						<TableCompoent Th={searchHead}>
							{reacentSearch.data.map((data: any) => {
								return (
									<tr>
										<td>{data.package_name}</td>
										<td>{data.search_use}</td>

										<td>{data.comp_use}</td>
										<td>${data.total_cost}</td>

										<td>{dayjs(data.updated_at).format("MMM D, YYYY")}</td>
									</tr>
								);
							})}
						</TableCompoent>
					</div>
					<div className="secondary-table-container">
						{reacentSearch.data.map((data: any) => {
							return (
								<div className="secondary-table-content">
									<div className="top">
										<div className="left">
											<p>{data.package_name}</p>
										</div>
										<div className="right">
											<p>
												Search : <span>{data.search_use}</span>
											</p>
											<p>
												Compare: <span>{data.comp_use}</span>
											</p>
										</div>
									</div>
									<div className="bottom">
										<div className="left">
											<p>
												Date: <span>{dayjs(data.updated_at).format("MMM D, YYYY")}</span>
											</p>
										</div>
										<div className="right">
											<p>
												Cost: <span>${data.total_cost}</span>
											</p>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			) : (
				<Empty />
			)}
		</div>
	);
};

export default BillingHistoryTable;
