import { createSlice } from '@reduxjs/toolkit';
import { sum } from 'utils/math';
export const matix = createSlice({
    name: 'matix',
    initialState: {
        total_search : "-",
        number_of_batch:"-",
        total_number_of_properties:"-",
        property_deal_analyze:"-",
    

    },
    reducers: {
        setMatix: (state, action) => {
            return {
                ...state,
                ...action.payload
            };
        },
        setTotalSearch :(state,action)=>{
            return {
                ...state,
                total_search :sum([state.total_search,action.payload]) 
            }
        },
        setNumberBatch :(state,action)=>{
            return {
                ...state,
                number_of_batch : sum([state.number_of_batch ,  action.payload]) 
            }
        },
        setTotalProperty :(state,action)=>{
            return {
                ...state,
                total_number_of_properties : sum([state.total_number_of_properties ,action.payload]) 
            }
        }
    },
});

export const { setMatix,setTotalSearch,setNumberBatch ,setTotalProperty} = matix.actions;
export const matixInformation = (state) => state.matix;
export default matix.reducer;
