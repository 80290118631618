import Snackbar, { BatchSnack } from 'components/snackbar';
import SubscriptionModal from 'components/subscriptionModal';
import SuccessfulModal from 'components/subscriptionModal/successfulModal';
import UploadingSnackbar from 'components/uploadingSnackbar';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { csvState } from 'reducers/csv';
import { setMessage, setSnackError, subscriptionState } from 'reducers/subscriptionModal';
const AllModals = () => {
    const dispatch = useDispatch();
    const { csv } = useSelector(csvState);
    const { message, snackError } = useSelector(subscriptionState);
    const setError = (message: string) => {
        dispatch(setMessage(message));
        dispatch(setSnackError(message));
    };
    const measseWhenChangePackage = (packageName: string) => {
        dispatch(setMessage(`You have succesfully subscribed in ${packageName}`));
    };
    return (
        <div>
            <UploadingSnackbar csv={csv} />
            <SuccessfulModal />
            <SubscriptionModal measseWhenChangePackage={measseWhenChangePackage} />
            <Snackbar open={!!message ? true : false} setError={setError} message={message} autoHideTime={5500} bg='#50bfd8' />
            <BatchSnack open={!!snackError ? true : false} setError={setError} message={snackError} bg='#f20f66' />
        </div>
    );
};

export default AllModals;
