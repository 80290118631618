import React from "react";
import Sidebar from "../../components/sidebar";
import Dashboard from "./dashboard";

import MobileDashboardNav from "../../components/mobileDashboardNav/mobileDashboardNav";

import "./dashboard.scss";
function index() {
	return (
		<>
			<MobileDashboardNav />
			<div className="flex">
				<div className="main-sidebar-container">
					<Sidebar />
				</div>
				<Dashboard />
			</div>
		</>
	);
}

export default index;
