import BreadCumb from "components/BreadCumb";
import Navbar from "components/navbar";
import ProfileTab from "components/ProfileTab";
import ProfileTab2 from "components/ProfileTab2";
import UserProfile from "components/userProfile";
import React from "react";
import { useSelector } from "react-redux";
import { userState } from "reducers/user";
import { ContainerBox } from "styles/Box";
import BillingHistoryTable from "./billingHistoryTable";
// import Details from './CurrentPlan.Details';
function CurrentPlan() {
	const user = useSelector(userState);

	return (
		<div className="dashboard--wraper pt-15 pl-15 pr-15 pb-15">
			<Navbar />
			<div>
				<BreadCumb breadCumb={[{ name: "Billing History", link: "/billing-hostory" }]} />
				<ContainerBox className="pl-21 pr-43 page-main-container">
					<UserProfile user={user} />
					<ProfileTab tabName="billingHistory" />
					<ProfileTab2 tabName="billingHistory" />
					<BillingHistoryTable />
				</ContainerBox>
			</div>
		</div>
	);
}

export default CurrentPlan;
