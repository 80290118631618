import React from "react";
import Chart from "react-apexcharts";

class LineChart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			series: [
				{
					name: props.title,
					data: props.data
				}
			],
			options: {
				chart: {
					type: "line",
					zoom: {
						enabled: false
					},
					toolbar: {
						show: false
					}
				},
				stroke: {
					show: true,
					curve: "smooth",
					colors: props.colors,
					width: 3
				},
				grid: {
					show: false
				},
				dataLabels: {
					show: false
				},
				legend: {
					show: false
				},
				xaxis: {
					labels: {
						show: false
					},
					axisBorder: {
						show: false
					},
					axisTicks: {
						show: false
					},
					tooltip: {
						enabled: false
					}
				},
				yaxis: {
					labels: {
						show: false
					},
					axisBorder: {
						show: false
					},
					axisTicks: {
						show: false
					},
					tooltip: {
						enabled: false
					}
				},
				tooltip: {
					enabled: false
				}
			}
		};
	}

	render() {
		return (
			<div className="line-chart-container">
				<Chart options={this.state.options} series={this.state.series} type="line" height={this.props.height} />
			</div>
		);
	}
}

export default LineChart;
