import React from "react";
import SVGicon from "../../assets/img/blue-arrow.svg";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

type TabName =
	| "details"
	| "analysis"
	| "extimator"
	// | "comparable"
	| "market";

const DetailsTab2: React.FC<{ tabName: TabName }> = ({ tabName }) => {
	const { search, proid } = useParams<{ search: string; proid: string }>();
	const history = useHistory();
	const [showTabs, setShowTabs] = React.useState(false);

	const handleTab = (e: any) => {
		history.push(`/dashboard/${e}/${search}/${proid}/view`);
	};

	const handleShowTabs = () => {
		setShowTabs(!showTabs);
	};

	return (
		<div className="page-secondary-tab-container active" onClick={handleShowTabs}>
			<div className="tab active">
				<p>{tabName}</p>
				<div className={showTabs ? "arrow active" : "arrow"}>
					<img src={SVGicon} alt="extend" />
				</div>
			</div>
			<div className={showTabs ? "tabs active" : " tabs"}>
				{tabName !== "details" ? (
					<div className="tab" onClick={() => handleTab("details")}>
						<p>Details</p>
					</div>
				) : null}
				{/* {tabName !== "comparable" ? (
					<div className="tab" onClick={() => handleTab("comparable")}>
						<p>Comparable Properties</p>
					</div>
				) : null} */}
				{tabName !== "market" ? (
					<div className="tab" onClick={() => handleTab("market")}>
						<p>Market Data</p>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default DetailsTab2;
