import React from 'react';
import { Link, useLocation } from 'react-router-dom';
const SigninAndSignupTab: React.FC = () => {
    const location = useLocation();
    return (
        <div className='flex signupnav '>
            <div className={location.pathname === '/signin' ? 'active-tab-sign' : ''}>
                <Link to='/signin'>Sign In</Link>
            </div>
            <div className={location.pathname === '/signup' ? 'active-tab-sign' : ''}>
                <Link to='/signup'>Sign Up</Link>
            </div>
        </div>
    );
};

export default SigninAndSignupTab;
