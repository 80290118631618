import React, { Component } from "react";
import Chart from "react-apexcharts";

export default class ProfessionalHostsChart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			series: props.data,

			options: {
				colors: ["#50bfd8", "#4e77b6"],
				labels: ["Multi Listing Hosts", "Single Listing Hosts"],
				dataLabels: {
					enabled: false
				},
				plotOptions: {
					pie: {
						donut: {
							size: "60%"
						}
					}
				},
				responsive: [
					{
						breakpoint: 468,
						options: {
							chart: {
								width: 300,
								height: 300
							},
							legend: {
								position: "bottom"
							}
						}
					}
				]
			}
		};
	}

	render() {
		return <Chart options={this.state.options} series={this.state.series} width={this.props.height} height={this.props.height} type="donut" />;
	}
}
