import Sq from "assets/img/sq.svg";
import Unit from "assets/img/unit.svg";
import Yr from "assets/img/yr.svg";
import Beds from "assets/img/beds.svg";
import Bathroom from "assets/img/bathrooms.svg";
import React from "react";
import Skeleton from "react-loading-skeleton";
import numberWithCommas from "utils/numberWithCommas";

const DetailsData = ({ loading, parcel, structure: { bed_count, baths, total_area_sq_ft, year_built }, bathAndBed: { bedrooms, bathrooms }, realty_mole_property }) => {
	var accuracy = false;
	var bath = realty_mole_property && realty_mole_property[0].bathrooms ? realty_mole_property[0].bathrooms : bath || bathrooms,
		bed = realty_mole_property && realty_mole_property[0].bedrooms ? realty_mole_property[0].bedrooms : bed_count || bedrooms,
		sq_ft = total_area_sq_ft || 0;

	const ViewSkaleton = (value) => {
		return loading ? <Skeleton width="140" /> : value;
	};
	const isArray = function (a) {
		return !!a && a.constructor === Array;
	};

	console.log("21", realty_mole_property);

	return (
		<div className="flex mt-35 mb-20 p-20 details-data">
			<Box imageSrc={Beds} number={ViewSkaleton(bed)} name={bed > 1 ? "Beds" : "Bed"} accuracy={accuracy} />
			<Box imageSrc={Bathroom} number={ViewSkaleton(bath)} name={bath > 1 ? "Bathrooms" : "Bathroom"} accuracy={accuracy} />
			<Box imageSrc={Sq} number={ViewSkaleton(sq_ft)} name="Sq.Ft" accuracy={true} />
			<Box imageSrc={Yr} number={ViewSkaleton(year_built || "n/a")} name="Yr. Built" accuracy={true} />
			<Box imageSrc={Unit} number={ViewSkaleton(parcel?.county_name)} name="County Name" accuracy={true} />
		</div>
	);
};

const Box = ({ imageSrc, number, name, accuracy }) => {
	return (
		<div style={{ width: "33%", display: "flex", flexDirection: "column", alignItems: "center" }} className="details-data--single">
			<div className="flex justify-center">
				<div className="details-data--single--img">
					<img src={imageSrc} alt="beds" />
				</div>
				<div>
					<h3 className="fs-24 font-bold ">{number}</h3>
					<p className="fs-16">{name}</p>
				</div>
			</div>
			{accuracy === false ? <p className="inaccurate-text">*possible inaccurate count</p> : null}
		</div>
	);
};

export default DetailsData;
