import React from 'react';
import ExtendTableCard from 'components/extendableCard';
import DealTRv3 from 'components/DealTR/dealTRv3';
import CalculationInput from 'components/calculationInput/calculationInput';
import numberWithCommas from '../../utils/numberWithCommas';
import useAnalyzer from '../../hooks/useAnalyzer';
const FInancingCost = () => {
    const {
        firstMortgagePoints,
        getfirstMortgagePoints,
        getfirstMortgagePoints2,
        getfirstMortgageInterest,
        getfirstMortgageInterestPayment,
        getMortageLienAmount,
        getSecondMortgagePoints,
        getSecondMortgageInterest,
        getSecondMortgageInterestPayment,
        getMicsAmount,
        getMicsPoint,
        getMicInterest,
        getMicInterestPayment,
        totalFinancingCost,
    } = useAnalyzer();

    return (
        <div className='w-6/12 px-15'>
            <ExtendTableCard className='dealinformation__2' title='Financing Costs' isOpen={true}>
                <div className='border'>
                    <div>
                        <table className='fs-14' style={{ width: '100%' }}>
                            <tr className='h-30 brand-text bg-white'>
                                <th className='w-6/12'></th>
                                <th>% of Costs</th>

                                <th>Total</th>
                            </tr>
                            <DealTRv3
                                right='First Mortgage Points'
                                middle={<CalculationInput type='percentage' name='firstMortgagePoints' />}
                                input={<input type='text' value={'$' + numberWithCommas(getfirstMortgagePoints())} />}
                            />
                            <DealTRv3
                                right='First Mortgage Points'
                                middle={<CalculationInput type='text' name='firstMortgagePoints2' />}
                                input={<input type='text' value={'$' + numberWithCommas(getfirstMortgagePoints2())} />}
                            />
                            <DealTRv3
                                right='First Mortgage Interest'
                                middle={<CalculationInput type='percentage' name='firstMortgageInterest' />}
                                input={<input type='text' value={'$' + numberWithCommas(getfirstMortgageInterest())} />}
                            />
                            <DealTRv3
                                right='First Mortgage Monthly Interest Only Payment'
                                middle={<span />}
                                input={<input type='text' value={'$' + numberWithCommas(getfirstMortgageInterestPayment())} />}
                            />
                        </table>
                        <div className='bg-white h-30'></div>
                        <table className='fs-14'>
                            <DealTRv3
                                right='Second Mortgage / Lien Amount'
                                middle={<input type='text' value={100 - firstMortgagePoints + '%'} />}
                                input={<input type='text' value={'$' + numberWithCommas(getMortageLienAmount())} />}
                            />
                            <DealTRv3
                                right='Second Mortgage Points'
                                middle={<CalculationInput type='text' name='secondMortgagePoints' />}
                                input={<input type='text' value={'$' + numberWithCommas(getSecondMortgagePoints())} />}
                            />
                            <DealTRv3
                                right='Second Mortgage Interest'
                                middle={<CalculationInput type='percentage' name='secondMortgageInterest' />}
                                input={<input type='text' value={'$' + numberWithCommas(getSecondMortgageInterest())} />}
                            />
                            <DealTRv3
                                right='Second Mortgage Monthly Interest Only Payment'
                                middle={<span />}
                                input={<input type='text' value={'$' + numberWithCommas(getSecondMortgageInterestPayment())} />}
                            />
                        </table>

                        <div className='bg-white h-30'></div>
                        <table className='fs-14'>
                            <DealTRv3
                                right='Misc. Mortgage / Lien Amount'
                                middle={<CalculationInput type='percentage' name='miscMortgageLienAmount' />}
                                input={<input type='text' value={'$' + numberWithCommas(getMicsAmount())} />}
                            />
                            <DealTRv3
                                right='Misc. Mortgage Points'
                                middle={<CalculationInput type='text' name='miscMortgagePoints' />}
                                input={<input type='text' value={'$' + numberWithCommas(getMicsPoint())} />}
                            />
                            <DealTRv3
                                right='Misc. Mortgage Interest'
                                middle={<CalculationInput type='percentage' name='miscMortgageInterest' />}
                                input={<input type='text' value={'$' + numberWithCommas(getMicInterest())} />}
                            />
                            <DealTRv3
                                right='Misc. Mortgage Monthly Interest Only Payment'
                                middle={<span />}
                                input={<input type='text' value={getMicInterestPayment()} />}
                            />
                        </table>
                        <div className='bg-white h-30'></div>
                        <table className='fs-14'>
                            <DealTRv3
                                right='Miscellaneous Financing Costs
                                Financing Costs'
                                middle={<input value='' />}
                                input={<CalculationInput name='miscellaneousFinancingCosts' />}
                            />
                        </table>
                        <div className='h-50 flex justify-between items-center  text-white' style={{ background: '#7b87ae' }}>
                            <div className='fs-18 w-6/12 text-right pr-15 '>Total Financing cost</div>
                            <div className='fs-20 w-6/12 text-center'>${numberWithCommas(totalFinancingCost())}</div>
                        </div>
                    </div>
                </div>
            </ExtendTableCard>
        </div>
    );
};

export default FInancingCost;
