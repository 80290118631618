import React from "react";
import BreadCumb from "components/BreadCumb";
import DetailsTab from "components/details/DetailsTab";
import DetailsTab2 from "components/details/DetailsTab2";
import HeadLine from "components/details/HeadLine";
import Navbar from "components/navbar";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { singlePropertyState } from "reducers/singleProperty";
import { ContainerBox } from "styles/Box";
import MarketDataDetails from "./marketDataDetails/marketDataDetails";

function MarketPropertiesWrapper() {
	const { search, proid } = useParams();
	const {
		estated: { data }
	} = useSelector(singlePropertyState);
	return (
		<div className="dashboard--wraper pt-15 pl-15 pr-15 pb-15">
			<Navbar />
			<BreadCumb
				breadCumb={[
					{ name: "New Listing", link: "/profile" },
					{ name: "Property List View", link: "/dashboard" }
				]}
			/>
			<ContainerBox className="p-35 page-main-cotainer">
				<HeadLine name={data.address.formatted_street_address} city={data.address.city} state={data.address.state} zip={data.address.zip_code} />
				<DetailsTab tabName="market" />
				<DetailsTab2 tabName="market" />
				<MarketDataDetails proid={proid} />
			</ContainerBox>
		</div>
	);
}

export default MarketPropertiesWrapper;
