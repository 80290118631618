import CalculationInput from 'components/calculationInput/calculationInput';
import DealTR from 'components/DealTR/dealTR';
import ExtendTableCard from 'components/extendableCard';
import useAnalyzer from 'hooks/useAnalyzer';
import React from 'react';
import numberWithCommas from '../../utils/numberWithCommas';

const BuyAndHold = () => {
    const { downPayment } = useAnalyzer();
    return (
        <div className='w-4/12 px-15'>
            <ExtendTableCard className='dealinformation__2' title='BUY AND HOLD' isOpen={true}>
                <div className='border'>
                    <div>
                        <table className='fs-14' style={{ width: '100%' }}>
                            <DealTR right='Closing Costs' input={<CalculationInput name='closingCosts' />} />
                            <DealTR right='Loan to Value %' input={<CalculationInput type='percentage' name='loantoValue' />} />
                            <DealTR right='Down Payment' input={<input type='text' value={'$' + numberWithCommas(downPayment())} />} />
                            <DealTR right='Loan Term in Months' input={<CalculationInput type='text' name='loanTerminMonths' />} />
                            <DealTR
                                right='Mortgage Interest'
                                border={true}
                                input={<CalculationInput type='percentage' name='mortgageInterest' />}
                            />
                        </table>
                    </div>
                    <h3 className='bg-white border-top border-bottom fs-16 py-4 px-10 brand-text fw-500'> DEAL FACTORS </h3>
                    <table className='fs-14' style={{ width: '100%' }}>
                        <DealTR right='Capital Expenses' input={<CalculationInput type='percentage' name='capitalExpenses' />} />
                        <DealTR
                            right='Estimated Maintenance Costs'
                            border={true}
                            input={<CalculationInput type='percentage' name='estimatedMaintenanceCosts' />}
                        />
                    </table>
                </div>
            </ExtendTableCard>
        </div>
    );
};

export default BuyAndHold;
