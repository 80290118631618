import React from "react";
import Sidebar from "../../components/sidebar";
import Analyzer from "./Analyzer";
import "./dealSTR.scss";

import MobileDashboardNav from "../../components/mobileDashboardNav/mobileDashboardNav";

function index() {
	return (
		<>
			<MobileDashboardNav />
			<div className="flex">
				<div className="main-sidebar-container">
					<Sidebar />
				</div>
				<Analyzer />
			</div>
		</>
	);
}

export default index;
