import React, { Component } from "react";
import Chart from "react-apexcharts";

export default class ActiveListingChart extends Component {
	constructor(props) {
		super(props);
		var labels = [];
		var da = props.data;
		var data1 = [],
			data2 = [],
			data3 = [];
		var hp = 0;
		da.ep.calendar_months.map((d) => {
			var date = d.month + "/01/" + d.year;
			labels.push(date);
			data1.push(d.active_listings);
			if (hp < d.active_listings) {
				hp = d.active_listings;
			}
		});
		// da.pr.calendar_months.map((d) => {
		// 	data2.push(d.active_listings);
		// 	if (hp < d.active_listings) {
		// 		hp = d.active_listings;
		// 	}
		// });
		// da.sr.calendar_months.map((d) => {
		// 	data3.push(d.active_listings);
		// 	if (hp < d.active_listings) {
		// 		hp = d.active_listings;
		// 	}
		// });
		this.state = {
			series: [
				{
					name: "Entire Place",
					type: "line",
					data: data1
				}
				// {
				// 	name: "Shared Room",
				// 	type: "area",
				// 	data: data3
				// },
				// {
				// 	name: "Private Room",
				// 	type: "line",
				// 	data: data2
				// }
			],
			options: {
				chart: {
					type: "line",
					zoom: {
						enabled: false
					},
					toolbar: {
						show: false
					}
				},
				fill: {
					opacity: [0.5, 0.1, 1]
				},
				labels: labels,
				stroke: {
					show: true,
					curve: "smooth",
					colors: props.colors,
					width: 3
				},
				xaxis: {
					type: "datetime"
				},
				yaxis: {
					title: {
						text: "Points"
					},
					labels: {
						formatter: function (value) {
							if (value) {
								return Math.round(value);
							} else {
								return "0";
							}
						}
					}
				},
				grid: {
					show: true
				},
				legend: {
					show: false
				}
			}
		};
	}

	render() {
		return <Chart options={this.state.options} series={this.state.series} height={this.props.height} type="line" />;
	}
}
