import React from "react";
import { useSelector } from "react-redux";
import SVGicon from "../../assets/img/blue-arrow.svg";
import { userState } from "reducers/user";
import { useHistory } from "react-router-dom";

import "./index.css";

type TabName = "details" | "currentPlan" | "billingHistory" | "paymentMethod";

const ProfileTab: React.FC<{ tabName: TabName }> = ({ tabName }) => {
	const { card_info } = useSelector(userState);
	const history = useHistory();
	const [showTabs, setShowTabs] = React.useState(false);

	const handleTab = (e: any) => {
		if (e === "details") {
			history.push(`/profile`);
		} else if (e === "currentPlan") {
			history.push(`/current-plan`);
		} else if (e === "billingHistory") {
			history.push(`/billing-history`);
		} else if (e === "paymentMethod") {
			history.push(`/pyment-method`);
		}
	};

	const handleShowTabs = () => {
		setShowTabs(!showTabs);
	};
	return (
		<div className="profile-tab2-container" onClick={handleShowTabs}>
			<div className="tab active">
				<p>{tabName}</p>
				<div className={showTabs ? "arrow active" : "arrow"}>
					<img src={SVGicon} alt="extend" />
				</div>
			</div>
			<div className={showTabs ? "tabs active" : " tabs"}>
				{tabName !== "details" ? (
					<div className="tab" onClick={() => handleTab("details")}>
						<p>Details</p>
					</div>
				) : null}
				{tabName !== "currentPlan" ? (
					<div className="tab" onClick={() => handleTab("currentPlan")}>
						<p>Current Plan</p>
					</div>
				) : null}
				{tabName !== "billingHistory" ? (
					<div className="tab" onClick={() => handleTab("billingHistory")}>
						<p>Billing History</p>
					</div>
				) : null}
				{card_info.status ? (
					<>
						{tabName !== "paymentMethod" ? (
							<div className="tab" onClick={() => handleTab("paymentMethod")}>
								<p>Payment Method</p>
							</div>
						) : null}
					</>
				) : null}
			</div>
		</div>
	);
};

export default ProfileTab;
