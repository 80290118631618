import React from 'react';

const DealTRv3 = (props: any) => {
    return (
        <tr>
            <td className='text-right table__color pr-20 w-6/12'>{props.right}</td>
            <td className={`bg-white border-right  ${!props.border ? 'border-bottom ' : ''}`}>{props.middle}</td>
            <td className={`bg-white ${!props.border ? 'border-bottom ' : ''}`}>{props.input}</td>
        </tr>
    );
};

export default DealTRv3;
