import ExtendTableCard from 'components/extendableCard';
// import UpDown from 'components/upDown';
import useAnalyzer from 'hooks/useAnalyzer';
import React from 'react';
import { devided } from 'utils/math';
import numberWithCommas from 'utils/numberWithCommas';

const Summary = () => {
    const {
        afterRepairValue,
        purchasePrice,
        estimatedRepairs,
        totalFinancingCost,
        totolHoldingCost,
        totalBuyingTransactionCost,
        totalSellingTransactionCost,
    } = useAnalyzer();
    const [heightVal, setVal] = React.useState(0);
    React.useEffect(() => {
        function arrayMax(arr: any[]) {
            var len = arr.length,
                max = -Infinity;
            while (len--) {
                if (arr[len] > max) {
                    max = arr[len];
                }
            }
            return max;
        }
        const getValue = () => {
            const data = [
                estimatedRepairs,
                totalFinancingCost(),
                totolHoldingCost(),
                totalBuyingTransactionCost(),
                totalSellingTransactionCost(),
            ];
            const heightValue = arrayMax(data);
            setVal(heightValue);
        };
        getValue();
    }, [totalSellingTransactionCost(), totalBuyingTransactionCost(), totolHoldingCost(), totalFinancingCost(), estimatedRepairs]);
    const getParcent = (basevalue: string | number, value: string | number) => {
        const perPercent = devided(basevalue, 100, 2);

        return devided(value, perPercent, 2);
    };

    return (
        <div>
            <ExtendTableCard className='dealinformation__1' title='SUMMARY' isOpen={true}>
                <div className='py-20'>
                    <div className='flex mb-20'>
                        <div className='w-6/12 pr-20' style={{ borderRight: '0.1rem dotted #7b87ae' }}>
                            <h5 className='text-right fs-16 second-color'>Annual Repair Value</h5>
                            <h3 className='text-right fs-20 brand-text'>${numberWithCommas(afterRepairValue)}</h3>
                        </div>
                        <div className='w-6/12 pl-20'>
                            <h5 className=' fs-16 second-color'>Purchage Price</h5>
                            <h3 className='fs-20 brand-text'>${numberWithCommas(purchasePrice)}</h3>
                        </div>
                    </div>

                    <TabGraph
                        name='Estimated Repair Costs'
                        value={estimatedRepairs}
                        color={'#8983ff'}
                        parent={getParcent(heightVal, estimatedRepairs)}
                    />
                    <TabGraph
                        name='Total Financing Costs'
                        value={totalFinancingCost()}
                        color={'#50bfd8'}
                        parent={getParcent(heightVal, totalFinancingCost())}
                    />
                    <TabGraph
                        name='Total Holding Costs'
                        value={totolHoldingCost()}
                        color={'#8983ff'}
                        parent={getParcent(heightVal, totolHoldingCost())}
                    />
                    <TabGraph
                        name='Total Buying Transactions Coats'
                        value={totalBuyingTransactionCost()}
                        color={'#50bfd8'}
                        parent={getParcent(heightVal, totalBuyingTransactionCost())}
                    />
                    <TabGraph
                        name='Total Selling Transactions Coats:'
                        value={totalSellingTransactionCost()}
                        color={'#8983ff'}
                        parent={getParcent(heightVal, totalSellingTransactionCost())}
                    />
                </div>
            </ExtendTableCard>
        </div>
    );
};

const TabGraph = ({ parent, value, name, color }: any) => {
    const getWidth = (value: number) => {
        if (Number(value) === 0 || Number(value) < 0) {
            return 0;
        }

        return Number(value) < 2 ? Number(value) + 3 : value;
    };

    return (
        <div className='flex items-center p-10 -mx-10'>
            <div className='w-5/12 fs-13 px-10 text-right c-919191'>{name}</div>
            <div className='w-7/12 px-10'>
                <div
                    className='relative'
                    style={{
                        background: color,
                        height: '0.5rem',
                        width: getWidth(parent) + '%',
                        transition: '0.3s all',
                    }}
                >
                    <span
                        className={`absolute second-color ${Number(parent) < 50 ? 'whenvalueSmall' : 'whenvalueBig'}`}
                        style={{ transition: '0.3s all' }}
                    >
                        ${numberWithCommas(value)}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Summary;
