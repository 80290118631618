import Logo from "assets/img/logo.png";
import Analyzer from "assets/svg/Analyzer";
import Batch from "assets/svg/batchUpload";
import Dashboard from "assets/svg/Dashboard";
import Fb from "assets/svg/facebook.svg";
import Favorite from "assets/svg/Favourite";
import Linkedin from "assets/svg/linkedin.svg";
import Recent from "assets/svg/Recent";
import Tweeter from "assets/svg/twitter-logo.svg";
import User from "assets/img/user.png";
import Logout from "assets/img/logout.png";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { setUser, userState } from "reducers/user";
import "./sidebar.scss";
import Cookies from "js-cookie";

function Sidebar() {
	const dispatch = useDispatch();
	const history = useHistory();
	const { first_name } = useSelector(userState);
	const logout = () => {
		dispatch(
			setUser({
				active: null,
				address: null,
				dob: null,
				email: "",
				first_name: "",
				id: undefined,
				last_name: "",
				phone: "",
				photo: undefined
			})
		);

		Cookies.remove("token");
		history.push("/signin");
	};
	return (
		<div className="w-240 sidebar h-screen ">
			{/* <a href="/"> */}
				<img src={Logo} className="mb-80 w-79 h-53 ml-30 mt-17" alt="logo" />
			{/* </a> */}
			<div className="sidebar-section">
				<p className="section-header">Sections</p>
				<NavLink activeClassName="is-active" to="/dashboard" className="h-52 w-full flex items-center pl-20">
					<div className="h-52 flex items-center">
						<Dashboard />
						Dashboard
					</div>
				</NavLink>
				<NavLink activeClassName="is-active" to="/batch-upload" className="h-52 w-full flex items-center pl-20">
					<div className="h-52 flex items-center">
						<Batch />
						Batch Upload
					</div>
				</NavLink>
				<NavLink activeClassName="is-active" to="/favourites" className="h-52 w-full flex items-center pl-20">
					<div className="h-52 flex items-center">
						<Favorite />
						Favorites
					</div>
				</NavLink>
				<NavLink activeClassName="is-active" to="/recent" className="h-52 w-full flex items-center pl-20">
					<div className="h-52 flex items-center">
						<Recent />
						Recent
					</div>
				</NavLink>
				<NavLink activeClassName="is-active" to="/deal-analyzer" className="h-52 w-full flex items-center pl-20 relative hide-mobile">
					<div className="h-52 flex items-center ">
						<Analyzer />
						Deal Analyzer
						<span className="absolute beta__sidebar">beta</span>
					</div>
				</NavLink>
				{/* <NavLink activeClassName='is-active' to='/repair-estimator' className='h-52 w-full flex items-center pl-40'>
                <div className='h-52 flex items-center'>
                    <Estimator />
                    Repair Estimator
                </div>
            </NavLink> */}
			</div>
			<div className="sidebar-section">
				<p className="section-header">Others</p>
				<NavLink activeClassName="is-active" to="/profile" className="h-52 w-full flex items-center pl-20 relative">
					<div className="h-52 flex items-center ">
						<img src={User} style={{ width: "22px", height: "22px", marginRight: "15px", objectFit: "contain" }} />
						{first_name}
					</div>
				</NavLink>
				<div className="h-52 w-full flex items-center pl-20 relative" onClick={logout}>
					<div className="h-52 flex items-center ">
						<img src={Logout} style={{ width: "20px", height: "20px", marginRight: "15px", objectFit: "contain" }} />
						Logout
					</div>
				</div>
			</div>
			<div className="absolute w-full py-20" style={{ bottom: "0px" }}>
				<p className="fs-16 mb-10 text-center">admin@dynamic.re</p>

				<div className="flex items-center justify-center " style={{ margin: "0px" }}>
					<a href="https://www.facebook.com/www.dynamic.re" target="_blank">
						<img src={Fb} height="22px" width="22px" className="mr-10" />
					</a>
					<a href="https://twitter.com/dynamicre1" target="_blank">
						<img src={Tweeter} height="22px" width="22px" className="mr-10" />
					</a>
					<a href="https://www.linkedin.com/company/dynamic-re" target="_blank">
						<img src={Linkedin} height="22px" width="22px" />
					</a>
				</div>
				<p className="text-center mt-10">
					<a className="fs-16 underline text-center" href="https://dynamic.freshdesk.com/support/solutions" target="_blank">
						Help Center
					</a>
				</p>
				<p className="text-center mt-10">
					<a className="fs-16 underline text-center" href="https://dynamic.upfeed.co" target="_blank">
						Feature Requests
					</a>
				</p>
			</div>
		</div>
	);
}

export default Sidebar;
