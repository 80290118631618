import PropTypes from "prop-types";
import React from "react";
import removeLast from "utils/removeLast";
import Home from "../../assets/img/home-icon.svg";
import "./index.css";

function DetailsHeadline({ name = "", city = "", zip = "", state = "" }) {
	return (
		<div className="flex headline">
			<img height="55px" width="55px" src={Home} alt="Home" />
			<div className="ml-19">
				<h5 className="fs-24 fw-600 f-p normal">{name}</h5>
				<p style={{ color: "#1a174b" }} className="fs-16 f-p normal">
					{city}
					{state ? ", " + state + " " : null}
					{zip}
				</p>
			</div>
		</div>
	);
}

DetailsHeadline.propTypes = {
	name: PropTypes.string.isRequired,
	address: PropTypes.string.isRequired
};

export default DetailsHeadline;
