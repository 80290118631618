import React, { Component } from "react";
import "./marketAverageDailyRate.css";
import Chart from "react-apexcharts";
import back from "../../../../assets/img/left-arrow.png";
import numberWithCommas from "utils/numberWithCommas";

export default class MarketAverageDailyRate extends Component {
	constructor(props) {
		super(props);
		var labels = [];
		var da = {
			ep: JSON.parse(this.props.data.adr_entire_place).data.adr
			// pr: JSON.parse(this.props.data.adr_private_room).data.adr,
			// sr: JSON.parse(this.props.data.adr_shared_room).data.adr
		};
		var data1 = [],
			data2 = [],
			data3 = [],
			data4 = [];
		da.ep.calendar_months.map((d) => {
			var date = d.month + "/01/" + d.year;
			labels.push(date);
			data1.push(d.percentiles["90"]);
		});
		da.ep.calendar_months.map((d) => {
			console.log(d);
			var date = d.month + "/01/" + d.year;
			labels.push(date);
			data2.push(d.percentiles["75"]);
		});
		da.ep.calendar_months.map((d) => {
			console.log(d);
			var date = d.month + "/01/" + d.year;
			labels.push(date);
			data3.push(d.percentiles["50"]);
		});
		da.ep.calendar_months.map((d) => {
			console.log(d);
			var date = d.month + "/01/" + d.year;
			labels.push(date);
			data4.push(d.percentiles["25"]);
		});
		this.state = {
			series: [
				{
					name: "90th Percentile",
					data: data1
				},
				{
					name: "75th Percentile",
					data: data2
				},
				{
					name: "50th Percentile",
					data: data3
				},
				{
					name: "25th Percentile",
					data: data4
				}
			],
			options: {
				chart: {
					type: "line",
					zoom: {
						enabled: false
					},
					toolbar: {
						show: false
					}
				},
				labels: labels,
				stroke: {
					show: true,
					curve: "smooth",
					colors: ["#25A0FC", "#3FEAB0", "#FED88D", "#FF6A80"],
					width: 3
				},
				dataLabels: {
					show: false
				},
				legend: {
					show: false
				},
				xaxis: {
					type: "datetime"
				},
				yaxis: {
					labels: {
						formatter: function (value) {
							if (value) {
								return "$" + numberWithCommas(Math.round(value));
							} else {
								return "$0";
							}
						}
					}
				},
				markers: {
					colors: ["#25A0FC", "#3FEAB0", "#FED88D", "#FF6A80"]
				}
			}
		};
	}
	render() {
		return (
			<div className="market-average-daily-rate-main-container">
				<div className="header">
					<img onClick={this.props.handleBack} src={back} alt="back-key" />
					<h1>STR Summary</h1>
				</div>
				<div className="market-average-daily-rate-container">
					<h1>Average Daily Rate</h1>
					<Chart options={this.state.options} series={this.state.series} height={360} type="line" />
				</div>
			</div>
		);
	}
}
