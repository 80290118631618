import Dialog from "@material-ui/core/Dialog";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Success from "../../assets/svg/success.svg";
import { setSuccess, subscriptionState } from "../../reducers/subscriptionModal";
function SuccessModal() {
	const { success } = useSelector(subscriptionState);
	const dispatch = useDispatch();
	return (
		<div>
			<Dialog maxWidth={"sm"} fullWidth={true} className="modal" onClose={() => dispatch(setSuccess({ type: false }))} open={success.type} disablePortal={true} disableEnforceFocus={false} disableAutoFocus={false} aria-labelledby="server-modal-title" aria-describedby="server-modal-description">
				<div className="modal--body">
					<div className="modal--top--img">
						<img src={Success} alt="success" />
					</div>
					<h3>Thank You!</h3>
					<p className="modal--body--green text-center fs-20 my-20">
						You have successfully subscribed
						<br /> to the {success.package} plan
					</p>
					<p className="modal--body--pera">We'll send you a receipt via e-mail.</p>
					<Link onClick={() => dispatch(setSuccess(false))} to="/dashboard">
						Back To Dashboard
					</Link>
				</div>
			</Dialog>
		</div>
	);
}

export default SuccessModal;
