import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import App from "./App";
import "./assets/css/global.scss";
import "./assets/css/mobile.scss";
import "./assets/css/tab.scss";
import "./index.css";
import Store from "./reducers";

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY,
	autoSessionTracking: true,
	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0
});
ReactDOM.render(
	<Provider store={Store}>
		<App />
	</Provider>,
	document.getElementById("root")
);
