import React from 'react';
import DealTR from 'components/DealTR/dealTR';
import CalculationInput from 'components/calculationInput/calculationInput';
import ExtendTableCard from 'components/extendableCard';
import { PropertyInformationState } from '../../reducers/propertyInformation';
import numberWithCommas from '../../utils/numberWithCommas';

import { useSelector } from 'react-redux';
const FixAndFlip = () => {
    const { afterRepairValue, estimatedRepairs } = useSelector(PropertyInformationState);
    return (
        <div>
            <ExtendTableCard className='dealinformation__2' title='FIX AND FLIP' isOpen={true}>
                <div className='border'>
                    <table className='fs-14' style={{ width: '100%' }}>
                        <DealTR right='After Repair  Value' input={<CalculationInput name='afterRepairValue' />} />
                        <DealTR
                            right='MAO'
                            input={<input value={'$' + numberWithCommas((afterRepairValue * 0.7 - estimatedRepairs).toFixed(0))} />}
                        />
                        <DealTR right='Hold Time (mo)' input={<CalculationInput type='text' name='holdTime' />} />
                        <DealTR right='Estimated Repairs' border={true} input={<CalculationInput name='estimatedRepairs' />} />
                    </table>
                </div>
            </ExtendTableCard>
        </div>
    );
};

export default FixAndFlip;
