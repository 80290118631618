import requestForFavUnFav from "api/favAndUnfav";
import Call from "assets/svg/phone-call.svg";
import Pin from "assets/svg/pin.svg";
import HearIcon from "components/HeartIcon";
import GoogleMapReact from "google-map-react";
import React, { useRef, useState } from "react";
import Streetview from "react-google-streetview";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

//Analytics
import ReactGA from "react-ga";

const Button = styled.button`
	color: #50bfd8;
	width: 31%;
	border: 0.1rem solid #50bfd8;
	background: white;
	border-radius: 1rem;
`;

const AnyReactComponent = ({ text }) => {
	return <img height="32px" width="32px" src={Pin} alt={text} style={{ transform: "translate(-1.6rem,-32px)" }} />;
};

function DetailsWraperMap({ center, id, favorite, loading }) {
	const [mapType, setType] = useState("terrain");
	const mapRef = useRef();
	const [state, setState] = useState(favorite);
	const onClickMap = () => {
		setType("terrain");
		mapRef.current.setMapTypeId("terrain");
	};
	const onClicSatellite = () => {
		setType("hybrid");
		mapRef.current.setMapTypeId("hybrid");
	};
	const onClicStreet = () => {
		setType("street");
	};

	const favandunFav = () => {
		ReactGA.event({
			category: "Property Details Page",
			action: state ? "Remove Favorite button" : "Favorite button"
		});
		setState(!state);
		requestForFavUnFav(id);
	};

	React.useEffect(() => {
		ReactGA.event({
			category: "Property Details Page",
			action: "Property Map View"
		});
		setState(favorite);
	}, [favorite]);

	return (
		<div className="details-map w-6/12 px-15 flex flex-col -m-10">
			{loading ? (
				<div>
					<div className="mb-10 h-50 flex justify-end">
						<div className="details-map-button-loader">
							<Skeleton height={50} width={250} />
						</div>
						<Skeleton height={50} width={250} />
					</div>
					<Skeleton height={375} />
				</div>
			) : (
				<>
					<div className="details-button-group flex justify-end pb-10">
						<Button className="h-50 fs-16 f-p mr-20 ">
							<a className="flex justify-center items-center " href="https://calendly.com/dynamic-re/advisor" target="_blank">
								<img className="w-22 h-22 mr-5" alt="Speak with Advisor" src={Call} />
								Speak with Advisor
							</a>
						</Button>

						<Button className="h-50 flex justify-center items-center fs-16 f-p " onClick={favandunFav}>
							<HearIcon filled={state} changeFavourite={() => console.log("Hei why are you seeing console?")} id={4} style={{ padding: "0px", margin: "0px 1rem 0px 0px" }} />
							{state ? "Remove Favorite" : " Add To Favorites"}
						</Button>
					</div>
					<div className="py-10 relative" style={{ height: "100%", width: "100%" }}>
						<div className="button--forgoooglez absolute flex justify-center" style={{ zIndex: "5" }}>
							<button onClick={onClickMap} className={mapType === "terrain" ? "google-button-active" : ""}>
								Map
							</button>
							<button onClick={onClicSatellite} className={mapType === "hybrid" ? "google-button-active" : ""}>
								Satellite
							</button>
							<button onClick={onClicStreet} className={mapType === "street" ? "google-button-active" : ""}>
								Street View
							</button>
						</div>

						{mapType === "street" ? (
							<Streetview
								apiKey={`${process.env.REACT_APP_GOOGLE_KEY}`}
								streetViewPanoramaOptions={{
									position: center
								}}
							/>
						) : (
							<GoogleMapReact
								bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
								defaultCenter={center}
								defaultZoom={16}
								yesIWantToUseGoogleMapApiInternals
								onGoogleApiLoaded={({ map, maps }) => {
									map.mapTypeId = mapType;

									map.streetViewControl = true;
									// map.mapTypeControl = true;
									mapRef.current = map;
								}}>
								<AnyReactComponent lat={center.lat} lng={center.lng} text={"text"} />
							</GoogleMapReact>
						)}
					</div>
				</>
			)}
		</div>
	);
}

export default DetailsWraperMap;
