import Sidebar from "components/sidebar";
import React from "react";
import BatchUpload from "./batchUpload";

import "./index.css";

import MobileDashboardNav from "../../components/mobileDashboardNav/mobileDashboardNav";

export default () => {
	return (
		<>
			<MobileDashboardNav />
			<div className="flex">
				<div className="main-sidebar-container">
					<Sidebar />
				</div>
				<BatchUpload />
			</div>
		</>
	);
};
