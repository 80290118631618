import Snackbar from '@material-ui/core/Snackbar';
import React from 'react';

export default function CustomizedSnackbars(props: any) {
    return (
        <Snackbar open={props.open} autoHideDuration={!!props.autoHideTime ? props.autoHideTime : 5000} onClose={() => props.setError('')}>
            <div className='px-30 py-18 fs-16 font-semibold text-white' style={{ background: props.bg, borderRadius: '0.7rem' }}>
                {props.message}
            </div>
        </Snackbar>
    );
}
export function BatchSnack(props: any) {
    return (
        <Snackbar open={props.open} onClose={() => props.setError('')}>
            <div className='px-30 py-18 fs-16 font-semibold text-white' style={{ background: props.bg, borderRadius: '0.7rem' }}>
                {props.message}
            </div>
        </Snackbar>
    );
}
