import React from "react";
import "./table.scss";
function Table(props) {
	return (
		<div className="table w-full">
			<table className="w-full">
				<thead>
					<tr>
						{props.Th.map((data, key) => (
							<th style={{ textAlign: data === "Favorite" || data === "Action" || data === "Download" ? "center" : "left" }} key={key}>
								{data}
							</th>
						))}
					</tr>
				</thead>

				<tbody>{props.children}</tbody>
			</table>
		</div>
	);
}

export default Table;
