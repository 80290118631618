import { configureStore } from '@reduxjs/toolkit';
import csvReducer from './csv';
import matix from "./matix";
import projectsDetails from "./projectDetials";
import propertyInformationReducer from './propertyInformation';
import signlePropertyReducers from './singleProperty';
import list from './subscriptionList';
import subscription from './subscriptionModal';
import userReducer from './user';
export default configureStore({
    reducer: {
        singleProperty: signlePropertyReducers,
        userReducer: userReducer,
        csvReducer: csvReducer,
        subcriptionReducer: subscription,
        subcriptionListReducer: list,
        propertyInformationReducer: propertyInformationReducer,
        projectsDetails : projectsDetails,
        matix : matix
    },
});
