import React from 'react';
import { Link } from 'react-router-dom';
type TabName = 'dealInformation' | 'DealAnalyzer' | 'STR' | 'traditionalRental';

const DataTab: React.FC<{ tabName: TabName; className?: string; onClick?: any }> = ({
    tabName,
    className = 'profile--tab--action',
    onClick,
}) => {
    return (
        <div
            className='profile--tab flex items-center justify-between  mb-20 pr-20 sticky bg-white top-0 z-50'
            style={{ borderTopRightRadius: '1.2rem', borderTopLeftRadius: '1.2rem' }}
        >
            <div className='flex' style={{ paddingBottom: '0px', paddingTop: '20px' }}>
                <Link to={`/deal-analyzer`}>
                    <div className={tabName === 'dealInformation' ? className : ''}>Deal Infromation</div>
                </Link>
                {/* <div className={tabName === 'analysis' ? className : ''}> Deal Analysis</div>
        <div className={tabName === 'extimator' ? className : ''}>Repair Estimator</div> */}
                <Link to={`/deal-analyzer/rehab`}>
                    <div className={tabName === 'DealAnalyzer' ? className : ''}>Rehab</div>
                </Link>
                <Link to={`/deal-analyzer/traditional-rental`}>
                    <div className={tabName === 'STR' ? className : ''}>Traditional Rental</div>
                </Link>
                <Link to={`/deal-analyzer/str`}>
                    <div className={tabName === 'traditionalRental' ? className : ''}>STR</div>
                </Link>
            </div>

            <button onClick={onClick} className='float-right submit__button h-40 w-110 fs-14'>
                Download
            </button>
        </div>
    );
};

export default DataTab;
