import ExtendTableCard from 'components/extendableCard';
import Pie from 'components/pie';
import React from 'react';
import useAnalyzer from '../../hooks/useAnalyzer';
import { devided } from '../../utils/math';
import commas from '../../utils/numberWithCommas';

const FinancedPurchase = () => {
    const { financedGrossIncome, financedPurchaseParcentage } = useAnalyzer();

    const data = [
        { title: 'Annual Gross Income', value: 100 - Number(financedPurchaseParcentage()), color: '#50bfd818' },
        { title: 'Monthly Gross Income', value: Number(financedPurchaseParcentage()), color: '#50bfd8' },
    ];

    return (
        <div>
            <ExtendTableCard
                className='dealinformation__1'
                title='FINANCED PURCHASE
'
                isOpen={true}
            >
                <div className='py-30 flex justify-between items-center relative px-20'>
                    <div className='w-5/12 flex flex-col justify-center items-center'>
                        <h4 className='fs-14 brand-text-color mb-10'>Annual Gross Income</h4>
                        <h2 className='fs-16 brand-text mb-10 font-semibold'>${commas(financedGrossIncome())}</h2>
                        <h4 className='fs-14 brand-text-color mb-10'>Monthly Gross Income</h4>
                        <h2 className='fs-16 brand-text font-semibold'>${commas(devided(financedGrossIncome(), 12))}</h2>
                    </div>
                    <div className='w-6/12 relative'>
                        <div className='absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center'>
                            <h3 className='fs-20 font-bold brand-text'>{financedPurchaseParcentage()}%</h3>
                        </div>
                        <Pie data={data} />
                    </div>
                </div>
            </ExtendTableCard>
        </div>
    );
};

export default FinancedPurchase;
