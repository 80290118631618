import React from "react";
import "./filter.css";
import { IoMdClose } from "react-icons/io";
import { FiPlusCircle } from "react-icons/fi";
import { RiArrowDownSLine } from "react-icons/ri";
import axios from "axios";
import Cookies from "js-cookie";
import { Range } from "rc-slider";
import "rc-slider/assets/index.css";

class Filter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			defaultValue: {
				bed: 1,
				bath: 1,
				["est value"]: [2, 15],
				["rent value"]: [2, 15],
				["STR value"]: [2, 15],
				["STRCap value"]: [0, 100],
				["rentCap value"]: [0, 100]
			},
			showAdd: false,
			estated_max: 0,
			estated_min: 0,
			rental_max: 0,
			rental_min: 0,
			str_max: 0,
			str_min: 0,
			str_cpa_max: "0",
			str_cpa_min: "0",
			filters: props.filters
		};
	}

	async componentDidMount() {
		if (this.props.minMaxData !== null) {
			this.setState({
				defaultValue: {
					bed: 1,
					bath: 1,
					["est value"]: [this.props.minMaxData.estated_min, this.props.minMaxData.estated_max],
					["rent value"]: [this.props.minMaxData.rental_min, this.props.minMaxData.rental_max],
					["STR value"]: [this.props.minMaxData.str_min, this.props.minMaxData.str_max],
					["STRCap value"]: [0, 100],
					["rentCap value"]: [0, 100]
				},
				bed_max: 100,
				bed_min: 1,
				bath_max: 100,
				bath_min: 1,
				estated_max: this.props.minMaxData.estated_max,
				estated_min: this.props.minMaxData.estated_min,
				rental_max: this.props.minMaxData.rental_max,
				rental_min: this.props.minMaxData.rental_min,
				str_max: this.props.minMaxData.str_max,
				str_min: this.props.minMaxData.str_min,
				str_cpa_max: this.props.minMaxData.str_cpa_max,
				str_cpa_min: this.props.minMaxData.str_cpa_min,
				rent_cpa_max: this.props.minMaxData.rent_cpa_max,
				rent_cpa_min: this.props.minMaxData.rent_cpa_min
			});
		}
	}

	addFilter = (e) => {
		var filters = this.state.filters;
		var found = false;
		filters.map((filter) => {
			if (filter.name === e) {
				found = true;
			}
		});
		if (found === false) {
			var f = {
				name: e,
				value: this.state.defaultValue[e],
				open: false
			};
			filters.push(f);
			this.setState({ filters: filters, showAdd: false }, () => {
				this.props.handleFilter(this.state.filters);
			});
		} else {
			this.setState({ showAdd: false });
		}
	};

	handleRemoveFilter = (e) => {
		var filters = this.state.filters;
		var newFilters = [];
		filters.map((filter) => {
			if (filter.name !== e) {
				newFilters.push(filter);
			}
		});
		this.setState({ filters: newFilters }, () => {
			this.props.handleFilter(this.state.filters);
		});
	};

	handleOpenSlider = (e) => {
		var filters = this.state.filters;
		filters.map((filter) => {
			if (filter.name === e) {
				filter.open = !filter.open;
			}
		});
		this.setState({ filters: filters });
	};

	handleChangeSingle = (e) => {
		var filters = this.state.filters;
		filters.map((filter) => {
			if (filter.name === e.target.name) {
				filter.value = e.target.value;
			}
		});
		this.setState({ filters: filters });
	};

	handleChangeRange = (value, name) => {
		console.log(value, name);
		var filters = this.state.filters;
		filters.map((filter) => {
			if (filter.name === name) {
				filter.value = value;
			}
		});
		this.setState({ filters: filters });
	};

	handleAfterChangeRange = (e) => {
		this.props.handleFilter(this.state.filters);
	};

	render() {
		var fBed = false,
			fBath = false,
			fEst = false,
			fRent = false,
			fSTR = false,
			fSTRCap = false,
			frentCap = false;
		this.state.filters.map((filter) => {
			if (filter.name === "bed") {
				fBed = true;
			} else if (filter.name === "bath") {
				fBath = true;
			} else if (filter.name === "est value") {
				fEst = true;
			} else if (filter.name === "rent value") {
				fRent = true;
			} else if (filter.name === "STR value") {
				fSTR = true;
			} else if (filter.name === "STRCap value") {
				fSTRCap = true;
			} else if (filter.name === "rentCap value") {
				frentCap = true;
			}
		});
		return (
			<div className="filter-container">
				{this.state.filters.map((f, index) => {
					if (f.name === "bed" || f.name === "bath") {
						return (
							<div className="filter-button">
								<div className="left">
									<i onClick={() => this.handleRemoveFilter(f.name)}>
										<IoMdClose />
									</i>
									<p>{f.name}:</p>
								</div>
								<input type="number" min={this.state.bed_min} max={this.state.bed_max} onChange={this.handleChangeSingle} value={f.value} name={f.name} />
							</div>
						);
					} else if (f.name === "bath") {
						return (
							<div className="filter-button">
								<div className="left">
									<i onClick={() => this.handleRemoveFilter(f.name)}>
										<IoMdClose />
									</i>
									<p>{f.name}:</p>
								</div>
								<input type="number" min={this.state.bath_min} max={this.state.bath_max} onChange={this.handleChangeSingle} value={f.value} name={f.name} />
							</div>
						);
					} else if (f.name === "est value") {
						return (
							<div className="filter-button includes-slider">
								<div className="left">
									<i onClick={() => this.handleRemoveFilter(f.name)}>
										<IoMdClose />
									</i>
									<p>{f.name}:</p>
								</div>
								<div className="right" onClick={() => this.handleOpenSlider(f.name)}>
									<p>
										{f.value[0] > 999 ? Math.round(f.value[0] / 1000) + "k" : f.value[0]}-{f.value[1] > 999 ? Math.round(f.value[1] / 1000) + "k" : f.value[1]}
									</p>
									<i style={{ transform: f.open ? "rotate(180deg)" : "rotate(0deg)" }}>
										<RiArrowDownSLine />
									</i>
								</div>
								<div className={f.open ? "range-slider-container show" : "range-slider-container"}>
									<div className="range-slider">
										<Range
											min={this.state.estated_min}
											max={this.state.estated_max}
											value={f.value}
											onChange={(v) => this.handleChangeRange(v, f.name)}
											onAfterChange={this.handleAfterChangeRange}
										/>
									</div>
									<div className="range-slider-text">
										<p>{this.state.estated_min > 999 ? Math.round(this.state.estated_min / 1000) + "k" : this.state.estated_min}</p>
										<p>{this.state.estated_max > 999 ? Math.round(this.state.estated_max / 1000) + "k" : this.state.estated_max}</p>
									</div>
								</div>
							</div>
						);
					} else if (f.name === "rent value") {
						return (
							<div className="filter-button includes-slider">
								<div className="left">
									<i onClick={() => this.handleRemoveFilter(f.name)}>
										<IoMdClose />
									</i>
									<p>{f.name}:</p>
								</div>
								<div className="right" onClick={() => this.handleOpenSlider(f.name)}>
									<p>
										{f.value[0] > 999 ? Math.round(f.value[0] / 1000) + "k" : f.value[0]}-{f.value[1] > 999 ? Math.round(f.value[1] / 1000) + "k" : f.value[1]}
									</p>
									<i style={{ transform: f.open ? "rotate(180deg)" : "rotate(0deg)" }}>
										<RiArrowDownSLine />
									</i>
								</div>
								<div className={f.open ? "range-slider-container show" : "range-slider-container"}>
									<div className="range-slider">
										<Range
											min={this.state.rental_min}
											max={this.state.rental_max}
											value={f.value}
											onChange={(v) => this.handleChangeRange(v, f.name)}
											onAfterChange={this.handleAfterChangeRange}
										/>
									</div>
									<div className="range-slider-text">
										<p>{this.state.rental_min > 999 ? Math.round(this.state.rental_min / 1000) + "k" : this.state.rental_min}</p>
										<p>{this.state.rental_max > 999 ? Math.round(this.state.rental_max / 1000) + "k" : this.state.rental_max}</p>
									</div>
								</div>
							</div>
						);
					} else if (f.name === "STR value") {
						return (
							<div className="filter-button includes-slider">
								<div className="left">
									<i onClick={() => this.handleRemoveFilter(f.name)}>
										<IoMdClose />
									</i>
									<p>{f.name}:</p>
								</div>
								<div className="right" onClick={() => this.handleOpenSlider(f.name)}>
									<p>
										{f.value[0] > 999 ? Math.round(f.value[0] / 1000) + "k" : f.value[0]}-{f.value[1] > 999 ? Math.round(f.value[1] / 1000) + "k" : f.value[1]}
									</p>
									<i style={{ transform: f.open ? "rotate(180deg)" : "rotate(0deg)" }}>
										<RiArrowDownSLine />
									</i>
								</div>
								<div className={f.open ? "range-slider-container show" : "range-slider-container"}>
									<div className="range-slider">
										<Range
											min={this.state.str_min}
											max={this.state.str_max}
											value={f.value}
											onChange={(v) => this.handleChangeRange(v, f.name)}
											onAfterChange={this.handleAfterChangeRange}
										/>
									</div>
									<div className="range-slider-text">
										<p>{this.state.str_min > 999 ? Math.round(this.state.str_min / 1000) + "k" : this.state.str_min}</p>
										<p>{this.state.str_max > 999 ? Math.round(this.state.str_max / 1000) + "k" : this.state.str_max}</p>
									</div>
								</div>
							</div>
						);
					} else if (f.name === "STRCap value") {
						return (
							<div className="filter-button includes-slider">
								<div className="left">
									<i onClick={() => this.handleRemoveFilter(f.name)}>
										<IoMdClose />
									</i>
									<p>{f.name}:</p>
								</div>
								<div className="right" onClick={() => this.handleOpenSlider(f.name)}>
									<p>
										{Math.round(f.value[0])}-{Math.round(f.value[1])}
									</p>
									<i style={{ transform: f.open ? "rotate(180deg)" : "rotate(0deg)" }}>
										<RiArrowDownSLine />
									</i>
								</div>
								<div className={f.open ? "range-slider-container show" : "range-slider-container"}>
									<div className="range-slider">
										<Range min={0} max={100} value={f.value} onChange={(v) => this.handleChangeRange(v, f.name)} onAfterChange={this.handleAfterChangeRange} />
									</div>
									<div className="range-slider-text">
										<p>{0}</p>
										<p>{100}</p>
									</div>
								</div>
							</div>
						);
					} else if (f.name === "rentCap value") {
						return (
							<div className="filter-button includes-slider">
								<div className="left">
									<i onClick={() => this.handleRemoveFilter(f.name)}>
										<IoMdClose />
									</i>
									<p>{f.name}:</p>
								</div>
								<div className="right" onClick={() => this.handleOpenSlider(f.name)}>
									<p>
										{Math.round(f.value[0])}-{Math.round(f.value[1])}
									</p>
									<i style={{ transform: f.open ? "rotate(180deg)" : "rotate(0deg)" }}>
										<RiArrowDownSLine />
									</i>
								</div>
								<div className={f.open ? "range-slider-container show" : "range-slider-container"}>
									<div className="range-slider">
										<Range min={0} max={100} value={f.value} onChange={(v) => this.handleChangeRange(v, f.name)} onAfterChange={this.handleAfterChangeRange} />
									</div>
									<div className="range-slider-text">
										<p>{0}</p>
										<p>{100}</p>
									</div>
								</div>
							</div>
						);
					}
				})}
				{fBed === false || fBath === false || fEst === false || fRent === false || fSTR === false || fSTRCap === false || frentCap === false ? (
					<div className="add-filter-button">
						<button onClick={() => this.setState({ showAdd: !this.state.showAdd })} className={this.state.showAdd ? "selector-button active" : "selector-button"}>
							<i>
								<FiPlusCircle />
							</i>
							Add Filter
						</button>
						{this.state.showAdd ? (
							<div className="add-filter-list">
								{/* {fBed === false ? <p onClick={() => this.addFilter("bed")}>Bed</p> : null} */}
								{/* {fBath === false ? <p onClick={() => this.addFilter("bath")}>Bath</p> : null} */}
								{fEst === false ? <p onClick={() => this.addFilter("est value")}>Estimate Value</p> : null}
								{fRent === false ? <p onClick={() => this.addFilter("rent value")}>Rent/Mo</p> : null}
								{frentCap === false ? <p onClick={() => this.addFilter("rentCap value")}>Rent Cap</p> : null}
								{fSTR === false ? <p onClick={() => this.addFilter("STR value")}>STR Annual/Yr</p> : null}
								{fSTRCap === false ? <p onClick={() => this.addFilter("STRCap value")}>STR Cap</p> : null}
							</div>
						) : null}
					</div>
				) : null}
			</div>
		);
	}
}

export default Filter;
