import React from "react";
import { Link, useParams } from "react-router-dom";
import "./index.css";

type TabName =
	| "details"
	| "analysis"
	| "extimator"
	// | "comparable"
	| "market";

const DetailsTab: React.FC<{ tabName: TabName; className?: string; loading?: boolean }> = ({
	tabName,
	className = "active"
	// loading = false
}) => {
	const { search, proid } = useParams<{ search: string; proid: string }>();
	return (
		<div className="tab-bar page-main-tab-container">
			<Link to={`/dashboard/details/${search}/${proid}/view`}>
				<div className={tabName === "details" ? "tab " + className : "tab"}>Details</div>
			</Link>
			<Link to={`/dashboard/market/${search}/${proid}/view`}>
				<div className={tabName === "market" ? "tab " + className : "tab"}>Market Data</div>
			</Link>
		</div>
	);
};

export default DetailsTab;
