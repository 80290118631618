import Empty from "components/empty";
import Filter from "components/filter/filter";
import LoadingSkeleton from "components/loadingSkeleton";
import PaginationLink from "components/paginationLink";
import PropertyTR from "components/propertyTR";
import TableCompoent from "components/table/index.js";
import usePropertydata from "hooks/usePropertydata";
import React from "react";
import TableHeader from "./recent.TableHeader";
import Cookies from "js-cookie";
import { NavLink } from "react-router-dom";
import axios from "axios";
import numberWithCommas from "../../utils/numberWithCommas";

//Analytics
import ReactGA from "react-ga";

function Table() {
	const [filterLoading, setFilterLoading] = React.useState(false);
	const [filters, setFilters] = React.useState([]);
	const [minMaxData, setMinMax] = React.useState(null);
	const [filterData, setFilterData] = React.useState(null);
	const { searchHead, loading, changeFavourite, page, propertyData } = usePropertydata("/api/v1/general/address/recent-search");
	React.useEffect(() => {
		fetchMinMax();
		ReactGA.event({
			category: "Recent Page",
			action: "Page " + page
		});
	}, []);

	const fetchMinMax = async () => {
		const token = Cookies.get("token");
		const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/general/address/max-min-value?type=recent_search`, {
			headers: { Authorization: `Bearer ${token}` }
		});
		if (res.data !== null) {
			setMinMax(res.data);
			console.log("Filter params:", res.data);
		}
	};

	const handleFilter = async (e) => {
		console.log("FILTER ARRAY", e);
		if (e.length > 0) {
			setFilters(e);
			setFilterLoading(true);
			const token = Cookies.get("token");
			var bed_max, bed_min, bath_max, bath_min, estated_max, estated_min, rental_max, rental_min, str_max, str_min, str_cpa_max, str_cpa_min, rent_cpa_max, rent_cpa_min;
			e.map((fil) => {
				if (fil.name == "bed") {
					bed_min = 0;
					bed_max = fil.value;
				} else if (fil.name === "bath") {
					bath_min = 0;
					bath_max = fil.value;
				} else if (fil.name === "est value") {
					estated_min = fil.value[0];
					estated_max = fil.value[1];
				} else if (fil.name === "rent value") {
					rental_min = fil.value[0];
					rental_max = fil.value[1];
				} else if (fil.name === "STR value") {
					str_min = fil.value[0];
					str_max = fil.value[1];
				} else if (fil.name === "STRCap value") {
					str_cpa_min = fil.value[0];
					str_cpa_max = fil.value[1];
				} else if (fil.name === "rentCap value") {
					rent_cpa_min = fil.value[0];
					rent_cpa_max = fil.value[1];
				}
			});
			const params = {
				perpage: 10,
				// bed_value_start: bed_min ? bed_min : 0,
				// bed_value_end: bed_max ? bed_max : 100,
				// bath_value_start: bath_min ? bed_min : 0,
				// bath_value_end: bath_max ? bed_max : 100,
				estimate_value_start: estated_min ? estated_min : minMaxData.estated_min,
				estimate_value_end: estated_max ? estated_max : minMaxData.estated_max,
				rent_start: rental_min ? rental_min : minMaxData.rental_min,
				rent_end: rental_max ? rental_max : minMaxData.rental_max,
				rent_cpa_start: rent_cpa_min ? rent_cpa_min : minMaxData.rent_cpa_min,
				rent_cpa_end: rent_cpa_max ? rent_cpa_max : minMaxData.rent_cpa_max,
				str_cpa_start: str_cpa_min ? str_cpa_min : minMaxData.str_cpa_min,
				str_cpa_end: str_cpa_max ? str_cpa_max : minMaxData.str_cpa_max
			};
			console.log("RT 89 PARAMS", params);
			const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/general/address/recent-search`, {
				headers: { Authorization: `Bearer ${token}` },
				params: params
			});
			if (res.data !== null) {
				setFilterLoading(false);
				setFilterData(res.data);
			} else {
				setFilterLoading(false);
				setFilterData([]);
				setFilters([]);
			}
			console.log("RESULT:", res.data);
		} else {
			setFilters([]);
		}
	};

	if (loading === true || filterLoading === true) {
		return (
			<div className="bg-white mt-20 table--top--wraper">
				<TableHeader />
				<div className="main-table-loader">
					<LoadingSkeleton Head={searchHead} />
				</div>
				<div className="responsive-table-loader">
					<LoadingSkeleton Head={["", ""]} />
				</div>
			</div>
		);
	}

	return (
		<div className="bg-white mt-20 table--top--wraper">
			<TableHeader />
			<Filter filters={filters} handleFilter={handleFilter} minMaxData={minMaxData} />

			{filters.length === 0 ? (
				<>
					{!!propertyData.data.length ? (
						<div>
							<div className="table-container">
								<TableCompoent Th={searchHead}>
									{propertyData.data.map((value, index) => {
										return <PropertyTR value={value} changeFavourite={changeFavourite} />;
									})}
								</TableCompoent>
							</div>
							<div className="secondary-table-container">
								{propertyData.data.map((value) => {
									return (
										<div className="secondary-table-content">
											<NavLink to={`/dashboard/details/${value.mainAddress}/${value.id}/view/`}>
												<div className="left">
													<p>{value.address}</p>
													{value.str_cpa_used ? <h5 className="str-tag">STR</h5> : null}
												</div>
												<div className="right">
													<p>
														Est Value: <span>${numberWithCommas(value.est)}</span>
													</p>
													<p>
														Rent: <span>${numberWithCommas(value.rent)}</span>
													</p>
													<p>
														STR Annual: <span>${numberWithCommas(value.str_year)}</span>
													</p>
												</div>
											</NavLink>
										</div>
									);
								})}
							</div>
							{propertyData.meta.last_page !== 1 ? (
								<div className="py-25 flex items-center justify-center">
									<PaginationLink page={page} count={propertyData.meta.last_page} link="/recent?" />
								</div>
							) : null}
						</div>
					) : (
						<Empty />
					)}
				</>
			) : (
				<>
					<div className="table-container">
						{filterData !== null ? (
							<>
								{filterData.data.length > 0 ? (
									<>
										<TableCompoent Th={searchHead}>
											{filterData &&
												filterData.data.map((value, index) => {
													return <PropertyTR value={value} changeFavourite={changeFavourite} key={index} />;
												})}
										</TableCompoent>
									</>
								) : (
									<Empty />
								)}
							</>
						) : (
							<Empty />
						)}
					</div>
					<div className="secondary-table-container">
						{filterData !== null ? (
							<>
								{filterData.data.length > 0 ? (
									<>
										{filterData.data.map((value, index) => {
											return (
												<div className="secondary-table-content" key={index}>
													<NavLink to={`/dashboard/details/${value.mainAddress}/${value.id}/view/`}>
														<div className="left">
															<p>{value.address}</p>
															{value.str_cpa_used ? <h5 className="str-tag">STR</h5> : null}
														</div>
														<div className="right">
															<p>
																Est Value: <span>${numberWithCommas(value.est)}</span>
															</p>
															<p>
																Rent: <span>${numberWithCommas(value.rent)}</span>
															</p>
															<p>
																STR Annual: <span>${numberWithCommas(value.str_year)}</span>
															</p>
														</div>
													</NavLink>
												</div>
											);
										})}
									</>
								) : (
									<Empty />
								)}
							</>
						) : (
							<Empty />
						)}
					</div>
					{filterData && filterData.meta.last_page !== 1 ? (
						<div className="py-25 flex items-center justify-center">
							<PaginationLink page={page} count={propertyData.meta.last_page} link="/recent?" />
						</div>
					) : null}
				</>
			)}
		</div>
	);
}

export default Table;
