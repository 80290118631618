export const devided = (num, time, point = 0) => {
    const number = Number(num);
    const times = Number(time);
    if (number == 0 || times == 0) {
        return 0;
    }
    return (number / times).toFixed(point);
};

export const multiply = (num, time, point = 0) => {
    const number = Number(num);
    const times = Number(time);
    return (number * times).toFixed(point);
};
export function sum(input) {
    var total = 0;
    for (var i = 0; i < input.length; i++) {
        if (isNaN(input[i])) {
            continue;
        }
        total += Number(input[i]);
    }
    return total;
}
