import React from "react";
import { useSelector } from "react-redux";
import { userState } from "reducers/user";
import CurrentUsages from "./currentUsages";
import Pakages from "./Profile.Pakages";

//Analytics
import ReactGA from "react-ga";

function ProfleBodySubscription() {
	const { subs_info, usages } = useSelector(userState);

	React.useEffect(() => {
		ReactGA.event({
			category: "Profile",
			action: "Click_Current_Plan_Tab"
		});
	}, []);

	return (
		<div className="profilebody--subscription">
			<div className="profilebody--subscription--heading flex items-center h-92 px-40 mt-30">
				<h3 className="f-p fs-24 fw-600">Your Current Plan:</h3>
				<h5 className="ml-35 fw-500 fs-20">{subs_info.subs_package}</h5>
			</div>
			<div className="flex -mx-15 package-group">
				<div className="w-6/12 px-15 package">
					<Pakages packageName="Current Plan Details" />
				</div>
				<div className="w-6/12 px-15 package">
					<CurrentUsages />
				</div>
			</div>
		</div>
	);
}

export default ProfleBodySubscription;
