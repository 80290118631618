import { Dialog } from '@material-ui/core';
import axios from 'axios';
import Cookies from 'js-cookie';
import React from 'react';
import { setUser } from 'reducers/user';
import { useDispatch } from 'react-redux';
import { setMessage } from 'reducers/subscriptionModal';
import { Button } from 'styles/Buttons';
const Cancel: React.FC<{
    open: boolean;
    onClose: Function;
}> = ({ open, onClose }) => {

    const dispatch = useDispatch();
    const onCancel = async () => {
        const token = Cookies.get('token');

        const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/v1/general/subscriptions/my-subscription/cancel
            `,
            { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.status == 200) {
            const getUserData = async () => {
                try {
                    if (!!token) {
                        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/general/get-user-info`, {
                            headers: { Authorization: `Bearer ${token}` },
                        });
                        dispatch(setMessage("You subscription is canceled"))
                        onClose(false);
                        dispatch(setUser(response.data.data));
                    }
                } catch (e) {
                    Cookies.remove('token');
                }
            };

            getUserData();
        }
    };
    return (
        <Dialog
            maxWidth={'sm'}
            fullWidth={true}
            className='modal'
            onClose={() => onClose(false)}
            open={open}
            disablePortal={true}
            disableEnforceFocus={false}
            disableAutoFocus={false}
            aria-labelledby='server-modal-title'
            aria-describedby='server-modal-description'
        >
            <div className='py-40 '>
                <h3 className='fs-24 mb-20 text-center '>Are you sure?</h3>
                <div className='flex justify-center '>
                    <Button
                        onClick={onCancel}
                        className='submit__button '
                        style={{ width: '70px', height: '40px', color: 'white', marginRight: '20px', border: 'none' }}
                    >
                        yes
                    </Button>
                    <Button
                        onClick={() => onClose(false)}
                        className='outline__button '
                        style={{ width: '70px', height: '40px', color: '' }}
                    >
                        No
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default Cancel;
