import Navbar from 'components/navbar';
import React from 'react';
import ProfileBody from './ProfileBody/index';
function Profile() {
    return (
        <div className='dashboard--wraper pt-15 pl-15 pr-15 pb-15'>
            <Navbar />
            <ProfileBody />
        </div>
    );
}

export default Profile;
