import React, { useEffect } from "react";
import SideBar from "../../components/sidebar";
import Details from "./Details";

import MobileDashboardNav from "../../components/mobileDashboardNav/mobileDashboardNav";

function DashboardProperty() {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<MobileDashboardNav />
			<div className="flex">
				<div className="main-sidebar-container">
					<SideBar />
				</div>
				<Details />
			</div>
		</>
	);
}

export default DashboardProperty;
