import React, { Component } from "react";
import "./marketZipCodeRental.css";
import Chart from "react-apexcharts";
import numberWithCommas from "utils/numberWithCommas";

export default class MarketZipCodeRental extends Component {
	constructor(props) {
		super(props);
		var labels = [],
			data1 = [],
			data2 = [],
			data3 = [],
			mapped = [];
		if (JSON.parse(props.data.zipcode_rental_data) && JSON.parse(props.data.zipcode_rental_data).rentalData) {
			var {
				rentalData: { history }
			} = JSON.parse(props.data.zipcode_rental_data);

			mapped = Object.entries(history).map(([k, v]) => {
				var a = {
					date: k,
					...v
				};
				return a;
			});
			for (var i = 0; i < mapped.length; i++) {
				data1.push(mapped[i].minRent);
				data2.push(mapped[i].averageRent);
				data3.push(mapped[i].maxRent);
				labels.push(mapped[i].date);
			}
		}
		this.state = {
			series: [
				{
					name: "Max Rent",
					data: data3
				},
				{
					name: "Average Rent",
					data: data2
				},
				{
					name: "Min Rent",
					data: data1
				}
			],
			options: {
				chart: {
					type: "line",
					zoom: {
						enabled: false
					},
					toolbar: {
						show: false
					}
				},
				labels: labels,
				stroke: {
					show: true,
					curve: "smooth",
					colors: ["#25A0FC", "#3FEAB0", "#FED88D"],
					width: 3
				},
				dataLabels: {
					show: false
				},
				legend: {
					show: false
				},
				xaxis: {
					type: "datetime"
				},
				yaxis: {
					labels: {
						formatter: function (value) {
							if (value) {
								return "$" + numberWithCommas(Math.round(value));
							} else {
								return "0%";
							}
						}
					}
				},
				markers: {
					colors: ["#25A0FC", "#3FEAB0", "#FED88D"]
				}
			}
		};
	}
	render() {
		return (
			<div className="market-zip-rental-main-container">
				<div className="market-zip-rental-container">
					<h1>Zip Code Rental Data</h1>
					{this.state.options.labels.length > 0 ? <Chart options={this.state.options} series={this.state.series} height={360} type="line" /> : <p>No data found</p>}
				</div>
			</div>
		);
	}
}
