import React, { useEffect } from "react";
import AllModals from "components/AllModals";
import FreshChat from "components/FreshChat";
import BatchUpload from "pages/batchUpload";
import BillingHistory from "pages/billingHistory";
import CurrentPlan from "pages/currentPlan";
import ForgetPassword from "pages/forgetPassword";
import Logout from "pages/logout";
import MarketProperties from "pages/marketProperties/marketProperties";
import PaymentMethod from "pages/paymentMethod";
import RecoveryPassword from "pages/recoveryPassword";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PrivateRoute from "utils/AuthRouter";
// import ComparateblePropertise from "./pages/comparableProperties";
import Dashboard from "./pages/dashboard";
import DealAnalyzer from "./pages/dealAnalyzer";
import DealInformation from "./pages/dealInformation";
import DealSTR from "./pages/dealSTR";
import DealRental from "./pages/dealTraditionalRental";
import Favorite from "./pages/favourites";
import Profile from "./pages/profile";
import PropertDetails from "./pages/propertyDetails";
import Recent from "./pages/recent";
import Estimator from "./pages/RepairEstimator";
//Metarial
// import AuthRoute from "./utils/AuthRoute";
//pages
import Signin from "./pages/signin";
import Signup from "./pages/signup/";
import SingleBatch from "./pages/singleBatch";

//Analytics
import ReactGA from "react-ga";

function App() {
	useEffect(() => {
		ReactGA.initialize("UA-201316637-1");
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);
	return (
		<BrowserRouter>
			<AllModals />

			<Switch>
				{/* <Route path="/" exact component={Signin} /> */}
				<Route exact path="/signin" component={Signin} />
				<Route path="/signup" component={Signup} />
				<Route path="/logout" component={Logout} />
				<Route exact path="/signup" component={Signup} />
				<Route exact path="/forget-password" component={ForgetPassword} />
				<Route exact path="/reset-password" component={RecoveryPassword} />

				<PrivateRoute>
					<FreshChat />
					<Switch>
						<Route exact path="/dashboard" component={Dashboard} />
						<Route exact path="/dashboard/batch/:id/:name/view" component={SingleBatch} />
						<Route path="/favourites" component={Favorite} />
						<Route path="/recent" component={Recent} />
						<Route path="/batch-upload" component={BatchUpload} />
						<Route path="/deal-analyzer/deal-information" component={DealInformation} />
						<Route path="/deal-analyzer/rehab" component={DealAnalyzer} />
						<Route path="/deal-analyzer/traditional-rental" component={DealSTR} />
						<Route path="/deal-analyzer/str" component={DealRental} />
						<Route path="/deal-analyzer" component={DealInformation} />
						<Route path="/repair-estimator" component={Estimator} />
						<Route path="/profile" component={Profile} />
						<Route path="/current-plan" component={CurrentPlan} />
						<Route path="/billing-history" component={BillingHistory} />
						<Route path="/pyment-method" component={PaymentMethod} />
						<Route exact path="/dashboard/details/:search/:proid/view" component={PropertDetails} />
						{/* <Route exact path="/dashboard/comparable/:search/:proid/view" component={ComparateblePropertise} /> */}
						<Route exact path="/dashboard/market/:search/:proid/view" component={MarketProperties} />
					</Switch>
				</PrivateRoute>
			</Switch>
		</BrowserRouter>
	);
}

export default App;
