type Props = {
    password: string;
    password_confirmation: string;
};

const forgetPassValid = ({ password, password_confirmation }: Props): { isValid: boolean; errors: any } => {
    const errors: any = {};

    if (password.length < 6) {
        errors.password = 'Password must contain at least 6 charactes';
    }
    if (password !== password_confirmation) {
        errors.password_confirmation = 'The new password and confirmation password do not match';
    }

    return {
        isValid: !!Object.getOwnPropertyNames(errors).length ? false : true,
        errors: errors,
    };
};

export default forgetPassValid;
