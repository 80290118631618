import React from "react";
import DashboadrdTabBar from "./dashboadrd.table.TabBar";
import SearchBar from "./dashboard.table.searchbar";
import SearchTable from "./searchTable";
import FavTable from "./favTable";
function Table() {
	const query = new URLSearchParams(window.location.search);
	const tab: "search" | "favourite" = query.get("tab") === "favourite" ? "favourite" : "search";

	return (
		<div className="dashboard-table mt-20 bg-white">
			<SearchBar />

			<DashboadrdTabBar tabName={tab} />

			{tab === "favourite" ? <FavTable /> : <SearchTable />}
		</div>
	);
}

export default Table;
