const getFirstNameAndLastName = (fullname: string = '') => {
    const arrayString = fullname.split(' ');
    if (arrayString.length === 1) {
        return { first_name: arrayString[0], last_name: '' };
    }
    const first_name: string = arrayString.slice(0, -1).join(' ');
    const last_name: string = arrayString.slice(-1).join(' ');

    return { first_name, last_name };
};

export default getFirstNameAndLastName;
