import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { userState } from "reducers/user";

type TabName = "details" | "currentPlan" | "billingHistory" | "paymentMethod";

const ProfileTab: React.FC<{ tabName: TabName; className?: string }> = ({ tabName, className = "profile--tab--action" }) => {
	const { card_info } = useSelector(userState);
	return (
		<div className="profile--tab flex pt-20 mb-20 sticky bg-white top-0 z-50 profile-tab-container" style={{ borderTopRightRadius: "1.2rem", borderTopLeftRadius: "1.2rem" }}>
			<Link to={`/profile`}>
				<div className={tabName === "details" ? className : ""}>Details</div>
			</Link>
			<Link to={`/current-plan`}>
				<div className={tabName === "currentPlan" ? className : ""}>Current Plan</div>
			</Link>
			<Link to={`/billing-history`}>
				<div className={tabName === "billingHistory" ? className : ""}> Billing History </div>
			</Link>
			{card_info.status ? (
				<Link to={`/pyment-method`}>
					<div className={tabName === "paymentMethod" ? className : ""}>Payment Method</div>
				</Link>
			) : null}
		</div>
	);
};

export default ProfileTab;
