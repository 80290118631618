import React from 'react';
import DealTR from 'components/DealTR/dealTR';
import ExtendTableCard from 'components/extendableCard';
import useAnalyzer from '../../hooks/useAnalyzer';
import commas from '../../utils/numberWithCommas';
const PurchaseAnalysis = () => {
    const {
        afterRepairValue,
        purchasePrice,
        estimatedRepairs,
        totalFinancingCost,
        totolHoldingCost,
        totalBuyingTransactionCost,
        totalSellingTransactionCost,
        estimatedNetPropit,
    } = useAnalyzer();
    return (
        <div>
            <ExtendTableCard className='dealinformation__1' title='Purchase & Deal Analysis' isOpen={true}>
                <div className='border'>
                    <div>
                        <table className='fs-14' style={{ width: '100%' }}>
                            <DealTR right='After Repair Value ' input={<input type='text' value={'$' + commas(afterRepairValue)} />} />
                            <DealTR right='Purchase Price' input={<input type='text' value={'$' + commas(purchasePrice)} />} />
                            <DealTR right='Estimated Repair Costs' input={<input type='text' value={'$' + commas(estimatedRepairs)} />} />
                            <DealTR
                                right='Total Financing Costs'
                                input={<input type='text' value={'$' + commas(totalFinancingCost())} />}
                            />
                            <DealTR right='Total Holding Costs' input={<input type='text' value={'$' + commas(totolHoldingCost())} />} />
                            <DealTR
                                right='Total Buying Transaction Costs'
                                input={<input type='text' value={'$' + commas(totalBuyingTransactionCost())} />}
                            />
                            <DealTR
                                right='Total Selling Transaction Costs'
                                border={true}
                                input={<input type='text' value={'$' + commas(totalSellingTransactionCost())} />}
                            />
                        </table>
                        <div className='h-50 flex justify-between bg_brand2 items-center  text-white'>
                            <div className='fs-18 w-6/12 text-right pr-15 '>Estimated Net Profit</div>
                            <div className='fs-20 w-6/12 text-center'>${commas(estimatedNetPropit())}</div>
                        </div>
                    </div>
                </div>
            </ExtendTableCard>
        </div>
    );
};

export default PurchaseAnalysis;
