import requestForFavUnFav from 'api/favAndUnfav';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import removeLast from 'utils/removeLast';
type Data = {
    address: string;
    barth: number | string;
    bed: number | string;
    est: number | string;
    favorite: boolean;
    id: string | number;
    rent: string | number;
    rent_cpa: string | number;
    str_cpa: string | number;
    str_cpa_used: true;
    str_year: string | number;
    zip_code: string | number;
};

const usePropertydata = (baseUrl: string) => {
    const searchHead = ['', 'Date', 'Address', 'Estimate Value', 'Rent', 'STR Annual', 'Rental Cap', 'STR Cap'];

    const token = Cookies.get('token');
    const query = new URLSearchParams(window.location.search);
    const page = parseInt(query.get('page') || '1', 10);

    const [loading, setLoading] = useState(true);
    const [propertyData, setPropertyData] = useState<{ data: Data[]; meta: any }>({
        data: [],
        meta: {},
    });
    const changeFavourite = (propertyId: string) => {
        const searchArray = propertyData.data.map((property: any) =>
            property.id === propertyId ? { ...property, favorite: !property.favorite } : property
        );

        setPropertyData({ meta: propertyData.meta, data: searchArray });
        requestForFavUnFav(propertyId);
    };

    const getRecentSearch = async () => {
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}${baseUrl}?perpage=10&page=${page}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        const modifyData = response.data.data.map((object: any) => {
            return {
                ...object,
                address: `${removeLast(object.address)} ${object.zip_code}`,
                mainAddress: object.address,
            };
        });
        setPropertyData({ meta: response.data.meta, data: modifyData });
        setLoading(false);
    };
    const unFav = (propertyId: string) => {
        console.log(propertyId);
        const fav = propertyData.data;
        const findeIndex = fav.findIndex((data: any) => data.id === propertyId);
        fav.splice(findeIndex, 1);
        setPropertyData({
            ...propertyData,
            data: fav,
        });
        requestForFavUnFav(propertyId);
    };

    useEffect(() => {
        getRecentSearch();
    }, [page]);

    return { searchHead, loading, changeFavourite, page, propertyData, unFav };
};

export default usePropertydata;
