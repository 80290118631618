import React from 'react';
import ExtendTableCard from 'components/extendableCard';

import useAnalyzer from '../../hooks/useAnalyzer';
import commas from '../../utils/numberWithCommas';
import { devided } from '../../utils/math';
import UpDown from 'components/upDown';
const SummaryReturns3 = () => {
    const { financedGrossIncome, getSummaryReturns3 } = useAnalyzer();

    return (
        <div>
            <ExtendTableCard className='dealinformation__1' title='SUMMARY OF RETURNS with' isOpen={true}>
                <h3 className='fs-18 font-bold brand-text-color pt-20 px-20'>Maintenance & Vacancy</h3>
                <div className='py-30 flex justify-between items-center relative px-20'>
                    <div className='w-5/12 flex flex-col justify-center items-center'>
                        <h4 className='fs-14 brand-text-color mb-10'>Annual Gross Income</h4>
                        <h2 className='fs-16 brand-text mb-10 font-semibold'>${commas(financedGrossIncome())}</h2>
                        <h4 className='fs-14 brand-text-color mb-10'>Monthly Gross Income</h4>
                        <h2 className='fs-16 brand-text font-semibold'>${commas(devided(financedGrossIncome(), 12))}</h2>
                    </div>
                    <div className='w-6/12 flex flex-col items-center justify-center relative'>
                        <UpDown isUp={Number(getSummaryReturns3()) > 0 ? true : false} />
                        <h3 className='fs-20 brand-text font-bold mt-10'>{getSummaryReturns3()}%</h3>
                    </div>
                </div>
            </ExtendTableCard>
        </div>
    );
};

export default SummaryReturns3;
