import CancelModal from "components/cancelModal";
import dayjs from "dayjs";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "reducers/subscriptionModal";
import { userState } from "reducers/user";

//Analytics
import ReactGA from "react-ga";

const Pakages: React.FC<{ packageName: string }> = ({ packageName }) => {
	const dispatch = useDispatch();
	const [cancelModal, setCancelModal] = React.useState(false);
	const { subs_info, subs_type, next_billing_date, subs_search_remaining } = useSelector(userState);

	const handleClick = () => {
		ReactGA.event({
			category: "Profile",
			action: "Click_upgrade_button"
		});
	};

	return (
		<div className="pakages mt-10 mb-10">
			<CancelModal open={cancelModal} onClose={setCancelModal} />

			<div className="pakages--wraper">
				<div className="pakages--tab h-54 fs-16 fw-500 f-p ">
					<div>{packageName}</div>
				</div>
				<div className="p-20">
					<div>
						<div className="flex items-center justify-between mb-15">
							<div className="fs-16">Package Name</div>
							<div className="fs-16 brand-text">{subs_info.subs_package}</div>
						</div>
						{subs_type == "trial" ? (
							<div className="flex items-center justify-between mb-15">
								<div className="fs-16">{subs_type == "trial" ? "# of Searches Remaining" : "Limit per month"}</div>
								<div className="fs-16 brand-text">{subs_search_remaining}</div>
							</div>
						) : null}
						{/* {subs_info.subs_package == 'Monthly Plan' || subs_info.subs_package == 'Annual Plan' ? (
                            <div className='flex items-center justify-between mb-15'>
                                <div className='fs-16'>Regular rate</div>
                                <div className='fs-16 brand-text'>${subs_info.regular_rate}</div>
                            </div>
                        ) : null} */}

						<div className="flex items-center justify-between mb-15">
							<div className="fs-16">Overage</div>
							<div className="fs-16 brand-text">${subs_info.over_rate}</div>
						</div>
						<div className="flex items-center justify-between mb-15">
							<div className="fs-16">Batch Upload</div>
							<div className="fs-16 brand-text">{subs_info.batch ? "Yes" : "N/A"}</div>
						</div>
						<div className="flex items-center justify-between mb-15">
							<div className="fs-16">Start Date</div>
							<div className="fs-16 brand-text">{dayjs(subs_info.subs_start_date).format("MMM D, YYYY")}</div>
						</div>

						<div className="flex items-center justify-between mb-15">
							<div className="fs-16">Next Billing Date</div>
							<div className="fs-16 brand-text">{dayjs(next_billing_date).format("MMM D, YYYY")}</div>
						</div>
					</div>
					<div className="flex justify-between items-center mt-20">
						<button
							className="submit__button "
							onClick={() => {
								handleClick();
								dispatch(setModal(true));
							}}>
							Upgrade
						</button>
						{subs_type !== "trial" ? (
							<button className="ml-30 fs-16" onClick={() => setCancelModal(true)} style={{ color: "red" }}>
								Cancel Plan
							</button>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Pakages;
