import DealTR from 'components/DealTR/dealTR';
import ExtendTableCard from 'components/extendableCard';
import React from 'react';
import useAnalyzer from '../../hooks/useAnalyzer';
import commas from '../../utils/numberWithCommas';
const SummaryDeal2 = () => {
    const {
        loadAmout,
        mortgageInterest,
        loanTerminMonths,
        estimatedPrincipalInterest,
        downPayment,
        estimatedAnnaulNetExpense,
        totalCashNeeded,
    } = useAnalyzer();

    return (
        <div>
            <ExtendTableCard className='dealinformation__2' title='SUMMARY OF DEAL' isOpen={true}>
                <div className='border'>
                    <div>
                        <table className='fs-14' style={{ width: '100%' }}>
                            <DealTR right='Loan Amout ' input={<input type='text' value={'$' + commas(loadAmout())} />} />
                            <DealTR right='Interest Rate' input={<input type='text' value={commas(mortgageInterest) + '%'} />} />
                            <DealTR right='Loan Terms in Months' input={<input type='text' value={commas(loanTerminMonths)} />} />
                            <DealTR
                                right='Estimated Principal & Interest'
                                input={<input type='text' value={'$' + commas(estimatedPrincipalInterest())} />}
                            />
                            <DealTR right='Down Payment' input={<input type='text' value={'$' + commas(downPayment())} />} />
                            <DealTR
                                right='Estimated Annual Principal & Interest'
                                input={<input type='text' value={'$' + commas((estimatedPrincipalInterest() * 12).toFixed(2))} />}
                            />
                            <DealTR
                                right='Estimated Annual Net Expenses'
                                border={true}
                                input={<input type='text' value={'$' + commas(estimatedAnnaulNetExpense())} />}
                            />
                        </table>
                        <div className='h-50 flex justify-between bg_brand2 items-center  text-white'>
                            <div className='fs-18 w-6/12 text-right pr-15 '>Total Cash Needed</div>
                            <div className='fs-20 w-6/12 text-center'>${commas(totalCashNeeded())}</div>
                        </div>
                    </div>
                </div>
            </ExtendTableCard>
        </div>
    );
};

export default SummaryDeal2;
