import { Grid } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import StatusCard from "../../components/statusCard";
import { matixInformation } from "../../reducers/matix";

function DashboardStatus() {
	const matix = useSelector(matixInformation);

	return (
		<div className="py-15">
			<Grid container spacing={2}>
				<StatusCard
					name={"Properties Searched"}
					secondName="(Current billing cycle)"
					number={matix.total_search}
					src="https://cdn.zeplin.io/5ef9b0be7b4748819ae0decf/assets/1e4b4f4a-ace1-4828-b9d3-7e112d0a398a.svg"
				/>
				<StatusCard
					name="Batches Uploaded"
					secondName="(Lifetime)"
					number={matix.number_of_batch}
					src="https://cdn.zeplin.io/5ef9b0be7b4748819ae0decf/assets/4ace4810-7ff8-4e99-8c44-8b5975aac6a7.svg"
				/>
				<StatusCard
					name="Properties Searched"
					secondName="(Lifetime)"
					number={matix.total_number_of_properties}
					src="https://cdn.zeplin.io/5ef9b0be7b4748819ae0decf/assets/fe3e1e39-a71b-4e80-9763-704d93d0da20.svg"
				/>
				<StatusCard
					name="Deals Analyzed"
					secondName="(Lifetime)"
					number={matix.property_deal_analyze}
					src="https://cdn.zeplin.io/5ef9b0be7b4748819ae0decf/assets/2055ac05-2085-4613-8902-282107d91318.svg"
				/>
			</Grid>
		</div>
	);
}

export default DashboardStatus;
