import { Grid } from "@material-ui/core";
import axios from "axios";
import { Input, InputLabel } from "components/Input";
import Snackbar from "components/snackbar";
import Cookies from "js-cookie";
import React from "react";
import { useDispatch } from "react-redux";
import { setUser } from "reducers/user";
import formatPhoneNumber from "utils/formatPhone";
function Details({ user, img }) {
	const { first_name, last_name, email, street_address, city, state, zip, company, phone, interested_in } = user;
	const [message, setMeassage] = React.useState("");
	const dispatch = useDispatch();
	const [profile, setProfile] = React.useState({
		first_name,
		last_name,
		email,
		street_address,
		interested_in,
		city,
		state,
		zip,
		company,
		phone: phone ? phone : ""
	});

	const onChange = (event) => {
		setProfile({ ...profile, [event.target.name]: event.target.value });
	};

	const numberOnChange = (event) => {
		setProfile({
			...profile,
			phone: ("" + event.target.value).replace(/\D/g, "")
		});
	};

	const onSubmit = async (event) => {
		event.preventDefault();
		// setLoading(true);
		const token = Cookies.get("token");
		const formData = new FormData();
		if (img.file) {
			formData.append("profile_pic", img.file ? img.file : null);
		}
		formData.append("first_name", profile.first_name || "");
		formData.append("last_name", profile.last_name || "");
		formData.append("email", profile.email || "");
		formData.append("street_address", profile.street_address || "");
		formData.append("interested_in", profile.interested_in || "");
		formData.append("city", profile.city || "");
		formData.append("state", profile.state || "");
		formData.append("zip", profile.zip || "");
		formData.append("phone", profile.phone || "");
		formData.append("company", profile.company || "");

		try {
			const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/general/update-user-info`, formData, {
				headers: { Authorization: `Bearer ${token}` }
			});
			setMeassage("Your profile information successfully updated");
			// setLoading(false);
			dispatch(
				setUser({
					...profile,
					profile_pic: img.file ? img.name : undefined
				})
			);
			if (img.file) {
				localStorage.setItem("profile", response.data.data.profile_pic);
			}
		} catch (err) {
			// setLoading(false);
		}
	};

	return (
		<div className="md:pl-30 md:pr-10 lg:pr-10 xl:pr-150 sm:pr-1 pt-29 pb-150 profile-container-body">
			<Snackbar open={!!message ? true : false} setError={setMeassage} message={message} bg="#50bfd8" />
			<form onSubmit={onSubmit}>
				<Grid container justify="space-between">
					<Grid item xs={12} sm={12} md={12} lg={5} xl={4}>
						<InputLabel text="First Name" />
						<Input placeholder="Your First Name" className="mt-10 mb-20" value={profile.first_name} name="first_name" onChange={onChange} />
						<InputLabel text="Last Name" />
						<Input placeholder="Your Last Name" className="mt-10 mb-20" value={profile.last_name} name="last_name" onChange={onChange} />
						<InputLabel text="Street Address" />
						<Input placeholder="Street Address" className="mt-10 mb-20" value={profile.street_address} name="street_address" onChange={onChange} />

						<Grid container spacing={2}>
							<Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
								<InputLabel text="State" />
								<Input placeholder="State" className="mt-10 mb-20" value={profile.state} name="state" onChange={onChange} />
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
								<InputLabel text="Zip" />
								<Input placeholder="Zip" type="number" className="mt-10 mb-20" value={profile.zip} name="zip" onChange={onChange} />
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={5} xl={4}>
						<InputLabel text="Company" />
						<Input placeholder="Company Name" className="mt-10 mb-20" value={profile.company} name="company" onChange={onChange} />
						<InputLabel text="Phone No" />
						<Input placeholder="Phone No" type="text" className="mt-10 mb-20" value={formatPhoneNumber(profile.phone)} name="phone" onChange={numberOnChange} />
						<InputLabel text="City" />
						<Input placeholder="City" className="mt-10 mb-20" value={profile.city} name="city" onChange={onChange} />

						{/* <div className='flex justify-between'>
                            <InputLabel text='Notifications' />
                            <div>
                                <IOSSwitch />
                            </div>
                        </div> */}
					</Grid>
				</Grid>
				<button type="submit" className="submit__button mt-40">
					Update Profile
				</button>
			</form>
		</div>
	);
}

export default Details;
