import React from 'react';
import ExtendTableCard from 'components/extendableCard';
import DealTRv3 from 'components/DealTR/dealTRv3';
import CalculationInput from 'components/calculationInput/calculationInput';
import numberWithCommas from '../../utils/numberWithCommas';
import useAnalyzer from '../../hooks/useAnalyzer';
const SellingCost = () => {
    const { getrealtorFees, gettransferConveyanceFees, totalSellingTransactionCost } = useAnalyzer();

    return (
        <div className='mt-20'>
            <ExtendTableCard className='dealinformation__2' title='Selling Transaction Costs' isOpen={true}>
                <div className='border'>
                    <div>
                        <table className='fs-14' style={{ width: '100%' }}>
                            <tr className='h-30 brand-text bg-white'>
                                <th className='w-6/12'></th>
                                <th>% of Purchase</th>

                                <th>Total</th>
                            </tr>
                            <DealTRv3
                                right='Escrow / Attorney Fees  '
                                middle={<span />}
                                input={<CalculationInput name='escrowAttorneyFees' />}
                            />
                            <DealTRv3
                                right='Selling Recording Fees'
                                middle={<span />}
                                input={<CalculationInput name='sellingRecordingFees' />}
                            />
                            <DealTRv3
                                right='Realtor Fees'
                                middle={<CalculationInput type='percentage' name='realtorFees' />}
                                input={<input type='text' value={'$' + numberWithCommas(getrealtorFees())} />}
                            />
                            <DealTRv3
                                right='Transfer & Conveyance Fees'
                                middle={<CalculationInput type='percentage' name='transferConveyanceFees' />}
                                input={<input type='text' value={'$' + numberWithCommas(gettransferConveyanceFees())} />}
                            />
                            <DealTRv3 right='Home Warranty' middle={<span />} input={<CalculationInput name='homeWarranty' />} />
                            <DealTRv3 right='Staging Costs' middle={<span />} input={<CalculationInput name='stagingCosts' />} />
                            <DealTRv3 right='Marketing Costs' middle={<span />} input={<CalculationInput name='marketingCosts' />} />
                            <DealTRv3
                                right='Miscellaneous Selling Costs'
                                middle={<span />}
                                input={<CalculationInput name='miscellaneousSellingCosts' />}
                            />
                        </table>
                        <div className='h-50 flex justify-between items-center  text-white' style={{ background: '#7b87ae' }}>
                            <div className='fs-18 w-7/12 text-right pr-15 '>Total Selling Transaction Cost</div>
                            <div className='fs-20 w-5/12 text-center'>${numberWithCommas(totalSellingTransactionCost())}</div>
                        </div>
                    </div>
                </div>
            </ExtendTableCard>
        </div>
    );
};

export default SellingCost;
