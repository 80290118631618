import { CardElement, ElementsConsumer } from "@stripe/react-stripe-js";
import axios from "axios";
import Cookies from "js-cookie";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { subscriptionListState } from "reducers/subscriptionList";
import { setModal, setSuccess } from "reducers/subscriptionModal";
import { setUser } from "reducers/user";
import CardSection from "./cardSection";
const CheckoutForm = (props: any) => {
	const token = Cookies.get("token");
	const dispatch = useDispatch();
	const [state, setState] = React.useState({
		name: ""
	});
	const [loading, setLoading] = React.useState<boolean>(false);
	const { details } = useSelector(subscriptionListState);
	const errorHande = () => {
		props.setError(true);
		setTimeout(() => {
			props.setError(false);
		}, 3000);
	};
	const requestRes = async (id: string) => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/api/v1/general/strip/user/create`,
				{ package_id: details.id, card_holder_name: state.name, stripe_id: id },
				{ headers: { Authorization: `Bearer ${token}` } }
			);

			if (response.status == 200) {
				console.log("29", response);
				const getUserData = async () => {
					try {
						if (!!token) {
							const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/general/get-user-info`, {
								headers: { Authorization: `Bearer ${token}` }
							});

							dispatch(setUser(response.data.data));
						}
					} catch (e) {
						Cookies.remove("token");
					}
				};

				getUserData();

				dispatch(setModal(false));
				var d = {
					type: true,
					package: details.package_name
				};
				dispatch(setSuccess(d));
				setLoading(false);
				var show = false;
				if (show) {
					props.measseWhenChangePackage(details.package_name);
				}
				setTimeout(() => {
					var d2 = {
						type: false
					};
					dispatch(setSuccess(d2));
					props.setSelected(false);
				}, 3000);
			} else {
				errorHande();
				setLoading(false);
			}
		} catch (error) {
			errorHande();
			setLoading(false);
		}
	};
	const onSubmit = async (event: any) => {
		event.preventDefault();
		if (loading === false) {
			const { stripe, elements } = props;
			if (!stripe || !elements) {
				return;
			}
			const cardElement = elements.getElement(CardElement);

			const { error, paymentMethod } = await stripe.createPaymentMethod({
				type: "card",
				card: cardElement
			});

			if (error) {
				// requestRes(paymentMethod.id);
				setLoading(false);
			} else {
				setLoading(true);
				requestRes(paymentMethod.id);

				// pass the token to your backend API
			}
		}
	};
	return (
		<div>
			<form onSubmit={onSubmit}>
				<CardSection state={state} setState={setState} />
				<button disabled={!props.stripe} className="submit__button mt-20  flex items-center justify-center" style={{ height: "40px", width: "100%" }}>
					{loading && <span className="button-spiner"></span>}

					{!loading ? "Make Payment" : "Process Payment"}
				</button>
				<div className="mt-15 fs-16 text-center" style={{ color: "red", maxHeight: props.errorOnPyment ? "100px" : "0px", overflow: "hidden", transition: "1s all" }}>
					<div>Oh no!! Payment Failed. Please try again</div>
				</div>
			</form>
		</div>
	);
};

function InjectedCheckoutForm(props: any) {
	return (
		<ElementsConsumer>
			{({ stripe, elements }) => (
				<CheckoutForm
					stripe={stripe}
					elements={elements}
					setSelected={props.setSelected}
					measseWhenChangePackage={props.measseWhenChangePackage}
					setError={props.setError}
					errorOnPyment={props.errorOnPyment}
				/>
			)}
		</ElementsConsumer>
	);
}

export default InjectedCheckoutForm;
