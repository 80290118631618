import React from 'react';

const DealTR = (props: any) => {
    return (
        <tr>
            <td className='text-right table__color pr-20 w-7/12'>{props.right}</td>
            <td className={`bg-white ${!props.border ? 'border-bottom ' : ''}`}>{props.input}</td>
        </tr>
    );
};

export default DealTR;
