function formatPhoneNumber(phone: string): string {
    phone = phone.replace(/[^\d]/g, '');
    // if(phone == 0){
    //     return ""
    // }
    //check if number length equals to 10
    // Trim the remaining input to ten characters, to preserve phone number format
    phone = phone.substring(0, 10);

    // Based upon the length of the string, we add formatting as necessary
    var size = phone.length;
    if (size == 0) {
        phone = '';
    } else if (size < 4) {
        phone = '(' + phone;
    } else if (size < 7) {
        phone = '(' + phone.substring(0, 3) + ') ' + phone.substring(3, 6);
    } else {
        phone = '(' + phone.substring(0, 3) + ') ' + phone.substring(3, 6) + ' - ' + phone.substring(6, 10);
    }

    return phone;
}

export default formatPhoneNumber;
