import CalculationInput from 'components/calculationInput/calculationInput';
import DealTR from 'components/DealTR/dealTR';
import ExtendTableCard from 'components/extendableCard';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropertyInformations, setInformation } from '../../reducers/projectDetials';

const PropertyDetails = () => {
    const dispatch = useDispatch();
    const PropertyInformation = useSelector(PropertyInformations);

    const onChange = (event: any) => {
        dispatch(setInformation({ name: event.target.name, value: event.target.value }));
    };

    return (
        <div className='w-6/12 px-15'>
            <ExtendTableCard className='dealinformation__1' title='Property Details' isOpen={true}>
                <div className='border'>
                    <table className='fs-14' style={{ width: '100%' }}>
                        <DealTR
                            right='Property Address'
                            input={
                                <input
                                    name='address'
                                    value={PropertyInformation.address}
                                    onChange={onChange}
                                    className='analyzer__input'
                                    type='text'
                                />
                            }
                        />
                        <DealTR
                            right='Bed'
                            input={
                                <input
                                    name='bed'
                                    value={PropertyInformation.bed}
                                    onChange={onChange}
                                    className='analyzer__input'
                                    type='number'
                                />
                            }
                        />
                        <DealTR
                            right='Bath'
                            input={
                                <input
                                    name='bath'
                                    onChange={onChange}
                                    value={PropertyInformation.bath}
                                    className='analyzer__input'
                                    type='number'
                                />
                            }
                        />
                        <DealTR
                            right='Total sqft'
                            input={
                                <input
                                    name='sqft'
                                    onChange={onChange}
                                    value={PropertyInformation.sqft}
                                    className='analyzer__input'
                                    type='number'
                                />
                            }
                        />
                        <DealTR right='Purchase Price' border={true} input={<CalculationInput name='purchasePrice' />} />
                    </table>
                </div>
            </ExtendTableCard>
        </div>
    );
};

export default PropertyDetails;
