import SideBar from "components/sidebar";
import React from "react";
import CurrentPlan from "./currentPlan";

import "./index.css";

import MobileDashboardNav from "../../components/mobileDashboardNav/mobileDashboardNav";

function index() {
	return (
		<>
			<MobileDashboardNav />
			<div className="flex">
				<div className="main-sidebar-container">
					<SideBar />
				</div>
				<CurrentPlan />
			</div>
		</>
	);
}

export default index;
