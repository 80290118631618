import { Dialog } from "@material-ui/core";
import Superhost from "assets/img/superhost.png";
import TableCompoent from "components/table/index.js";
import React from "react";
import numberWithCommas from "utils/numberWithCommas";
import back from "assets/img/left-arrow.png";
import "./comparable.scss";

//Analytics
import ReactGA from "react-ga";
const TableHead2 = ["", "Title", "Bed", "Bath", "Guest", "Occ", "Night", "ADR", "Price/mo", "Revenue", "Cleaning", "Distance", "Rating", "Reviews"];
const StrComparable = (props: any) => {
	React.useEffect(() => {
		ReactGA.event({
			category: "Property Details Page",
			action: "Property STR Comparable View"
		});
	}, []);

	const Table = ({ list }: { list: any[] }) => {
		const [showInfo, setShowInfo] = React.useState(false);

		interface faceProduct {
			// readonly superhost: string;
			// readonly title: string;
			// readonly bedrooms: number;
			// readonly bathrooms: number;
			// readonly accommodates: number;
			// readonly occ: number;
			// readonly adr: number;
			// readonly price_nightly: number;
			// readonly price_monthly: number;
			// readonly cleaning_fee: number;
			// readonly revenue: number;
			// readonly distance_from_center: string;
			// readonly rating_overall: string;
			// readonly reviews: number;
			// readonly listing_url: string;
			superhost: string;
			title: string;
			bedrooms: number;
			bathrooms: number;
			accommodates: number;
			occ: number;
			adr: number;
			price_nightly: number;
			price_monthly: number;
			cleaning_fee: number;
			revenue: number;
			distance_from_center: string;
			rating_overall: string;
			reviews: number;
			listing_url: string;
		}

		const [info, setData] = React.useState<Partial<faceProduct>>({});

		const handleCloseInfo = () => {
			setShowInfo(false);
		};

		const handleOpenInfo = (e: any) => {
			setShowInfo(true);
			setData(e);
			console.log(info);
		};

		const handleFixed = (value: any) => {
			var v = parseInt((value * 100).toFixed(2));
			return v;
		};

		return (
			<>
				<div className="market-header">
					<img onClick={props.handleBack} src={back} alt="back-key" />
					<h1>STR Summary</h1>
				</div>
				<div className="table-container">
					<TableCompoent Th={TableHead2} className="relative ">
						{list.map((data: any, index: number) => {
							return (
								<tr key={index}>
									<td style={{ padding: "1rem 0px" }}>
										<div className="flex justify-center items-center" style={{ width: "25px" }}>
											{data.superhost ? <img className="h-30" src={Superhost} alt="superhost" /> : ""}
										</div>
									</td>
									<td style={{ minWidth: "270px" }}>
										<a href={data.listing_url} target="_target">
											{data.title}
										</a>
									</td>
									<td>{data.bedrooms}</td>
									<td>{data.bathrooms}</td>
									<td>{data.accommodates}</td>
									<td>{(data.occ * 100).toFixed(2)}%</td>
									<td>${numberWithCommas(data.price_nightly ? data.price_nightly : 0)}</td>
									<td>${Number(data.adr).toFixed(2)}</td>
									<td>${numberWithCommas(data.price_monthly ? data.price_monthly : 0)}</td>
									<td>${numberWithCommas(data.revenue)}</td>
									<td>${numberWithCommas(data.cleaning_fee)}</td>
									<td>{Number(data.distance_from_center * 0.000621371192).toFixed(2)} mi</td>
									<td>{data.rating_overall}</td>
									<td>{data.reviews}</td>
								</tr>
							);
						})}
					</TableCompoent>
				</div>
				<div className="secondary-table-container">
					<Dialog onClose={handleCloseInfo} aria-labelledby="customized-dialog-title" open={showInfo}>
						<div className="info-container">
							<div className="info-container-body">
								<div className="info-container-section">
									<div className="info-container-section-left">
										<h1>Title</h1>
										<a href={info.listing_url} target="_blank" rel="noopener noreferrer">
											<p>{info.title}</p>
										</a>
									</div>
									<div className="info-container-section-right"></div>
								</div>
								<div className="info-container-section">
									<div className="info-container-section-left">
										<h1>Bed</h1>
										<p>{info.bedrooms}</p>
									</div>
									<div className="info-container-section-right">
										<h1>Bath</h1>
										<p>{info.bathrooms}</p>
									</div>
								</div>
								<div className="info-container-section">
									<div className="info-container-section-left">
										<h1>Guest</h1>
										<p>{info.accommodates}</p>
									</div>
									<div className="info-container-section-right">
										<h1>Occ</h1>
										<p>{handleFixed(info.occ) + "%"}</p>
									</div>
								</div>
								<div className="info-container-section">
									<div className="info-container-section-left">
										<h1>Night</h1>
										<p>${numberWithCommas(info.price_nightly ? info.price_nightly : 0)}</p>
									</div>
									<div className="info-container-section-right">
										<h1>ADR</h1>
										<p>${Number(info.adr).toFixed(2)}</p>
									</div>
								</div>
								<div className="info-container-section">
									<div className="info-container-section-left">
										<h1>Price/mo</h1>
										<p>${numberWithCommas(info.price_monthly ? info.price_monthly : 0)}</p>
									</div>
									<div className="info-container-section-right">
										<h1>Revenue</h1>
										<p>${numberWithCommas(info.revenue)}</p>
									</div>
								</div>
								<div className="info-container-section">
									<div className="info-container-section-left">
										<h1>Cleaning</h1>
										<p>${numberWithCommas(info.cleaning_fee)}</p>
									</div>
									<div className="info-container-section-right">
										<h1>Distance</h1>
										<p>{(Number(info.distance_from_center) * 0.000621371192).toFixed(2)} mi</p>
									</div>
								</div>
								<div className="info-container-section">
									<div className="info-container-section-left">
										<h1>Rating</h1>
										<p>{info.rating_overall}</p>
									</div>
									<div className="info-container-section-right">
										<h1>Reviews</h1>
										<p>{info.reviews}</p>
									</div>
								</div>
							</div>
						</div>
					</Dialog>
					{list.map((data: any, index: number) => {
						return (
							<div className="secondary-table-content" onClick={() => handleOpenInfo(data)} key={index}>
								<div className="top">{data.superhost ? <img className="h-30" src={Superhost} alt="superhost" /> : ""}</div>
								<div className="top">
									<div className="left">
										<p>{data.title}</p>
									</div>
									<div className="right">
										<p>
											Occ: <span>{(data.occ * 100).toFixed(2)}%</span>
										</p>
										<p>
											ADR: <span>${Number(data.adr).toFixed(2)}</span>
										</p>
									</div>
								</div>
								<div className="bottom">
									<div className="left">
										<p>
											Beds: <span>{data.bedrooms}</span>
										</p>
									</div>
									<div className="right">
										<p>
											Revenue: <span>${numberWithCommas(data.revenue)}</span>
										</p>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</>
		);
	};

	return (
		<div style={{ overflowX: "scroll", transform: "rotateX(180deg)" }}>
			<div style={{ transform: "rotateX(180deg)" }}>
				<Table list={props.list} />
			</div>
		</div>
	);
};

export default StrComparable;
