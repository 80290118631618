import { CardElement } from '@stripe/react-stripe-js';
import React from 'react';

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#303238',
            fontSize: '1.6rem',
            fontSmoothing: 'antialiased',
            '::placeholder': {
                color: '#CFD7DF',
            },
        },
        invalid: {
            color: '#e5424d',
            ':focus': {
                color: '#303238',
            },
        },
    },
};

const CardSection: React.FC<{ state: { name: string }; setState: any }> = ({ state, setState }) => {
    // const { card_info } = useSelector(userState);

    const onChange = (e: any) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };
    return (
        <div>
            <label className='block mb-10 fs-14'>Card Holder Name</label>
            <input
                name='name'
                type='text'
                id='card-holder-name'
                required
                className='StripeElement input__payment mb-10'
                value={state.name}
                onChange={onChange}
            />
            <label className='block mb-10 fs-14'>Card Details</label>
            <CardElement options={CARD_ELEMENT_OPTIONS} />
        </div>
    );
};

export default CardSection;
