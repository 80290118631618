import React from "react";
import Pdf from "react-to-pdf";
import { ContainerBox } from "styles/Box";
import DealTab from "../../components/DealTab/DealTab";
import Navbar from "../../components/navbar";
import CashPurchase from "./CashPurchase";
import FinancedPurchase from "./FinancedPurchase";
import PropertyAnalysis from "./PropertyAnalysis";
import SummaryOfDeal from "./SummaryOfDeal";
import SummaryOfDeal2 from "./SummaryOfDeal2";
import SummaryReturns from "./SummaryReturns";
import SummaryReturns2 from "./SummaryReturns2";
import SummaryReturns3 from "./SummaryReturns3";
import SummaryReturns4 from "./SummaryReturns4";

//Analytics
import ReactGA from "react-ga";

function Analyzer() {
	const ref = React.createRef();
	const options = {
		unit: "in",
		format: [1400, 1800]
	};

	React.useEffect(() => {
		ReactGA.event({
			category: "Dashboard",
			action: "Deal Analyzer STR"
		});
	}, []);

	return (
		<div className="dashboard--wraper pt-15 pl-15 pr-15 pb-15">
			<Navbar />

			<ContainerBox className="mt-20 ">
				<Pdf targetRef={ref} filename="str.pdf" options={options} scale={1} x={0.1} y={0.5}>
					{({ toPdf }) => <DealTab tabName={"traditionalRental"} onClick={toPdf} />}
				</Pdf>
				<div ref={ref}>
					<div className="px-20 mt-10 mb-20">
						<h3 className="fs-18 brand-text-color mb-20">Investment Property Analysis</h3>
						<PropertyAnalysis />
						<div className="flex py-20 -mx-20">
							<div className="w-6/12 px-20">
								<CashPurchase />
							</div>
							<div className="w-6/12 px-20">
								<FinancedPurchase />
							</div>
						</div>
					</div>
					<div className="px-20 pb-20 mb-20">
						<div className="flex -mx-20">
							<div className="w-6/12 px-20">
								<SummaryReturns />
							</div>
							<div className="w-6/12 px-20">
								<SummaryReturns2 />
							</div>
						</div>
					</div>
					<div className="px-20 pb-20 mb-20">
						<div className="flex -mx-20">
							<div className="w-6/12 px-20">
								<SummaryReturns3 />
							</div>
							<div className="w-6/12 px-20">
								<SummaryReturns4 />
							</div>
						</div>
					</div>
					<div className="px-20 pb-20 mb-20">
						<div className="flex -mx-20">
							<div className="w-6/12 px-20">
								<SummaryOfDeal />
							</div>
							<div className="w-6/12 px-20">
								<SummaryOfDeal2 />
							</div>
						</div>
					</div>
				</div>
			</ContainerBox>
		</div>
	);
}

export default Analyzer;
