import React from 'react'

function Analyzer() {
    return (
        <div>
            <svg className="w-20 h-28">
            <g id="_005-analytics" data-name="005-analytics" transform="translate(-95 -53)">
                <path id="Path_1282" d="M170.7 260.633a.58.58 0 0 0 .5.635h1.945l-2.088 2.953-2.242-2.613a.439.439 0 0 0-.39-.166.491.491 0 0 0-.356.261l-2.167 3.851a.749.749 0 0 0 .125.871.435.435 0 0 0 .69-.135l1.838-3.267 2.2 2.558a.444.444 0 0 0 .364.166.45.45 0 0 0 .338-.211l2.283-3.254v2.218a.515.515 0 1 0 1 0v-3.881a.691.691 0 0 0-.136-.439.427.427 0 0 0-.344-.178h-3.06a.58.58 0 0 0-.5.631z" className="cls-1" data-name="Path 1282" transform="translate(-66.327 -195.916)"/>
                <path id="Path_1283" d="M112.733 59.175l-5.859-5.831-.174-.177a.562.562 0 0 0-.4-.167h-8.412A2.9 2.9 0 0 0 95 55.9v16.956a2.9 2.9 0 0 0 2.888 2.9h12.275a2.9 2.9 0 0 0 2.888-2.9V59.733a.555.555 0 0 0-.159-.4zm-5.793-4.2l4.174 4.193h-3.874a.324.324 0 0 1-.3-.34zm4.989 17.883a1.775 1.775 0 0 1-1.767 1.783H97.888a1.775 1.775 0 0 1-1.767-1.783V55.9a1.775 1.775 0 0 1 1.767-1.783h7.932v4.706a1.445 1.445 0 0 0 1.421 1.461h4.689z" className="cls-1" data-name="Path 1283"/>
            </g>
            </svg>
        </div>
    
    )
}

export default Analyzer


