import { createSlice } from '@reduxjs/toolkit';
export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState: {
        message:"",
        subscription: false,
        success: false,
        payment: false,
        error: false,
        snackError : ""
    },
    reducers: {
        setModal: (state, action) => {
            return {
                ...state,
                subscription: action.payload,
                // subscription: false,
            };
        },
        setSuccess: (state, action) => {
            return {
                ...state,
                success: action.payload,
            };
        },
        setPaymentModal: (state, action) => {
            return {
                ...state,
                payment: action.payload,
            };
        },
        setMessage :(state,action)=>{
            return {
                ...state,
                message : action.payload
            }
        },
        setSnackError :(state,action)=>{
            return {
                ...state,
                snackError : action.payload
            }
        }
    },
});

export const { setModal, setSuccess, setPaymentModal, setMessage , setSnackError } = subscriptionSlice.actions;
export const subscriptionState = (state) => state.subcriptionReducer;
export default subscriptionSlice.reducer;
