import { createSlice } from '@reduxjs/toolkit';
export const projectsDetails = createSlice({
    name: 'projectsDetails',
    initialState: {
        address : "",
        bed : "",
        bath : "",
        sqft : "",
        vacant : "",
        evaluator : "",
        closingDate : "",
    

    },
    reducers: {
        setInformation: (state, action) => {
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };
        },
    },
});

export const { setInformation } = projectsDetails.actions;
export const PropertyInformations = (state) => state.projectsDetails;
export default projectsDetails.reducer;
