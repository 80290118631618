import ExtendTableCard from 'components/extendableCard';
import React from 'react';
import { devided } from 'utils/math';
import numberWithCommas from 'utils/numberWithCommas';
import useAnalyzer from '../../hooks/useAnalyzer';
import DefaultCard from './DefaultCard';

const StrFinancePurchase = () => {
    const { strfinancedGrossIncome, strfinancedPurchaseParcentage, strtotalCashNeeded } = useAnalyzer();

    const data = [
        { title: 'Annual Gross Income', value: 100 - Number(strfinancedPurchaseParcentage()), color: '#50bfd818' },
        { title: 'Monthly Gross Income', value: Number(strfinancedPurchaseParcentage()), color: '#50bfd8' },
    ];

    return (
        <div>
            <ExtendTableCard className='dealinformation__1' title='FINANCED PURCHASE' isOpen={true}>
                <DefaultCard
                    data={data}
                    parcent={strfinancedPurchaseParcentage()}
                    value_1={numberWithCommas(strfinancedGrossIncome())}
                    value_2={numberWithCommas(devided(strfinancedGrossIncome(), 12))}
                    capital={numberWithCommas(strtotalCashNeeded())}
                />
            </ExtendTableCard>
        </div>
    );
};

export default StrFinancePurchase;
