import React from 'react';
import Navbar from '../../components/navbar';
import Status from './dashboard.status';
import Table from './dashboard.table';
export default function Dashboard() {
    return (
        <div className='dashboard--wraper pt-15 pl-15 pr-15 pb-15'>
            <Navbar />
            <Status />
            <Table />
        </div>
    );
}
