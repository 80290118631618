import DealTR from 'components/DealTR/dealTR';
import React from 'react';
import useAnalyzer from '../../hooks/useAnalyzer';
import commas from '../../utils/numberWithCommas';
const PropertyAnalysis = () => {
    const {
        grossRents,
        afterRepairValue,
        annaulCapitalExpense,
        annaulMaintenanceExpense,
        annaulVacancyExpense,
        propertyTaxes,
        insurance,
        annaulHOA,
        annaulManagement,
    } = useAnalyzer();
    return (
        <div className='property__analysis dealinformation__1 flex ' style={{ border: '0.1rem solid #e7e7e7' }}>
            <div className='w-6/12 property__analysis__left'>
                <table className='fs-14' style={{ width: '100%' }}>
                    <DealTR right='Gross Rents ' input={<input type='text' value={'$' + commas(grossRents())} />} />
                    <DealTR right='ARV' input={<input type='text' value={'$' + commas(afterRepairValue)} />} />
                    <DealTR right='Annual Capital Expense' input={<input type='text' value={'$' + commas(annaulCapitalExpense())} />} />
                    <DealTR
                        right='Annual Maintenance Expense'
                        input={<input type='text' value={'$' + commas(annaulMaintenanceExpense())} />}
                    />
                    <DealTR right='Annual Vacancy Expense' input={<input type='text' value={'$' + commas(annaulVacancyExpense())} />} />
                </table>
            </div>
            <div className='w-6/12 property__analysis__right'>
                <table className='fs-14' style={{ width: '100%' }}>
                    <DealTR right='Annual Property Taxes' input={<input type='text' value={'$' + commas(propertyTaxes)} />} />
                    <DealTR right='Annual Property Insurance' input={<input type='text' value={'$' + commas(insurance)} />} />
                    <DealTR right='Annual HOA Dues & Utilities' input={<input type='text' value={'$' + commas(annaulHOA())} />} />
                    <DealTR right='Annual Management Fee' input={<input type='text' value={'$' + commas(annaulManagement())} />} />
                    <DealTR right='' input={<input type='text' />} />
                </table>
            </div>
        </div>
    );
};

export default PropertyAnalysis;
