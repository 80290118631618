import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import Remove from 'assets/svg/remove.svg';
import WarningImg from 'assets/svg/warning.svg';
import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteCsv } from 'reducers/csv.js';
const useStylesFacebook = makeStyles(() => ({
    root: {
        position: 'relative',
        color: '#50bfd8',
    },
}));

const isOpen = (csv: any) => {
    return !!Object.keys(csv).length;
};

function UploadingSnackbar({ csv }: { csv: any }) {
    const dispatch = useDispatch();
    const classes = useStylesFacebook();
    const removeDwonloadItem = (uuid: string): void => {
        dispatch(deleteCsv(uuid));
    };
    return (
        <Snackbar open={isOpen(csv)} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
            <div className='download-snack'>
                <div>
                    <h3>Uploading Batch</h3>
                </div>

                {Object.keys(csv).map((data: any, id: any) => {
                    return (
                        <div
                            key={id}
                            className='flex justify-between items-center px-10 py-10'
                            style={{ borderBottom: '0.1rem solid #ddd' }}
                        >
                            <div className='flex items-center'>
                                {csv[data].error ? (
                                    <div>
                                        <img
                                            onClick={() => removeDwonloadItem(data)}
                                            src={Remove}
                                            height='20px'
                                            width='20px'
                                            className='mr-10 cursor-pointer '
                                        />
                                    </div>
                                ) : null}

                                <div className='mr-10'>
                                    <p className='fs-14'>{csv[data].name}</p>
                                </div>
                            </div>

                            <Box position='relative' display='inline-flex'>
                                {csv[data].error ? (
                                    <img src={WarningImg} alt='warning' height='30px' width='30px' />
                                ) : (
                                    <CircularProgress className={classes.root} />
                                )}
                            </Box>
                        </div>
                    );
                })}
            </div>
        </Snackbar>
    );
}

export default UploadingSnackbar;
