import { createSlice } from '@reduxjs/toolkit';
export const subscriptionList = createSlice({
    name: 'subscriptionList',
    initialState: {
        list: [],
        details: {
            batch: 0,
            default: 0,
            id: 0,
            over_use_rate: '',
            package_name: '',
            package_type: '',
            regular_rate: '',
            search_per_month: 0,
            sub_title: '',
        },
    },
    reducers: {
        setList: (state, action) => {
            return {
                list: [...action.payload],
                details: action.payload.find((data) => data.default == 1),
            };
        },
        setDetails: (state, action) => {
            return {
                ...state,
                details: action.payload,
            };
        },
    },
});

export const { setList, setDetails } = subscriptionList.actions;
export const subscriptionListState = (state) => state.subcriptionListReducer;
export default subscriptionList.reducer;
