import Navbar from 'components/navbar';
import React from 'react';
import Table from './recent.table';

function Recent() {
    return (
        <div className='dashboard--wraper pt-15 pl-15 pr-15 pb-15'>
            <Navbar />
            <Table />
        </div>
    );
}

export default Recent;
