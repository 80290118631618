const removeLast = (text: string) => {
    if (last(text).toLowerCase() == 'usa' || typeof Number(last(text)) == 'number') {
        return text.substring(0, text.lastIndexOf(' '));
    } else {
        return text;
    }
};
function last(words: string) {
    var n = words.split(' ');

    return n[n.length - 1];
}

export default removeLast;
