import React from 'react';
import { CardListStyle, DataCardStyle } from '../../styles/Card';

export const DataCard = (props) => {
    return (
        <DataCardStyle color={props.color} barColor={props.barColor} className={props.class}>
            <div className='data--card--bar fs-20 flex items-center'>{props.barTitle}</div>
            <>{props.children}</>
        </DataCardStyle>
    );
};

export const DataCardList = ({ className = '', title = '', value  }) => {
    return (
        <CardListStyle className={`${className} flex justify-between items-center`}>
            <div className='card--list-title font-semibold'>{title}</div>
            <div className='card--list-value font-bold'>{value}</div>
        </CardListStyle>
    );
};
