import { useSelector } from 'react-redux';
import pmt from 'utils/pmt';
import { PropertyInformationState } from '../reducers/propertyInformation';
import { devided, multiply, sum } from '../utils/math';
const useAnalyzer = () => {
    const {
        STRManagementFee,
        loantoValue,
        strAnnaulRevenue,
        rentalManagementFee,
        loanTerminMonths,
        mortgageInterest,
        closingCosts,
        estimatedMaintenanceCosts,
        rentalIncome,
        totalSqft,
        propertyTaxes,
        HOACondoFees,
        insurance,
        utilities,
        afterRepairValue,
        estimatedRepairs,
        purchasePrice,
        holdTime,
        firstMortgagePoints,
        escrowAttorneyFees,
        firstMortgagePoints2,
        secondMortgageInterest,
        firstMortgageInterest,
        titleInsurance,
        miscMortgageInterest,
        miscMortgageLienAmount,
        secondMortgagePoints,
        miscellaneousFinancingCosts,
        miscMortgagePoints,
        miscellaneousBuyingCosts,
        sellingRecordingFees,
        homeWarranty,
        stagingCosts,
        marketingCosts,
        miscellaneousSellingCosts,
        transferConveyanceFees,
        realtorFees,
        capitalExpenses,
        rentalEstimatedVacancyRate,
        managementPercent,
    } = useSelector(PropertyInformationState);

    const totalMonthlyHoldingCosts = () =>
        sum([devided(propertyTaxes, 12, 2), parseFloat(HOACondoFees), devided(insurance, 12, 2), parseFloat(utilities)]).toFixed(2);
    const totolHoldingCost = () => multiply(totalMonthlyHoldingCosts(), holdTime);
    const totalPurchaseAndRepairCost = () => sum([parseFloat(purchasePrice), parseFloat(estimatedRepairs)]);

    const getfirstMortgagePoints = () => multiply(afterRepairValue, devided(firstMortgagePoints, 100, 10), 2);
    const getfirstMortgagePoints2 = () => devided(getfirstMortgagePoints(), multiply(100, firstMortgagePoints2), 2);

    //first
    const getfirstMortgageInterest = () => {
        var valueOfPoint = Number(multiply(getfirstMortgagePoints(), devided(firstMortgageInterest, 100, 2)));
        return multiply(devided(valueOfPoint, 12, 2), holdTime);
    };

    const getfirstMortgageInterestPayment = () => devided(getfirstMortgageInterest(), holdTime, 2);
    const getMortageLienAmount = () => {
        const value = 100 - firstMortgagePoints;
        return multiply(afterRepairValue, devided(value, 100, 10), 2);
    };
    const getSecondMortgagePoints = () => multiply(devided(secondMortgagePoints, 100, 2), getMortageLienAmount(), 0);

    const getSecondMortgageInterest = () => {
        var valueOfPoint = Number(multiply(getMortageLienAmount(), devided(secondMortgageInterest, 100, 2)));
        return multiply(devided(valueOfPoint, 12, 2), holdTime);
    };

    const getSecondMortgageInterestPayment = () => devided(getSecondMortgageInterest(), holdTime, 2);

    const getMicsAmount = () => multiply(afterRepairValue, devided(miscMortgageLienAmount, 100, 10), 2);
    const getMicsPoint = () => devided(getMicsAmount(), multiply(100, miscMortgagePoints), 2);
    const getMicInterest = () => {
        var valueOfPoint = Number(multiply(getMicsAmount(), devided(miscMortgageInterest, 100, 2)));
        return multiply(devided(valueOfPoint, 12, 2), holdTime);
    };

    // const getMicInterest = () => devided(multiply(getMicsPoint(), miscMortgageInterest), multiply(12, holdTime), 2);
    const getMicInterestPayment = () => devided(getMicInterest(), holdTime, 2);
    const gettitleInsurance = () => sum([500, multiply(purchasePrice, devided(titleInsurance, 100, 50))]);
    const totalBuyingTransactionCost = () => sum([gettitleInsurance(), escrowAttorneyFees, miscellaneousBuyingCosts]);
    const totalFinancingCost = () =>
        sum([
            getfirstMortgagePoints2(),
            getfirstMortgageInterest(),
            getSecondMortgagePoints(),
            getSecondMortgageInterest(),
            getMicsPoint(),
            getMicInterest(),
            miscellaneousFinancingCosts,
        ]);

    const getrealtorFees = () => multiply(afterRepairValue, devided(realtorFees, 100, 50));
    const gettransferConveyanceFees = () => multiply(afterRepairValue, devided(transferConveyanceFees, 100, 50));
    const totalSellingTransactionCost = () =>
        sum([
            escrowAttorneyFees,
            sellingRecordingFees,
            homeWarranty,
            stagingCosts,
            marketingCosts,
            miscellaneousSellingCosts,
            getrealtorFees(),
            gettransferConveyanceFees(),
        ]);

    const estimatedNetPropit = () => {
        return sum([
            afterRepairValue,
            -purchasePrice,
            -estimatedRepairs,
            -totalFinancingCost(),
            -totolHoldingCost(),
            -totalBuyingTransactionCost(),
            -totalSellingTransactionCost(),
        ]);
    };
    const totalCostReturn = () => {
        var value = afterRepairValue - estimatedNetPropit();
        return Number(multiply(devided(estimatedNetPropit(), value, 10), 100, 2));
    };
    const refairEstimtorPerSq = () => devided(sum([purchasePrice, estimatedRepairs]), totalSqft);
    const getDownpaymentRequire = () =>
        sum([
            purchasePrice,
            totalBuyingTransactionCost(),
            getfirstMortgagePoints2(),
            getSecondMortgagePoints(),
            getMicsPoint(),
            -getfirstMortgagePoints(),
            -getMortageLienAmount(),
            -getMicsAmount(),
        ]);
    const myCommittedCapital = () => {
        return sum([
            sum([purchasePrice, estimatedRepairs, getfirstMortgagePoints2()]),
            getSecondMortgagePoints(),
            getMicsPoint(),
            totolHoldingCost(),
            totalBuyingTransactionCost(),
            stagingCosts,
            marketingCosts,
            miscellaneousSellingCosts,
            -getfirstMortgagePoints(),
            -getMortageLienAmount(),
            -getMicsAmount(),
        ]);
    };
    const myAnnualizedCash = () => {
        const bal = devided(estimatedNetPropit(), myCommittedCapital(), 10);
        return multiply(devided(multiply(bal, 12, 10), holdTime, 100), 100, 2);
    };

    const totalAnnualized = () => multiply(totalCostReturn(), devided(12, holdTime, 10), 2);
    const rehabReturn = () => {
        return multiply(devided(estimatedNetPropit(), sum([purchasePrice, estimatedRepairs]), 10), 100, 2);
    };
    const grossRents = () => multiply(rentalIncome, 12, 2);
    const annaulCapitalExpense = () => multiply(grossRents(), devided(capitalExpenses, 100, 10), 2);
    const annaulMaintenanceExpense = () => multiply(grossRents(), devided(estimatedMaintenanceCosts, 100, 10), 2);
    const annaulManagement = () => multiply(grossRents(), devided(rentalManagementFee, 100, 10), 2);

    const annaulVacancyExpense = () => multiply(grossRents(), devided(rentalEstimatedVacancyRate, 100, 10), 2);
    const annaulHOA = () => sum([multiply(HOACondoFees, 12), multiply(utilities, 12)]);
    const AnnaulgrossIncome = (): number => sum([grossRents(), -propertyTaxes, -insurance, -annaulHOA(), -annaulManagement()]);
    const AnnaulgrossIncome2 = (): number => {
        return sum([
            grossRents(),
            -sum([
                propertyTaxes,
                insurance,
                annaulHOA(),
                Number(annaulManagement()),
                Number(annaulCapitalExpense()),
                -sum([annaulMaintenanceExpense(), annaulVacancyExpense()]),
            ]),
        ]);
    };
    const financedGrossIncome = () =>
        sum([
            grossRents(),
            -propertyTaxes,
            -insurance,
            -annaulHOA(),
            -annaulManagement(),
            -annaulCapitalExpense(),
            -annaulMaintenanceExpense(),
            -annaulVacancyExpense(),
        ]);
    const getSummaryReturns2 = () => multiply(devided(AnnaulgrossIncome2(), downPayment(), 2), 100, 2);
    const financedPurchaseParcentage = () => multiply(devided(financedGrossIncome(), multiply(0.2, purchasePrice, 2), 2), 100, 2);
    const purchaseParcentage = () => multiply(devided(AnnaulgrossIncome(), afterRepairValue, 10), 100, 2);
    const totolCashOutlay = () => sum([afterRepairValue, closingCosts]);
    const estimatedAnnaulNetExpense = () => sum([propertyTaxes, insurance, annaulHOA(), annaulManagement()]);
    const estimatedAnnaulNetExpense2 = () => {
        let annaulinterest = multiply(estimatedPrincipalInterest(), 12);
        return sum([grossRents(), -sum([annaulinterest, estimatedAnnaulNetExpense(), annaulVacancyExpense(), annaulMaintenanceExpense()])]);
    };
    //sssssss
    const getSummaryReturns3 = () => multiply(devided(financedGrossIncome(), totolCashOutlay(), 10), 100, 2);
    const downPayment = () => Number(multiply(purchasePrice, devided(sum([100, -loantoValue]), 100, 2)));
    const loadAmout = () => {
        return purchasePrice - downPayment();
    };
    const estimatedPrincipalInterest = () => {
        var parcentageValue = Number(devided(mortgageInterest, 100, 10));

        return Number(pmt(parcentageValue / 12, loanTerminMonths, loadAmout()));
    };
    const maintenanceVacancyIncome = () =>
        sum([
            grossRents(),
            propertyTaxes,
            -insurance,
            -annaulHOA(),
            -annaulManagement(),
            -annaulCapitalExpense(),
            -Number(annaulMaintenanceExpense()),
            -annaulVacancyExpense(),
        ]);
    const strannaulManagement = () => {
        return multiply(strAnnaulRevenue, Number(devided(STRManagementFee, 100, 10)), 2);
    };
    const strannaulCapitalExpense = () => multiply(strAnnaulRevenue, devided(capitalExpenses, 100, 10), 2);
    const strannaulMaintenanceExpense = () => multiply(strAnnaulRevenue, devided(estimatedMaintenanceCosts, 100, 10), 2);
    const strannaulVacancyExpense = () => multiply(strAnnaulRevenue, devided(rentalEstimatedVacancyRate, 100, 10), 2);
    const strAnnaulgrossIncome = (): number => sum([strAnnaulRevenue, -propertyTaxes, -insurance, -annaulHOA(), -strannaulManagement()]);
    const strpurchaseParcentage = () => multiply(devided(strAnnaulgrossIncome(), sum([purchasePrice, estimatedRepairs]), 10), 100, 2);

    const strCasOutlay = () => sum([purchasePrice, estimatedRepairs, closingCosts]);
    const strNetExpenses = () => sum([propertyTaxes, insurance, annaulHOA(), strannaulManagement()]);
    const strSummaryofReturns3 = () => {
        return sum([
            strAnnaulRevenue,
            -sum([Number(strannaulCapitalExpense()), Number(strannaulMaintenanceExpense()), Number(strNetExpenses())]),
        ]);
    };
    const strSummaryReturns3Parcentage = () => multiply(devided(strSummaryofReturns3(), strCasOutlay(), 10), 100, 2);
    const srtloadAmout = () => {
        return multiply(sum([purchasePrice, estimatedRepairs]), devided(sum([loantoValue]), 100, 2));
    };
    const strestimatedPrincipalInterest = () => {
        var parcentageValue = Number(devided(mortgageInterest, 100, 10));

        return Number(pmt(parcentageValue / 12, loanTerminMonths, srtloadAmout()));
    };
    const strestimatedAnnaulNetExpense = () => sum([propertyTaxes, insurance, annaulHOA(), strannaulManagement()]);
    const strManagementcost = () => multiply(strAnnaulRevenue, devided(managementPercent, 100, 10));
    const strFurnishing = () => multiply(0.1, sum([purchasePrice, estimatedRepairs]));
    const strtotalCashNeeded = () => sum([downPayment(), strFurnishing(), closingCosts]);
    const strfinancedGrossIncome = () =>
        sum([strAnnaulRevenue, -strestimatedAnnaulNetExpense(), -multiply(strestimatedPrincipalInterest(), 12)]);
    const strfinancedPurchaseParcentage = () => {
        const total = sum([downPayment(), strFurnishing()]);

        return multiply(devided(strfinancedGrossIncome(), total, 10), 100, 2);
    };
    const strgetSummaryReturns2 = () => {
        const total = sum([downPayment()]);

        return multiply(devided(strfinancedGrossIncome(), total, 10), 100, 2);
    };
    const strmaintenanceVacancyIncome = () => {
        return sum([
            strAnnaulRevenue,
            -strannaulCapitalExpense(),
            -strannaulMaintenanceExpense(),
            -multiply(strestimatedPrincipalInterest(), 12),
            -strestimatedAnnaulNetExpense(),
        ]);
    };
    const strgetSummaryReturns4 = () => {
        const total = sum([downPayment(), strFurnishing()]);

        return multiply(devided(strmaintenanceVacancyIncome(), total, 10), 100, 2);
    };
    return {
        loantoValue,
        strMonthly: devided(strAnnaulRevenue, 12),
        strAnnauly: strAnnaulRevenue,
        loanTerminMonths,
        mortgageInterest,
        closingCosts,
        rentalIncome,
        firstMortgagePoints,
        afterRepairValue,
        firstMortgagePoints2,
        secondMortgageInterest,
        firstMortgageInterest,
        holdTime,
        miscMortgageInterest,
        miscMortgageLienAmount,
        secondMortgagePoints,
        miscellaneousFinancingCosts,
        miscMortgagePoints,
        totalSqft,
        estimatedRepairs,
        purchasePrice,
        miscellaneousBuyingCosts,
        utilities,
        propertyTaxes,
        insurance,
        HOACondoFees,
        totalMonthlyHoldingCosts,
        totalPurchaseAndRepairCost,
        titleInsurance,
        getfirstMortgagePoints,
        getfirstMortgagePoints2,
        getfirstMortgageInterest,
        getfirstMortgageInterestPayment,
        getMortageLienAmount,
        getSecondMortgagePoints,
        getSecondMortgageInterest,
        getSecondMortgageInterestPayment,
        getMicsAmount,
        getMicsPoint,
        getMicInterest,
        getMicInterestPayment,
        totalFinancingCost,
        gettitleInsurance,
        escrowAttorneyFees,
        totalBuyingTransactionCost,
        sellingRecordingFees,
        homeWarranty,
        stagingCosts,
        marketingCosts,
        miscellaneousSellingCosts,
        getrealtorFees,
        gettransferConveyanceFees,
        totalSellingTransactionCost,
        totolHoldingCost,
        estimatedNetPropit,
        totalCostReturn,
        getDownpaymentRequire,
        refairEstimtorPerSq,
        myCommittedCapital,
        myAnnualizedCash,
        totalAnnualized,
        rehabReturn,
        grossRents,
        capitalExpenses,
        annaulCapitalExpense,
        estimatedMaintenanceCosts,
        annaulMaintenanceExpense,
        rentalEstimatedVacancyRate,
        annaulVacancyExpense,
        annaulHOA,
        AnnaulgrossIncome,
        financedGrossIncome,
        financedPurchaseParcentage,
        purchaseParcentage,
        getSummaryReturns2,
        totolCashOutlay,
        estimatedAnnaulNetExpense,
        getSummaryReturns3,
        loadAmout,
        estimatedPrincipalInterest,
        downPayment,
        totalCashNeeded: () => sum([closingCosts, downPayment()]),
        maintenanceVacancyIncome,
        getSummaryReturns4: () => multiply(devided(estimatedAnnaulNetExpense2(), downPayment(), 2), 100, 2),
        strFurnishing: strFurnishing,
        strannaulCapitalExpense,

        strannaulMaintenanceExpense,
        strannaulVacancyExpense,
        strAnnaulgrossIncome,
        strpurchaseParcentage,
        strfinancedGrossIncome,
        strfinancedPurchaseParcentage,
        AnnaulgrossIncome2,
        annaulManagement,
        estimatedAnnaulNetExpense2,
        strannaulManagement,
        strCasOutlay: strCasOutlay,
        strNetExpenses: strNetExpenses,
        strSummaryofReturns3: strSummaryofReturns3,
        strSummaryReturns3Parcentage: strSummaryReturns3Parcentage,
        srtloadAmout: srtloadAmout,
        strestimatedPrincipalInterest: strestimatedPrincipalInterest,
        strestimatedAnnaulNetExpense: strestimatedAnnaulNetExpense,
        strManagementcost: strManagementcost,
        strtotalCashNeeded: strtotalCashNeeded,
        strgetSummaryReturns2: strgetSummaryReturns2,
        strmaintenanceVacancyIncome: strmaintenanceVacancyIncome,
        strgetSummaryReturns4: strgetSummaryReturns4,
    };
};

export default useAnalyzer;
