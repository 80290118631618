import BarChart from "components/barChart/barChart";
import LineChart from "components/lineChart/lineChart";
import React, { Component } from "react";
import ActiveListingChart from "./activeListingChart/activeListingChart";
import "./marketSummary.css";
import ProfessionalHostsChart from "./professionalHostsChart/professionalHostsChart";
import RentalActivityChart from "./rentalActivityChart/rentalActivityChart";
export class MarketSummary extends Component {
	constructor(props) {
		super(props);
		console.log("Start Log");
		console.log(props);
		console.log(props);
		console.log("End Log");
		this.state = {};
	}
	render() {
		var summary = null,
			aL = null,
			aDR = null,
			occupancy = null,
			revenue = null;
		aL = {
			ep: JSON.parse(this.props.data.active_listings_entire_place).data.active_listings
			// pr: JSON.parse(this.props.data.active_listings_private_room).data.active_listings
			// sr: JSON.parse(this.props.data.active_listings_shared_room).data.active_listings
		};
		summary = JSON.parse(this.props.data.summary).data;
		aDR = {
			ep: JSON.parse(this.props.data.adr_entire_place).data.adr,
			// pr: JSON.parse(this.props.data.adr_private_room).data.adr,
			pr: JSON.parse(this.props.data.adr_entire_place).data.adr,
			// sr: JSON.parse(this.props.data.adr_shared_room).data.adr
			sr: JSON.parse(this.props.data.adr_entire_place).data.adr
		};
		occupancy = {
			ep: JSON.parse(this.props.data.occupancy_entire_place).data.occupancy,
			// pr: JSON.parse(this.props.data.occupancy_private_room).data.occupancy,
			pr: JSON.parse(this.props.data.occupancy_entire_place).data.occupancy,
			// sr: JSON.parse(this.props.data.occupancy_shared_room).data.occupancy
			sr: JSON.parse(this.props.data.occupancy_entire_place).data.occupancy
		};
		revenue = {
			ep: JSON.parse(this.props.data.revenue_entire_place).data.revenue,
			// pr: JSON.parse(this.props.data.revenue_private_room).data.revenue,
			pr: JSON.parse(this.props.data.revenue_entire_place).data.revenue,
			// sr: JSON.parse(this.props.data.revenue_shared_room).data.revenue
			sr: JSON.parse(this.props.data.revenue_entire_place).data.revenue
		};

		var adr = 0,
			occ = 0,
			rev = 0,
			adrData = [],
			occData = [],
			revData = [],
			pHData = [0, 0],
			activeHost = 0,
			hData = [0, 0, 0],
			rAAData = [0, 0, 0, 0],
			rABData = [0, 0, 0, 0],
			activeHost2 = 0,
			rData = [0, 0, 0];
		var data = summary;
		if (data !== null) {
			adr = Math.round(data.calendar_months.room_type.entire_place.adr["50th_percentile"]);
			occ = Math.round(data.calendar_months.room_type.entire_place.occ["50th_percentile"] * 100);
			rev = Math.round(data.calendar_months.room_type.entire_place.revenue["50th_percentile"]);
			aDR.ep.calendar_months.map((d) => {
				adrData.push(d.percentiles["90"]);
			});
			occupancy.ep.calendar_months.map((d) => {
				occData.push(d.percentiles["90"]);
			});
			revenue.ep.calendar_months.map((d) => {
				revData.push(d.percentiles["90"]);
			});
			pHData = [data.host_info.host_properties.multi_host_properties, data.host_info.host_properties.single_host_properties];
			activeHost = data.host_info.hosts.total_hosts;
			hData = [data.host_info.hosts.superhosts, data.host_info.hosts.multi_unit_hosts, data.host_info.hosts.single_unit_hosts];
			rAAData = [data.rental_activity.available["1-3"], data.rental_activity.available["4-6"], data.rental_activity.available["7-9"], data.rental_activity.available["10-12"]];
			rABData = [data.rental_activity.booked["1-3"], data.rental_activity.booked["4-6"], data.rental_activity.booked["7-9"], data.rental_activity.booked["10-12"]];
			activeHost2 =
				(data.rental_counts.counts.entire_place ? data.rental_counts.counts.entire_place.all : 0) +
				(data.rental_counts.counts.private_room ? data.rental_counts.counts.private_room.all : 0) +
				(data.rental_counts.counts.shared_room ? data.rental_counts.counts.shared_room.all : 0);
			rData = [
				data.rental_counts.counts.entire_place ? data.rental_counts.counts.entire_place.all : 0,
				data.rental_counts.counts.private_room ? data.rental_counts.counts.private_room.all : 0,
				data.rental_counts.counts.shared_room ? data.rental_counts.counts.shared_room.all : 0
			];
		}
		return (
			<div className="market-summary-container">
				<div className="market-summary-top">
					<h1>Zipcode summary</h1>
					<div className="market-summary-top-boxes">
						<div className="market-summary-top-box">
							<h1>Average Daily Rate</h1>
							<div className="market-summary-top-box-content">
								<h1>${adr}</h1>
								<LineChart height={90} title={"Average Daily Rate"} colors={["#50bfd8"]} data={adrData} />
							</div>
							<p onClick={() => this.props.handleTab(1, 1)}>
								View Pricing Details <span>{">"}</span>
							</p>
						</div>
						<div className="market-summary-top-box">
							<h1>Occupancy Rate</h1>
							<div className="market-summary-top-box-content">
								<h1>{occ}%</h1>
								<LineChart height={90} width={180} title={"Occupancy Rate"} colors={["#71d055"]} data={occData} />
							</div>
							<p onClick={() => this.props.handleTab(1, 2)}>
								View Occupancy Details <span>{">"}</span>
							</p>
						</div>
						<div className="market-summary-top-box">
							<h1>Revenue</h1>
							<div className="market-summary-top-box-content">
								<h1>${rev}</h1>
								<LineChart height={90} width={180} title={"Revenue"} colors={["#f2306c"]} data={revData} />
							</div>
							<p onClick={() => this.props.handleTab(1, 3)}>
								View Revenue Details <span>{">"}</span>
							</p>
						</div>
					</div>
					<div className="active-listing-container">
						<h1>Active Listing</h1>
						<ActiveListingChart data={aL} height={300} />
					</div>
					<div className="market-summary-chart-group">
						<div className="box">
							<h1>Professionals Hosts</h1>
							<div className="box-content">
								<ProfessionalHostsChart data={pHData} height={380} />
							</div>
						</div>
						<div className="box">
							<div className="header">
								<h1>{activeHost} Active Hosts</h1>
							</div>
							<h1>Hosts</h1>
							<div className="box-content">
								<BarChart
									data={hData}
									title={"Hosts"}
									height={170}
									width={400}
									colors={["#3FEAB0", "#50BFD8", "#D16BD9"]}
									labels={["Super Hosts", "Multi Listing Hosts", "Single Listing Hosts"]}
								/>
							</div>
						</div>
						<div className="box">
							<h1>Rental Activity</h1>
							<div className="box-content">
								<RentalActivityChart title={"Available"} labels={["1-3", "4-6", "7-9", "10-12"]} data={rAAData} height={180} colors={["#91A5FF", "#124695", "#345FA2", "#4E77B6"]} />
								<RentalActivityChart title={"Booked"} labels={["1-3", "4-6", "7-9", "10-12"]} data={rABData} height={180} colors={["#F27943", "#AB2D06", "#CB4509", "#EB520E"]} />
							</div>
						</div>
						<div className="box">
							<div className="header">
								<h1>{activeHost2} Active Rooms</h1>
							</div>
							<h1>Rental Type</h1>
							<div className="box-content">
								<BarChart data={rData} title={"Rooms"} height={170} width={400} colors={["#3FEAB0", "#50BFD8", "#D16BD9"]} labels={["Entire Home", "Private Room", "Shared Room"]} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default MarketSummary;
