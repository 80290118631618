import axios from 'axios';
import React from 'react';
import SinginAndSingupWraper from '../../hoc/signupAndSIgnPage';
import useError from '../../hooks/useError';
interface State {
    email: string;
}
const ForgetPassword = () => {
    const [state, setState] = React.useState<State>({ email: '' });
    const { error, setError } = useError();
    const [success, setSuccess] = React.useState(false);
    const onChange = (event: any) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const showAndRemoveError = (time: number, errorMessage: string): void => {
        setError({
            isError: true,
            errorMessage: errorMessage,
        });
        setTimeout(() => {
            setError({
                isError: false,
                errorMessage: '',
            });
        }, time);
    };

    const onSubmit = async (event: any) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/general/user/forget-password/request`, {
                ...state,
            });

            if (response.status === 200) {
                setSuccess(true);
                setState({ email: '' });
            }
        } catch (error) {
            showAndRemoveError(4000, error.response.data.message);
        }
    };
    return (
        <div className='h-full flex items-center justify-center'>
            <div className='tntro-leftside pt-40 md:pt-92 pb-40 md:pb-92 pl-15 md:pl-59 pr-15 md:pr-59' style={{ marginTop: '1rem' }}>
                <div className='signup--or mb-15 md:mb-20'>
                    <h3 className='fs-20'>Enter your Email for recovery account</h3>
                </div>
                <form className='w-full ' onSubmit={onSubmit}>
                    <div className='signin-input flex w-full h-50 pl-26 overflow-hidden mb-15'>
                        <div className='input--img'>
                            <img
                                className='w-20 h-20 mr-15'
                                src='https://cdn.zeplin.io/5ef9b0be7b4748819ae0decf/assets/c8bda553-9c90-4075-883c-c29966d45a29.svg'
                                alt=''
                            />
                        </div>
                        <input
                            name='email'
                            onChange={onChange}
                            value={state.email}
                            className='w-full h-full outline-none'
                            type='text'
                            placeholder='Write your email'
                            required={true}
                        />
                    </div>

                    <div onClick={onSubmit} className='signin--cehckbox mt-14'>
                        <button className='flex justify-center items-center'>Submit</button>
                    </div>

                    {error.isError && (
                        <div className='fs-16 mb-14 mt-10' style={{ color: '#c71f66' }}>
                            {error.errorMessage}
                        </div>
                    )}
                    {success && (
                        <div className='fs-18 mb-14 mt-14' style={{ color: '#3ab354' }}>
                            We sent a email in your email box
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default SinginAndSingupWraper(ForgetPassword);
