import axios from "axios";
import Cookies from "js-cookie";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "reducers/user";
import { subscriptionListState } from "../../reducers/subscriptionList";
import { setModal, setSuccess } from "../../reducers/subscriptionModal";

//Analytics
import ReactGA from "react-ga";

function capitalizeFirstLetter(string: string): string {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

const CardInfor: React.FC<{
	card_brand: string;
	card_last_four: string;
	setSelected: any;
	setError: React.Dispatch<React.SetStateAction<boolean>>;
	errorOnPyment: boolean;
	measseWhenChangePackage: Function;
}> = ({ card_brand, card_last_four, setError, setSelected, errorOnPyment, measseWhenChangePackage }) => {
	const token = Cookies.get("token");
	const dispatch = useDispatch();

	const [loading, setLoading] = React.useState<boolean>(false);
	const { details } = useSelector(subscriptionListState);

	const errorHandle = () => {
		setError(true);
		setTimeout(() => {
			setError(false);
		}, 3000);
	};

	const makePayment = async () => {
		ReactGA.event({
			category: "Profile",
			action: "Click_Make_Payment_button"
		});
		try {
			if (loading === false) {
				setLoading(true);

				const response = await axios.post(
					`${process.env.REACT_APP_BASE_URL}/api/v1/general/stripe/get-payment/from-existing-card`,
					{ package_id: details.id },
					{ headers: { Authorization: `Bearer ${token}` } }
				);

				if (response.status == 200) {
					const getUserData = async () => {
						try {
							if (!!token) {
								const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/general/get-user-info`, {
									headers: { Authorization: `Bearer ${token}` }
								});

								dispatch(setUser(response.data.data));
							}
						} catch (e) {
							Cookies.remove("token");
						}
					};

					getUserData();

					dispatch(setModal(false));
					var d = {
						type: true,
						package: details.package_name
					};
					dispatch(setSuccess(d));
					setLoading(false);
					var show = false;
					if (show) {
						measseWhenChangePackage(details.package_name);
					}
					setTimeout(() => {
						var d2 = {
							type: false
						};
						dispatch(setSuccess(d2));
						setSelected(false);
					}, 3000);
				} else {
					errorHandle();
					setLoading(false);
				}

				// pass the token to your backend API
			}
		} catch (error) {
			errorHandle();
			setLoading(false);
		}
	};
	return (
		<div className="fs-16">
			<div className="flex justify-between mb-30">
				<h4>Card Name</h4>
				<h4>{capitalizeFirstLetter(card_brand)}</h4>
			</div>
			<div className="flex justify-between">
				<h4>Card Number</h4>
				<h4>{"xxxx xxxx xxxx" + " " + card_last_four}</h4>
			</div>
			<button onClick={makePayment} className="submit__button mt-40  flex items-center justify-center" style={{ height: "40px", width: "100%" }}>
				{loading && <span className="button-spiner "></span>}

				{!loading ? "Make Payment" : "Process Payment"}
			</button>
			<div className="mt-15 fs-16 text-center" style={{ color: "red", maxHeight: errorOnPyment ? "100px" : "0px", overflow: "hidden", transition: "1s all" }}>
				<div>Oh no!! Payment Failed. Please try again</div>
			</div>
		</div>
	);
};

export default CardInfor;
