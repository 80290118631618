import React, { useEffect, useRef } from 'react';
import GoogleLogin from 'react-google-login';
import GoogleIcon from '../../assets/img/google-button.svg';
function GoogleButton(props) {
    const ref = useRef();
    useEffect(() => {
        ref.current.parentNode.removeAttribute('style');
    }, []);

    return (
        <React.Fragment>
            <GoogleLogin
                className='google-button'
                clientId= {`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
                onSuccess={props.responseGoogle}
                fetchBasicProfile={true}
                onFailure={props.responseGoogle}
            >
                <span ref={ref}>
                    <img className='w-21 h-21' src={GoogleIcon} alt='google-icon' />
                </span>
                <span> With Google</span>
            </GoogleLogin>
        </React.Fragment>
    );
}

export default GoogleButton;
