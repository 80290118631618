import axios from 'axios';
import PasswordInput from 'components/Input/passwordInput';
import SinginAndSingupWraper from 'hoc/signupAndSIgnPage';
import useError from 'hooks/useError';
import React from 'react';
import { useHistory } from 'react-router-dom';
import forgetPassValid from 'utils/forgetPasswordValid';
interface State {
    password: string;
    password_confirmation: string;
}
const RecoveryPassword = () => {
    const history = useHistory();
    const [state, setState] = React.useState<State>({ password_confirmation: '', password: '' });
    const [allError, setErrors] = React.useState({
        password_confirmation: '',
        password: '',
    });
    const { error, setError } = useError();
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const otp = urlParams.get('otp');

    const onChange = (event: any) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const showAndRemoveError = (time: number, errorMessage: string): void => {
        setError({
            isError: true,
            errorMessage: errorMessage,
        });
        setTimeout(() => {
            setError({
                isError: false,
                errorMessage: '',
            });
        }, time);
    };

    const onSubmit = async (event: any) => {
        event.preventDefault();
        const { isValid, errors } = forgetPassValid(state);

        if (isValid) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/general/user/forget-password/update`, {
                    otp,
                    email,
                    ...state,
                });

                if (response.status === 200) {
                    history.push('/signin');
                }
            } catch (error) {
                showAndRemoveError(4000, error.response.data.message);
            }
        } else {
            setErrors(errors);
        }
    };
    return (
        <div className='h-full flex items-center justify-center'>
            <div className='tntro-leftside pt-40 md:pt-92 pb-40 md:pb-92 pl-15 md:pl-59 pr-15 md:pr-59' style={{ marginTop: '1rem' }}>
                <div className='signup--or mb-15 md:mb-20'>
                    <h3 className='fs-20'>Enter your new password</h3>
                </div>
                <form className='w-full ' onSubmit={onSubmit}>
                    <PasswordInput name='password' onChange={onChange} value={state.password} placeholder='New password' />

                    {!!allError.password ? <small className='block fs-14 mb-10 mt-10 red '>{allError.password}</small> : null}
                    <div className='mt-20'>
                        <PasswordInput
                            name='password_confirmation'
                            onChange={onChange}
                            value={state.password_confirmation}
                            placeholder='Confirm new password'
                        />
                    </div>

                    {!!allError.password_confirmation ? (
                        <small className='block fs-14 mt-10  mb-10 red '>{allError.password_confirmation}</small>
                    ) : null}

                    <div onClick={onSubmit} className='signin--cehckbox mt-14'>
                        <button className='flex justify-center items-center'>Submit</button>
                    </div>

                    {error.isError && (
                        <div className='fs-14 mb-14 mt-14' style={{ color: '#c71f66' }}>
                            {error.errorMessage}
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default SinginAndSingupWraper(RecoveryPassword);
