import React from "react";
import "./marketDataDetails.css";
import SVGicon from "../../../assets/img/blue-arrow.svg";
import MarketSummary from "./marketSummary/marketSummary";
import MarketAverageDailyRate from "./marketAverageDailyRate/marketAverageDailyRate";
import MarketOccupancy from "./marketOccupancy/marketOccupancy";
import MarketRevenue from "./marketRevenue/marketRevenue";
import getPropertyDataFromOurServer from "api/getPropertyData";
import { setAddress, setAirDna, setEstated, setLatLng, setRealtyMole, singlePropertyState } from "reducers/singleProperty";
import RentalComparable from "../../comparableProperties/rentalComparable";
import StrComparable from "../../comparableProperties/strComparable";
import axios from "axios";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "@material-ui/core";
import { Button } from "styles/Buttons";
import TableCompoent from "components/table/index.js";
import { userState } from "reducers/user";
import { useParams } from "react-router-dom";
import { setModal } from "reducers/subscriptionModal";
import Delete from "components/actionButton/Delete.js";
import range from "utils/arrayRange";
import LoadingSkeleton from "components/loadingSkeleton";

//Analytics
import ReactGA from "react-ga";
import MarketZipCodeRental from "./marketZipCodeRental/marketZipCodeRental";
import MarketSaleListing from "./marketSaleListing/marketSaleListing";

const TableHead = ["Name", "Property", "Total Amount", "Created", "Action"];
const TableHead2 = ["", "Title", "Bed", "Bath", "Guest", "Occ", "Night", "ADR", "Price/mo", "Revenue", "Cleaning", "Distance", "Rating", "Reviews"];

const MarketDataDetails = (props) => {
	const dispatch = useDispatch();
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [activeTab, setActiveTab] = React.useState({ currentParent: 1, currentChild: 0 });
	const [data, setData] = React.useState(null);
	const [strData, setSTRData] = React.useState(null);
	const { proid } = props;
	React.useEffect(() => {
		const token = Cookies.get("token");
		setLoading(true);
		const getStrcom = async () => {
			try {
				const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/general/address/get-str-comparable/${proid}`, {
					headers: { Authorization: `Bearer ${token}` }
				});
				if (response) {
					console.log("50", response.data.data);
					getDatas(response.data.data);
				} else {
					setData(null);
					setSTRData(null);
					setLoading(false);
				}
			} catch (error) {
				console.log(error);
				setData(null);
				setSTRData(null);
				setLoading(false);
			}
		};
		getStrcom();
	}, []);

	const getDatas = async (resp) => {
		setLoading(true);
		try {
			const response = await getPropertyDataFromOurServer("", proid);
			if (response.status === 200) {
				console.log(response.data);
				dispatch(setAddress(response.data.data.address));
				dispatch(
					setLatLng({
						favorite: response.data.data.favorite,
						id: response.data.data.id,
						lat: response.data.data.lat,
						lng: response.data.data.lng
					})
				);
				dispatch(setEstated(response.data.data.estated));
				dispatch(setAirDna(response.data.data.airdna));
				dispatch(setRealtyMole(response.data.data.realty_mole));
				ReactGA.event({
					category: "Property Details Page",
					action: "Property Market Data View"
				});
				handleTab(1, null);
				setData(resp.marketData);
				setSTRData(resp.StrComp.comparables.properties);
				setLoading(false);
			} else {
				console.log(response);
				setLoading(false);
				setData([]);
				setSTRData([]);
			}
		} catch (error) {
			console.log(error);
			setLoading(false);
			setData(null);
			setSTRData(null);
		}
	};

	const buyDatas = async () => {
		setLoading(true);
		const token = Cookies.get("token");
		try {
			const response1 = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/general/address/buy-str-comparable/${proid}`, {
				headers: { Authorization: `Bearer ${token}` }
			});
			if (response1.data !== null) {
				getDatas(response1.data.data);
				setOpen(false);
			}
		} catch (error) {
			console.log(error);
			setLoading(false);
			setData(null);
			setSTRData(null);
		}
	};

	const getComplist = async () => {
		try {
			buyDatas();
		} catch (error) {
			dispatch(setModal(true));
		}
	};

	const handleTab = (p, c) => {
		var a;
		if (activeTab.currentParent === p) {
			a = {
				currentParent: p,
				currentChild: c
			};
		} else {
			a = {
				currentParent: p,
				currentChild: c
			};
		}
		setActiveTab(a);
	};

	const handleBack = () => {
		var a = {
			currentParent: activeTab.currentParent,
			currentChild: null
		};
		setActiveTab(a);
	};

	if (loading) {
		return <div class="market-details-loader"></div>;
	}

	return (
		<div className="relative">
			<Dialog
				maxWidth={"md"}
				// fullWidth={true}
				className="modal"
				onClose={() => setOpen(false)}
				open={open}
				disablePortal={true}
				disableEnforceFocus={false}
				disableAutoFocus={false}
				aria-labelledby="server-modal-title"
				aria-describedby="server-modal-description">
				<div className="p-40 ">
					<h3 className="fs-24 mb-20 text-center ">
						Hey there, just to confirm...
						<br />
						This will add a $10 charge to your monthly invoice.
					</h3>
					<div className="flex justify-center ">
						<Button onClick={getComplist} className="submit__button " style={{ width: "90px", height: "40px", color: "white", marginRight: "20px", border: "none" }}>
							Yes
						</Button>
						<Button onClick={() => setOpen(false)} className="outline__button " style={{ width: "80px", height: "40px", color: "" }}>
							No
						</Button>
					</div>
				</div>
			</Dialog>
			{strData === null ? (
				<DefaultData setOpen={setOpen} />
			) : (
				<div className="market-details-main-container">
					<div className="market-details-sidebar">
						<div className="market-details-sidebar-item">
							<div onClick={() => handleTab(1, null)} className={activeTab.currentParent === 1 ? "market-details-sidebar-item-header active" : "market-details-sidebar-item-header"}>
								<h1 className={activeTab.currentParent === 1 && activeTab.currentChild === null ? "active" : ""}>STR Summary</h1>
								<img src={SVGicon} alt="extend" />
							</div>
							{activeTab.currentParent === 1 ? (
								<>
									<div
										onClick={() => handleTab(1, 1)}
										className={activeTab.currentParent === 1 && activeTab.currentChild === 1 ? "market-details-sidebar-item-child active" : "market-details-sidebar-item-child"}>
										<h1>Average Daily Rate</h1>
									</div>
									<div
										onClick={() => handleTab(1, 2)}
										className={activeTab.currentParent === 1 && activeTab.currentChild === 2 ? "market-details-sidebar-item-child active" : "market-details-sidebar-item-child"}>
										<h1>Occupancy</h1>
									</div>
									<div
										onClick={() => handleTab(1, 3)}
										className={activeTab.currentParent === 1 && activeTab.currentChild === 3 ? "market-details-sidebar-item-child active" : "market-details-sidebar-item-child"}>
										<h1>Revenue</h1>
									</div>
									<div
										onClick={() => handleTab(1, 4)}
										className={activeTab.currentParent === 1 && activeTab.currentChild === 4 ? "market-details-sidebar-item-child active" : "market-details-sidebar-item-child"}>
										<h1>STR Comparable</h1>
									</div>
								</>
							) : null}
						</div>
						<div className="market-details-sidebar-item">
							<div onClick={() => handleTab(2, 1)} className={activeTab.currentParent === 2 ? "market-details-sidebar-item-header active" : "market-details-sidebar-item-header"}>
								<h1 className={activeTab.currentParent === 2 && activeTab.currentChild === null ? "active" : ""}>Sales Summary</h1>
								<img src={SVGicon} alt="extend" />
							</div>
							{activeTab.currentParent === 2 ? (
								<>
									<div
										onClick={() => handleTab(2, 1)}
										className={activeTab.currentParent === 2 && activeTab.currentChild === 1 ? "market-details-sidebar-item-child active" : "market-details-sidebar-item-child"}>
										<h1>Current Sale Listings</h1>
									</div>
								</>
							) : null}
						</div>
						<div className="market-details-sidebar-item">
							<div onClick={() => handleTab(3, 1)} className={activeTab.currentParent === 3 ? "market-details-sidebar-item-header active" : "market-details-sidebar-item-header"}>
								<h1 className={activeTab.currentParent === 3 && activeTab.currentChild === null ? "active" : ""}>Rental Summary</h1>
								<img src={SVGicon} alt="extend" />
							</div>
							{activeTab.currentParent === 3 ? (
								<>
									<div
										onClick={() => handleTab(3, 1)}
										className={activeTab.currentParent === 3 && activeTab.currentChild === 1 ? "market-details-sidebar-item-child active" : "market-details-sidebar-item-child"}>
										<h1>Rental Comparable</h1>
									</div>
									<div
										onClick={() => handleTab(3, 2)}
										className={activeTab.currentParent === 3 && activeTab.currentChild === 2 ? "market-details-sidebar-item-child active" : "market-details-sidebar-item-child"}>
										<h1>Zip Code Rental Data</h1>
									</div>
								</>
							) : null}
						</div>
					</div>
					<div className="market-details-body">
						{activeTab.currentParent === 1 && activeTab.currentChild === null ? <MarketSummary data={data} handleTab={handleTab} /> : null}
						{activeTab.currentParent === 1 && activeTab.currentChild === 1 ? <MarketAverageDailyRate data={data} handleBack={handleBack} /> : null}
						{activeTab.currentParent === 1 && activeTab.currentChild === 2 ? <MarketOccupancy data={data} handleBack={handleBack} /> : null}
						{activeTab.currentParent === 1 && activeTab.currentChild === 3 ? <MarketRevenue data={data} /> : null}
						{activeTab.currentParent === 1 && activeTab.currentChild === 4 ? <StrComparable loading={loading} list={strData} setList={setSTRData} handleBack={handleBack} /> : null}
						{activeTab.currentParent === 2 && activeTab.currentChild === 1 ? <MarketSaleListing data={data} /> : null}
						{activeTab.currentParent === 3 && activeTab.currentChild === 1 ? <RentalComparable /> : null}
						{activeTab.currentParent === 3 && activeTab.currentChild === 2 ? <MarketZipCodeRental data={data} /> : null}
					</div>
				</div>
			)}
		</div>
	);
};

const DefaultData = ({ setOpen }) => {
	const { subs_type } = useSelector(userState);

	const dispatch = useDispatch();
	const yesOnclick = () => {
		if (subs_type === "trial") {
			dispatch(setModal(true));
		} else {
			setOpen(true);
		}
	};

	return (
		<>
			<div className="blur">
				<div className="table-container">
					<TableCompoent Th={TableHead} className="relative ">
						{range(1, 7).map(() => {
							return (
								<tr>
									<td>Ha ha , you think, are you genius?</td>
									<td>30</td>
									<td>20</td>
									<td>20</td>
									<td>
										<Delete />
									</td>
								</tr>
							);
						})}
					</TableCompoent>
				</div>
				<div className="secondary-table-container">
					{range(1, 7).map(() => {
						return (
							<div className="secondary-table-content">
								<div className="top">
									<div className="left">
										<p>Ha ha , you think, are you genius?</p>
									</div>
									<div className="right">
										<p>
											Beds: <span>30</span>
										</p>
										<p>
											Bathrooms: <span>20</span>
										</p>
									</div>
								</div>
								<div className="bottom">
									<div className="left">
										<p>
											Square foot: <span>20</span>
										</p>
									</div>
									<div className="right">
										<p>
											Price: <span>20</span>
										</p>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			<div className="pop-up-info">
				<div className="left">
					<h1>Get a better picture of the property and make decisions with more confidence.</h1>
					<div className="content">
						<h1>This report will provide you with:</h1>
						<p>
							<span>&bull;</span>Comparables (rentals and short term rentals)
						</p>
						<p>
							<span>&bull;</span>How many STR listings are in this zip code
						</p>
						<p>
							<span>&bull;</span>How many STR hosts are in this zip code
						</p>
						<p>
							<span>&bull;</span>Zip Code summary of:
						</p>
						<div className="sub-content">
							<p>
								<span>&#9675;</span>Average Daily rate
							</p>
							<p>
								<span>&#9675;</span>Occupancy
							</p>
						</div>
						<button onClick={yesOnclick}>Continue for $10</button>
					</div>
				</div>
				<div className="right"></div>
			</div>
		</>
	);
};

export default MarketDataDetails;
