import getPropertyDataFromOurServer from "api/getPropertyData";
import DetailsTab from "components/details/DetailsTab";
import DetailsTab2 from "components/details/DetailsTab2";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setAddress, setAirDna, setEstated, setLatLng, setRealtyMole, setRealtyMoleProperty, singlePropertyState } from "reducers/singleProperty";
import "./details.scss";
import DetailsData from "./DetailsWraper.Data";
import DetailsWraperDataCard from "./DetailsWraper.DataCard";
import DetailsWraperImg from "./DetailsWraper.Img";
// import DetailsWraperDescription from './DetailsWraper.Description';
import DetailsWraperInformation from "./DetailsWraper.Information";
import DetailsWraperMap from "./DetailsWraper.Map";

//Analytics
import ReactGA from "react-ga";

function DetailsWraper() {
	const token = Cookies.get("token");
	const { search, proid } = useParams();
	const dispatch = useDispatch();
	const {
		favorite,
		id,
		addresss,
		lat,
		lng,
		estated: { data },
		realtyMole,
		airDna,
		realty_mole_property,
		propertyLoading
	} = useSelector(singlePropertyState);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			const response = await getPropertyDataFromOurServer("", proid);

			if (response.status === 200) {
				ReactGA.event({
					category: "Property Details Page",
					action: "Property Details View"
				});
				console.log("46", response);

				await dispatch(setAddress(response.data.data.address));
				await dispatch(
					setLatLng({
						favorite: response.data.data.favorite,
						id: response.data.data.id,
						lat: response.data.data.lat,
						lng: response.data.data.lng
					})
				);
				await dispatch(setEstated(response.data.data.estated));
				await dispatch(setAirDna(response.data.data.airdna));
				await dispatch(setRealtyMole(response.data.data.realty_mole));
				await dispatch(setRealtyMoleProperty(response.data.data.realty_mole_property ? response.data.data.realty_mole_property : null));
				console.log("72", data);
				setLoading(false);
			}
		};
		getData();
	}, [proid]);

	return (
		<>
			<DetailsTab tabName="details" />
			<DetailsTab2 tabName="details" />
			<div>
				<div className="flex -m-15 mt-45">
					<DetailsWraperImg center={airDna.property_details.location} />
					<DetailsWraperMap
						loading={loading}
						favorite={favorite}
						id={id}
						center={{
							lat,
							lng
						}}
					/>
				</div>
			</div>

			<DetailsData parcel={data.parcel} structure={data.structure} bathAndBed={airDna.property_details} loading={loading} realty_mole_property={realty_mole_property} />
			<DetailsWraperDataCard estated={data} airDna={airDna} realtyMole={realtyMole} loading={loading} />

			<DetailsWraperInformation
				structure={data.structure}
				owner={data.owner}
				parcel={data.parcel}
				taxes={data.taxes}
				assessments={data.assessments}
				deeds={data.deeds}
				market_assessments={data.market_assessments}
			/>
		</>
	);
}
export default DetailsWraper;
