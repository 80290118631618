import React, { Component } from "react";
import Chart from "react-apexcharts";

export default class RentalActivityChart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			series: props.data,

			options: {
				title: {
					text: props.title,
					align: "left",
					margin: 10,
					offsetX: 0,
					offsetY: 0,
					floating: false,
					style: {
						fontSize: "14px",
						fontWeight: "normal"
					}
				},

				colors: props.colors,
				labels: props.labels,
				dataLabels: {
					enabled: true,
					formatter: function (val, opts) {
						return parseInt(val, 10) + "%";
					}
				},
				legend: {
					show: false
				},
				plotOptions: {
					pie: {
						donut: {
							size: "60%"
						}
					}
				}
			}
		};
	}

	render() {
		return <Chart options={this.state.options} series={this.state.series} width={this.props.height} height={this.props.height} type="donut" />;
	}
}
