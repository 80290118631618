import React from "react";
import { useHistory } from "react-router-dom";

const DashboadrdTabBar: React.FC<{ tabName: "favourite" | "search" }> = ({ tabName }) => {
	const history = useHistory();

	return (
		<div className="tab-bar">
			<div className={tabName === "search" ? "tab active" : "tab"} onClick={() => history.push("/dashboard?tab=search")}>
				Recent Searches
			</div>
			<div className={tabName === "favourite" ? "tab active" : "tab"} onClick={() => history.push("/dashboard?tab=favourite")}>
				Favorites
			</div>
		</div>
	);
};

export default DashboadrdTabBar;
