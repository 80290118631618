import Empty from "components/empty";
import LoadingSkeleton from "components/loadingSkeleton";
import PropertyTR from "components/propertyTR";
import TableCompoent from "components/table/index.js";
import usePropertydata from "hooks/usePropertydata";
import React from "react";
import { NavLink } from "react-router-dom";

import numberWithCommas from "../../utils/numberWithCommas";

function FavTable() {
	const { searchHead, loading, unFav, propertyData } = usePropertydata(`/api/v1/general/address/favorites`);

	if (loading === true) {
		return (
			<>
				<div className="main-table-loader">
					<LoadingSkeleton Head={searchHead} />
				</div>
				<div className="responsive-table-loader">
					<LoadingSkeleton Head={["", ""]} />
				</div>
			</>
		);
	}

	return !!propertyData.data.length ? (
		<div>
			<div className="table-container">
				<TableCompoent Th={searchHead}>
					{propertyData.data.map((value: any) => {
						return <PropertyTR value={value} changeFavourite={unFav} />;
					})}
				</TableCompoent>
			</div>
			<div className="secondary-table-container">
				{propertyData.data.map((value: any) => {
					return (
						<div className="secondary-table-content">
							<NavLink to={`/dashboard/details/${value.mainAddress}/${value.id}/view/`}>
								<div className="left">
									<p>{value.address}</p>
									{value.str_cpa_used ? <h5 className="str-tag">STR</h5> : null}
								</div>
								<div className="right">
									<p>
										Est Value: <span>${numberWithCommas(value.est)}</span>
									</p>
									<p>
										Rent: <span>${numberWithCommas(value.rent)}</span>
									</p>
									<p>
										STR Annual: <span>${numberWithCommas(value.str_year)}</span>
									</p>
								</div>
							</NavLink>
						</div>
					);
				})}
			</div>
			{propertyData.meta.last_page !== 1 ? (
				<div className="py-25 flex items-center justify-center">
					<NavLink to={`/favourites?&page=2`}>
						<button className="view-more-button">View more</button>
					</NavLink>
				</div>
			) : null}
		</div>
	) : (
		<Empty />
	);
}

export default FavTable;
