import ExtendTableCard from 'components/extendableCard';
import UpDown from 'components/upDown';
import React from 'react';
import commas from '../../utils/numberWithCommas';
import useAnalyzer from '../../hooks/useAnalyzer';

const NetProfit = () => {
    const { estimatedNetPropit } = useAnalyzer();
    return (
        <div>
            <ExtendTableCard isOpen={true} className='dealinformation__1' title='ESTIMATED NET PROPIT'>
                <div className='p-40 flex items-center justify-between'>
                    <UpDown isUp={estimatedNetPropit() < 1 ? false : true} />
                    <div className=' brand-text font-bold' style={{ fontSize: '30px' }}>
                        ${commas(estimatedNetPropit())}
                    </div>
                </div>
            </ExtendTableCard>
        </div>
    );
};

export default NetProfit;
