import React, { Component } from "react";
import "./marketRevenue.css";
import Chart from "react-apexcharts";
import back from "../../../../assets/img/left-arrow.png";
import numberWithCommas from "utils/numberWithCommas";

export default class MarketRevenue extends Component {
	constructor(props) {
		super(props);
		var labels = [];
		var da = {
			ep: JSON.parse(this.props.data.revenue_entire_place).data.revenue
			// pr: JSON.parse(this.props.data.revenue_private_room).data.revenue
			// sr: JSON.parse(this.props.data.revenue_shared_room).data.revenue
		};
		var data1 = [],
			data2 = [],
			data3 = [];
		da.ep.calendar_months.map((d) => {
			var date = d.month + "/01/" + d.year;
			labels.push(date);
			data1.push(d.percentiles["90"]);
		});
		// da.pr.calendar_months.map((d) => {
		// 	var date = d.month + "/01/" + d.year;
		// 	labels.push(date);
		// 	data2.push(d.percentiles["90"]);
		// });
		// da.sr.calendar_months.map((d) => {
		// 	var date = d.month + "/01/" + d.year;
		// 	labels.push(date);
		// 	data3.push(d.percentiles["90"]);
		// });
		this.state = {
			series: [
				{
					name: "Entire Place",
					data: data1
				}
				// {
				// 	name: "Private Room",
				// 	data: data2
				// },
				// {
				// 	name: "Shared Room",
				// 	data: data3
				// }
			],
			options: {
				chart: {
					type: "line",
					zoom: {
						enabled: false
					},
					toolbar: {
						show: false
					}
				},
				stroke: {
					show: true,
					curve: "smooth",
					colors: ["#25A0FC", "#3FEAB0", "#FED88D"],
					width: 3
				},
				dataLabels: {
					show: false
				},
				legend: {
					show: false
				},
				labels: labels,
				xaxis: {
					type: "datetime"
				},
				yaxis: {
					labels: {
						formatter: function (value) {
							if (value) {
								return "$" + numberWithCommas(Math.round(value));
							} else {
								return "$0";
							}
						}
					}
				},
				markers: {
					colors: ["#25A0FC", "#3FEAB0", "#FED88D"]
				}
			}
		};
	}
	render() {
		return (
			<div className="market-revenue-main-container">
				<div className="header">
					<img onClick={this.props.handleBack} src={back} alt="back-key" />
					<h1>STR Summary</h1>
				</div>
				<div className="market-revenue-container">
					<h1>Revenue</h1>
					<Chart options={this.state.options} series={this.state.series} height={360} type="line" />
				</div>
			</div>
		);
	}
}
