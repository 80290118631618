import BreadCumb from "components/BreadCumb";
import Navbar from "components/navbar";
import ProfileTab from "components/ProfileTab";
import ProfileTab2 from "components/ProfileTab2";
import UserProfile from "components/userProfile";
import React from "react";
import { useSelector } from "react-redux";
import { userState } from "reducers/user";
import { ContainerBox } from "styles/Box";
function capitalizeFirstLetter(string: string): string {
	return string.charAt(0).toUpperCase() + string.slice(1);
}
const PaymentMethod = () => {
	const user = useSelector(userState);
	return (
		<div className="dashboard--wraper pt-15 pl-15 pr-15 pb-15">
			<Navbar />
			<div>
				<BreadCumb breadCumb={[{ name: "Billing History", link: "/billing-hostory" }]} />
				<ContainerBox className="pl-21 pr-43 pb-20 page-main-container" style={{ minHeight: "600px" }}>
					<UserProfile user={user} />
					<ProfileTab tabName="paymentMethod" />
					<ProfileTab2 tabName="paymentMethod" />
					<div className="pay-card fs-20">
						<div className="flex justify-between mb-20">
							<h4>Card Name</h4>
							<h4>{capitalizeFirstLetter(user.card_info.card_brand)}</h4>
						</div>
						<div className="flex justify-between ">
							<h4>Card Number</h4>
							<h4>{"xxxx xxxx xxxx" + " " + user.card_info.card_last_four}</h4>
						</div>
					</div>
				</ContainerBox>
			</div>
		</div>
	);
};

export default PaymentMethod;
