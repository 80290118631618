import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import getPropertyDataFromOurServer from "api/getPropertyData";
import getPropertyFromExternalAPI from "api/getPropertyFromAPI";
import storeData from "api/storeDataFromPoperties";
import Cookies from "js-cookie";
import React, { useState } from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setTotalProperty, setTotalSearch } from "reducers/matix";
import { setModal } from "reducers/subscriptionModal";
import { userState } from "reducers/user";
import removeLast from "utils/removeLast";
import { setAddress, setAirDna, setEstated, setLatLng, setRealtyMole, singlePropertyState } from "../../reducers/singleProperty";

//Analytics

import ReactGA from "react-ga";

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function isNumeric(value) {
	return /^-?\d+$/.test(value);
}

const useStyles = makeStyles((theme) => ({
	root: {
		fontSize: "1.4rem"
	}
}));

const LocationSearchInput = (props) => {
	const [address, setState] = useState("");
	const [searchLoading, setLoading] = useState(false);
	const [snack, setSnack] = useState(false);
	const [errorMessage, setMessage] = useState("");
	let history = useHistory();
	const classes = useStyles();
	const dispatch = useDispatch();
	const token = Cookies.get("token");
	const { realtyMole, airDna } = useSelector(singlePropertyState);
	const { subs_status } = useSelector(userState);
	const handleChange = (address) => {
		setState(address);
	};
	const localAddress = localStorage.getItem("address");
	React.useEffect(() => {
		if (!!localAddress) {
			setAddress(localAddress);
			handleSelect(localAddress);
			localStorage.removeItem("address");
		}
	}, [localAddress]);

	const storeInRedux = (response) => {
		dispatch(setAddress(response.data.data.address));
		dispatch(
			setLatLng({
				favorite: response.data.data.favorite,
				id: response.data.data.id,
				lat: response.data.data.lat,
				lng: response.data.data.lng
			})
		);
		dispatch(setEstated(response.data.data.estated));
		dispatch(setAirDna(response.data.data.airdna));
		dispatch(setRealtyMole(response.data.data.realty_mole));
	};

	const whenErrorHappen = (errorMessage) => {
		setLoading(false);
		setMessage(errorMessage);
		setSnack(true);
	};

	const getEstated = async (address = "", latLng) => {
		let addressArray = address.split(" ");
		if (addressArray.length < 3) {
			whenErrorHappen("Please use one of the suggested addresses");
		} else {
			if (!isNumeric(addressArray[0])) {
				whenErrorHappen("Your address is not a valid street address");
			} else {
				const response = await getPropertyDataFromOurServer(address);
				if (response.status === 200) {
					storeInRedux(response);
					dispatch(setTotalProperty(1));
					dispatch(setTotalSearch(1));

					history.push(`/dashboard/details/${address}/${response.data.data.id}/view`);
				} else if (response === false) {
					dispatch(setModal(true));
				} else {
					var newAddress = address[address.length - 1] === "," ? address.slice(0, address.length - 1) : address;
					const getRes = await getPropertyFromExternalAPI(newAddress);
					console.log(99, getRes);
					if (getRes !== false && getRes[2].data && getRes[2].data.data) {
						const storeRes = await storeData({
							address: newAddress,
							lat: latLng.lat,
							lng: latLng.lng,
							details: {
								realtyMole: {
									...getRes[0].data
								},
								airDna: {
									...getRes[1].data
								},
								estated: {
									...getRes[2].data
								},
								realtyMoleProperty: {
									...getRes[3].data
								}
							}
						});
						console.log(storeRes);
						if (storeRes) {
							// storeInRedux(storeRes);
							dispatch(setTotalProperty(1));
							dispatch(setTotalSearch(1));
							history.push(`/dashboard/details/${storeRes.data.address}/${storeRes.data.id}/view`);
						} else {
							whenErrorHappen("Internal Server Error");
						}
					} else {
						whenErrorHappen("No property details found for this address");
					}
				}
			}
		}
	};
	const getText = (text) => {
		return text.substring(0, text.lastIndexOf(", "));
	};
	const handleSelect = (address, suggestion) => {
		ReactGA.event({
			category: "Search Button",
			action: "Dashboard"
		});
		if (subs_status !== "active") {
			dispatch(setModal(true));
		} else {
			setState(address);
			if (!!address) {
				setLoading(true);
				geocodeByAddress(address)
					.then((results) => {
						return getLatLng(results[0]);
					})
					.then((latLng) => {
						getEstated(removeLast(address), latLng);
					})
					.catch((err) => {
						whenErrorHappen("Not found any property details, Try another address");
					});
			} else {
				whenErrorHappen("Search input is required");
			}
		}
	};

	const onSubmit = (event) => {
		event.preventDefault();
		ReactGA.event({
			category: "Search Button",
			action: "Dashboard"
		});
		handleSelect(address);
	};

	return (
		<>
			<Snackbar className={classes.root} open={snack} autoHideDuration={3000} onClose={() => setSnack(false)}>
				<Alert className={classes.root} onClose={() => setSnack(false)} severity="error">
					{errorMessage}
				</Alert>
			</Snackbar>

			<PlacesAutocomplete
				value={address}
				onChange={handleChange}
				onSelect={handleSelect}
				searchOptions={{
					types: ["address"],
					componentRestrictions: { country: "us" }
				}}>
				{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
					<div className="dashboard--table--searchbar">
						<form className="searchbar--form relative" onSubmit={onSubmit}>
							<input
								{...getInputProps({
									placeholder: "Search Property By Street Address",
									className: "location-search-input search-input fs-16"
								})}
							/>
							<button type="submit" className="search-submit flex items-center justify-center">
								{searchLoading && <span className="button-spiner"></span>}
								Submit
							</button>
							{/* <input className='search-submit' type='submit' value='Submit' /> */}
							<div className="autocomplete-dropdown-container absolute block left-0 right-0" style={{ display: "block" }}>
								{loading && <div>Loading...</div>}
								{suggestions.map((suggestion, index) => {
									const className = suggestion.active ? "suggestion-item--active autocomplete-dropdown-container-single" : "suggestion-item autocomplete-dropdown-container-single";
									// inline style for demonstration purpose
									const style = suggestion.active ? { backgroundColor: "#50bfd8", cursor: "pointer", color: "white" } : { backgroundColor: "#ffffff", cursor: "pointer" };
									return (
										<div
											{...getSuggestionItemProps(suggestion, {
												className,
												style
											})}
											key={index}>
											<span>{getText(suggestion.description)}</span>
										</div>
									);
								})}
							</div>
						</form>
					</div>
				)}
			</PlacesAutocomplete>
		</>
	);
};

export default LocationSearchInput;
