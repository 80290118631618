import { createSlice } from "@reduxjs/toolkit";
import inisitailState from "./singlePropertyDefaultData";
export const singlePropertySlice = createSlice({
	name: "singleProperty",
	initialState: inisitailState,
	reducers: {
		setData: (state, action) => {
			return {
				...action.payload
			};
		},
		setEstated: (state, action) => {
			const dataFromState = state.estated.data;
			const dataFromPayload = action.payload.data;
			console.log("15", dataFromPayload.structure);
			return {
				...state,
				estated: {
					data: {
						address: dataFromPayload.address || dataFromState.address,
						parcel: dataFromPayload.parcel || dataFromState.parcel,
						structure: dataFromPayload.structure || inisitailState.estated.data.structure,
						taxes: dataFromPayload.taxes || dataFromState.taxes,
						deeds: dataFromPayload.deeds || dataFromState.deeds,
						assessments: dataFromPayload.assessments || dataFromState.assessments,
						market_assessments: dataFromPayload.market_assessments || dataFromState.market_assessments,
						valuation: dataFromPayload.valuation || {
							high: 0,
							low: 0,
							value: 0
						},
						owner: dataFromPayload.owner || dataFromState.owner
					}
				}
			};
		},
		setAirDna: (state, action) => {
			return {
				...state,
				airDna: action.payload
			};
		},
		setRealtyMole: (state, action) => {
			return {
				...state,
				realtyMole: action.payload
			};
		},
		setAddress: (state, action) => {
			return {
				...state,
				address: action.payload
			};
		},
		setLatLng: (state, action) => {
			return {
				...state,
				...action.payload
			};
		},
		setRealtyMoleProperty: (state, action) => {
			return {
				...state,
				realty_mole_property: action.payload
			};
		}
	}
});

export const { setData, setEstated, setAirDna, setRealtyMole, setAddress, setLatLng, setRealtyMoleProperty } = singlePropertySlice.actions;

// export const incrementAsync = (amount) => (dispatch) => {
//     setTimeout(() => {
//         dispatch(incrementByAmount(amount));
//     }, 1000);
// };

export const singlePropertyState = (state) => state.singleProperty;

export default singlePropertySlice.reducer;
