import Avatar from "assets/img/avatar.png";
import BreadCumb from "components/BreadCumb";
import ProfileTab from "components/ProfileTab";
import ProfileTab2 from "components/ProfileTab2";
import UserProfile from "components/userProfile";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { userState } from "reducers/user";
import { ContainerBox } from "styles/Box";
import Details from "./ProfileBody.Details";
function ProfileBody() {
	const user = useSelector(userState);

	const [tab, setTab] = useState("details");
	const [img, setImge] = useState({
		file: undefined,
		name: !!user.profile_pic ? user.profile_pic : !!user.profile_avatar ? user.profile_avatar : Avatar
	});

	const imageChange = (event) => {
		setImge({
			file: event.target.files[0],
			name: URL.createObjectURL(event.target.files[0])
		});
	};
	return (
		<div>
			<BreadCumb breadCumb={[{ name: "Profile", link: "/profile" }]} />
			<ContainerBox className="pl-21 pr-43 page-main-container">
				<UserProfile user={user} img={img.name} setImge={imageChange} />
				<ProfileTab tabName="details" />
				<ProfileTab2 tabName="details" />
				<Details user={user} img={img} />
			</ContainerBox>
		</div>
	);
}

export default ProfileBody;
