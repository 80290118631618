import React from 'react';
import styled from 'styled-components';

const Shape = styled.div<{ isUp: boolean }>`
    width: 0;
    height: 0;
    border-left: 21px solid transparent;
    border-right: 21px solid transparent;
    border-bottom: ${(props) => (props.isUp ? '35px solid #8983ff' : '35px solid red')};
    transform: ${(props) => (props.isUp ? 'rotate(0deg)' : 'rotate(180deg)')};
`;

const upDown: React.FC<{ isUp: boolean }> = ({ isUp = true }) => {
    return <Shape isUp={isUp} />;
};

export default upDown;
