import TableHeaderBar from 'components/tableHeaderBar';
import React from 'react';
import styled from 'styled-components';
import { Button } from 'styles/Buttons';
interface Props {
    batchUpload: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
const ButtonStyles = styled(Button)`
    color: #4e77b6;
`;

function TableHeader({ batchUpload }: Props) {
    return (
        <div>
            <TableHeaderBar
                title='Batch Upload'
                HeaderRightComponent={() => <ButtonStyles onClick={batchUpload}> Batch Upload </ButtonStyles>}
            />
        </div>
    );
}

export default TableHeader;
