import ExtendTableCard from 'components/extendableCard';
import UpDown from 'components/upDown';
import useAnalyzer from 'hooks/useAnalyzer';
import React from 'react';
import numberWithCommas from 'utils/numberWithCommas';

const PrivateModney = () => {
    const { getMortageLienAmount } = useAnalyzer();
    return (
        <div>
            <ExtendTableCard className='dealinformation__1' title='PRIVATE MONEY CAPITAL NEEDED' isOpen={true}>
                <div className='py-20'>
                    <div className='py-10 flex justify-between items-center relative px-20'>
                        <div className='w-4/12 flex flex-col items-center  relative'>
                            <UpDown isUp={Number(getMortageLienAmount()) > 0 ? true : false} />
                        </div>
                        <div className='w-8/12 flex flex-col justify-center items-center fs-20 brand-text font-bold'>
                            ${numberWithCommas(getMortageLienAmount())}
                        </div>
                    </div>
                </div>
            </ExtendTableCard>
        </div>
    );
};

export default PrivateModney;
