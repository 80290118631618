import Cookies from 'js-cookie';
import React from 'react';
const Logout = () => {
    React.useEffect(() => {
        Cookies.remove('token');
        window.location.replace('/');
    }, []);
    return <div></div>;
};

export default Logout;
