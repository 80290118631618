import ExtendTableCard from 'components/extendableCard';
import UpDown from 'components/upDown';
import useAnalyzer from 'hooks/useAnalyzer';
import React from 'react';
import numberWithCommas from 'utils/numberWithCommas';

const NetProfit = () => {
    const { estimatedNetPropit, totalCostReturn } = useAnalyzer();
    return (
        <div>
            <ExtendTableCard className='dealinformation__1' title='ESTIMATED NET PROFIT' isOpen={true}>
                <div className='py-20'>
                    <div className='py-10 flex justify-between items-center relative px-20'>
                        <div className='w-4/12 flex flex-col items-center  relative'>
                            <UpDown isUp={Number(estimatedNetPropit()) > 0 ? true : false} />
                        </div>
                        <div className='w-8/12 flex flex-col justify-center items-center fs-20 brand-text font-bold'>
                            ${numberWithCommas(estimatedNetPropit())}
                        </div>
                    </div>
                    <h3 className='text-center py-10 fs-14'>TOTAL COSTS RETURN ON INVESTMENT (ROI)</h3>
                    <div className='py-10 flex justify-between items-center relative px-20'>
                        <div className='w-4/12 flex flex-col items-center  relative'>
                            <UpDown isUp={Number(totalCostReturn()) > 0 ? true : false} />
                        </div>
                        <div className='w-8/12 flex flex-col justify-center items-center fs-20 brand-text font-bold'>
                            {totalCostReturn()}%
                        </div>
                    </div>
                </div>
            </ExtendTableCard>
        </div>
    );
};

export default NetProfit;
