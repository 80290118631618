import React, { useEffect } from "react";
import SideBar from "../../components/sidebar";
import MarketPropertiesWrapper from "./marketPropertiesWrapper";

import MobileDashboardNav from "../../components/mobileDashboardNav/mobileDashboardNav";

const MarketProperties = () => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<MobileDashboardNav />
			<div className={`flex comparable`}>
				<div className="main-sidebar-container">
					<SideBar />
				</div>
				<MarketPropertiesWrapper />
			</div>
		</>
	);
};

export default MarketProperties;
