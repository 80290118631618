import axios from "axios";
import PasswordInput from "components/Input/passwordInput";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import FbButton from "../../components/signin/fbButton";
import GoogleButton from "../../components/signin/googleButton";
import SigninAndSignupTab from "../../components/signin/SigninAndSignupTab";
import SinginAndSingupWraper from "../../hoc/signupAndSIgnPage";
import useError from "../../hooks/useError";
// import EmailIcon from "../../assets/img/"
import "./signin.css";

interface State {
	email: string;
	password: string;
	id_token: string;
}
const Signin: React.FC = () => {
	const history = useHistory();
	const [state, setState] = useState<State>({ email: "", password: "", id_token: "" });
	const { error, setError } = useError();

	const onChange = (event: any) => {
		setState({ ...state, [event.target.name]: event.target.value });
	};

	const showAndRemoveError = (time: number, errorMessage: string): void => {
		setError({
			isError: true,
			errorMessage: errorMessage
		});
		setTimeout(() => {
			setError({
				isError: false,
				errorMessage: ""
			});
		}, time);
	};

	const onSubmit = async (event: any) => {
		event.preventDefault();
		try {
			const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/general/signin`, {
				...state,
				email: !!event.email ? event.email : state.email,
				id_token: !!event.id_token ? event.id_token : "",
				reg_by: !!event.loginType ? event.loginType : "email"
			});

			if (response.status === 200) {
				Cookies.set("token", response.data.access_token, { expires: 30 });
				history.push("/dashboard");
			}
		} catch (error) {
			showAndRemoveError(4000, error.response.data.message);
		}
	};
	const responseGoogle = async (response: any) => {
		if (!!response) {
			console.log(response);
			try {
				const userData = response.getBasicProfile();
				const email = userData?.getEmail();
				const id_token = response?.getAuthResponse()?.id_token;
				if (!!email && !!id_token) {
					onSubmit({
						preventDefault: () => {},
						loginType: "google",
						email: email,
						id_token: id_token
					});
				}
			} catch (error) {
				console.log(error);
			}
		}
	};
	const responseFacebook = (response: any) => {
		if (!!response) {
			if (!!response.email && !!response.accessToken) {
				onSubmit({
					preventDefault: () => {},
					loginType: "facebook",
					email: response.email,
					id_token: response.accessToken
				});
			}
		}
	};

	return (
		<div className="tntro-leftside pt-40 md:pt-92 pb-40 md:pb-92 pl-15 md:pl-59 pr-15 md:pr-59">
			<SigninAndSignupTab />

			<div className="flex justify-between flex-row login-buttons">
				<FbButton responseFacebook={responseFacebook} />
				<GoogleButton responseGoogle={responseGoogle} />
			</div>
			<div className="signup--or mb-15 mt-15 md:mb-20 md:mt-20"> - or - </div>
			<form className="w-full " onSubmit={() => history.push("/dashboard")}>
				<div className="signin-input flex w-full h-50 pl-26 overflow-hidden mb-15">
					<div className="input--img">
						<img className="w-20 h-20 mr-15" src="https://cdn.zeplin.io/5ef9b0be7b4748819ae0decf/assets/c8bda553-9c90-4075-883c-c29966d45a29.svg" alt="" />
					</div>
					<input name="email" onChange={onChange} value={state.email} className="w-full h-full outline-none" type="text" placeholder="Write your email" />
				</div>
				<PasswordInput name="password" value={state.password} onChange={onChange} placeholder="Write your password" />
				<div onClick={onSubmit} className="signin--cehckbox mt-14">
					<button className="flex justify-center items-center">Sign In</button>
				</div>
				<div className="fs-14 mt-14 underline">
					<Link to="/forget-password">Forgotten password?</Link>
				</div>
				{error.isError && (
					<div className="fs-14 mb-14 mt-14" style={{ color: "#c71f66" }}>
						{error.errorMessage}
					</div>
				)}
			</form>
		</div>
	);
};

export default SinginAndSingupWraper(Signin);
