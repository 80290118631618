import TableHeaderBar from 'components/tableHeaderBar';
import React from 'react';

function TableHeaderRightComponent() {
    return <span></span>;
}

function TableHeader() {
    return (
        <div>
            <TableHeaderBar title='Recent' HeaderRightComponent={TableHeaderRightComponent} />
        </div>
    );
}

export default TableHeader;
