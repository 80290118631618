import { Dialog } from '@material-ui/core';
import Avatar from 'assets/img/avatar.png';
import Gmail from 'assets/svg/gmail.svg';
import Phone from 'assets/svg/phone-call.svg';
import Plus from 'assets/svg/plus.svg';
import axios from 'axios';
import { Input, InputLabel } from 'components/Input';
import Cookies from 'js-cookie';
import React from 'react';
import { useHistory } from 'react-router';
import formatPhoneNumber from 'utils/formatPhone';
import validationUpdatePass from 'utils/updatePasswordValid';
import './userProfile.scss';
function UserProfile(props: any) {
    const [open, setOpen] = React.useState(false);
    const onClose = () => {
        setOpen(false);
    };
    const history = useHistory();
    const [allError, setErrors] = React.useState({
        current_password: '',
        password: '',
        password_confirmation: '',
    });
    const [state, setState] = React.useState({
        current_password: '',
        password: '',
        password_confirmation: '',
    });

    const onChange = (event: any) => {
        setState({ ...state, [event.target.name]: event.target.value });
        setErrors({
            ...allError,
            [event.target.name]: '',
        });
    };

    const onSubmit = async (event: any) => {
        event.preventDefault();

        const { isValid, errors } = validationUpdatePass(state);

        if (isValid) {
            // setLoading(true);
            const token = Cookies.get('token');

            // formData.append('photo', img.file);

            try {
                await axios.post(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/general/update-password`,
                    { ...state },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
                Cookies.remove('token');
                history.push('/signin');
            } catch (err) {
                setErrors({ ...allError, password_confirmation: err.response.data.message });
            }
        } else {
            setErrors({ ...errors });
        }
    };
    return (
        <div className='pl-40 pr-40 py-30 flex items-center pb-43'>
            <Dialog open={open} onClose={onClose} maxWidth='sm' className='subscription__modall trasition_on_item'>
                <div className='p-30'>
                    <h3 className='fs-24 mb-20 text-center brand-text'>Change password</h3>
                    <form onSubmit={onSubmit}>
                        <InputLabel text='Your current password' />
                        <Input
                            type='password'
                            placeholder='Your current password'
                            className='mt-10 mb-20'
                            value={state.current_password}
                            name='current_password'
                            onChange={onChange}
                            required={true}
                        />
                        {!!allError.current_password ? (
                            <small className='block fs-14 -mt-10 red '>{allError.current_password}</small>
                        ) : null}

                        <InputLabel text='New password' />
                        <Input
                            type='password'
                            placeholder='New password'
                            className='mt-10 mb-20'
                            value={state.password}
                            name='password'
                            onChange={onChange}
                            required={true}
                        />
                        {!!allError.password ? <small className='block fs-14 -mt-10 red '>{allError.password}</small> : null}
                        <InputLabel text='Confirm password ' />
                        <Input
                            type='password'
                            placeholder='Confirm password '
                            className='mt-10 mb-20'
                            value={state.password_confirmation}
                            name='password_confirmation'
                            onChange={onChange}
                            required={true}
                        />
                        {!!allError.password_confirmation ? (
                            <small className='block fs-14 -mt-10 red '>{allError.password_confirmation}</small>
                        ) : null}
                        <button type='submit' className='submit__button mt-10'>
                            Update Password
                        </button>
                    </form>
                </div>
            </Dialog>
            <div className='relative mr-51'>
                <div className='w-90 h-90 rounded-full border-2  overflow-hidden '>
                    <img
                        style={{ width: '100%', height: '100%' }}
                        src={
                            props.img
                                ? props.img
                                : !!props.user.profile_pic
                                ? props.user.profile_pic
                                : !!props.user.profile_avatar
                                ? props.user.profile_avatar
                                : Avatar
                        }
                        alt='UserProfile'
                    />
                </div>
                {props.img ? (
                    <div className='absolute imgfileuploader_div flex items-center justify-center cursor-pointer h-30 w-30 right-0 bottom-0 overflow-hidden '>
                        <div>
                            <img className='h-20 w-20' src={Plus} alt='upload' />
                        </div>
                        <input
                            type='file'
                            accept='image/*'
                            onChange={props.setImge}
                            className='top-0 left-0 right-0 bottom-0 cursor-pointer w-full h-full opacity-0 absolute'
                        />
                    </div>
                ) : null}
            </div>

            <div className='profile--section'>
                <h5>{props.user.first_name}</h5>
                <h6 className='mb-5'>Investor</h6>
                <div className='flex profile--section--mobile'>
                    <div className='mr-29 flex'>
                        <img src={Gmail} alt='gmail' height='20px' width='20px' className='mr-15' /> {props.user.email}
                    </div>
                    {!!props.user.phone ? (
                        <div className='flex'>
                            {' '}
                            <img src={Phone} alt='gmail' height='20px' width='20px' className='mr-15' />{' '}
                            {formatPhoneNumber(props.user.phone)}
                        </div>
                    ) : null}
                </div>
                <p className='mt-10 fs-14 underline cursor-pointer' onClick={() => setOpen(true)}>
                    Change Password
                </p>
            </div>
        </div>
    );
}

export default UserProfile;
