import Sidebar from "components/sidebar";
import React from "react";
import Recent from "./recent";

import MobileDashboardNav from "../../components/mobileDashboardNav/mobileDashboardNav";

function index() {
	return (
		<>
			<MobileDashboardNav />
			<div className="flex">
				<div className="main-sidebar-container">
					<Sidebar />
				</div>
				<Recent />
			</div>
		</>
	);
}

export default index;
