import { createStyles, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
const InputComponent = styled.input`
    width: ${(props) => (props.width ? props.width : '100%')};
    height: ${(props) => (props.height ? props.height : '55px')};
    border-radius: ${(props) => (props.radius ? props.radiusv : '1rem')};
    border: ${(props) => (props.border ? props.border : '0.1rem solid #4e77b6')};
    background-color: #ffffff;
    font-family: Poppins;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2.14;
    text-indent: 2rem;
    color: #242424;
    &::placeholder {
        font-family: Poppins;
        font-size: 1.4rem;
        line-height: 2.14;
        color: #d5d5d5;
    }
`;

export function Input(props) {
    return <InputComponent {...props} />;
}

Input.defaultProps = {
    type: 'text',
};

const Label = styled.label`
    font-family: Poppins;
    color: ${(props) => (props.color ? props.color : '#7b87ae')};
    font-size: ${(props) => (props.size ? props.size : '1.6rem')};
`;

export const InputLabel = ({ text, className = '' }) => {
    return <Label className={className}>{text}</Label>;
};

InputLabel.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
};

export const IOSSwitch = withStyles((theme) =>
    createStyles({
        root: {
            width: 42,
            height: 26,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            '&$checked': {
                transform: 'translateX(1.6rem)',
                color: theme.palette.common.white,
                '& + $track': {
                    backgroundColor: '#52d869',
                    opacity: 1,
                    border: 'none',
                },
            },
            '&$focusVisible $thumb': {
                color: '#52d869',
                border: '0.6rem solid #fff',
            },
        },
        thumb: {
            width: 24,
            height: 24,
        },
        track: {
            borderRadius: 26 / 2,
            border: `0.1rem solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.grey[50],
            opacity: 1,
            transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
    })
)(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});
