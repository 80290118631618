import React from 'react';
import DealTR from 'components/DealTR/dealTR';
import ExtendTableCard from 'components/extendableCard';
import CalculationInput from 'components/calculationInput/calculationInput';
const BuyingCosts = () => {
    return (
        <div className='mt-20'>
            <ExtendTableCard className='dealinformation__2' title='BUYING COSTS' isOpen={true}>
                <div className='border'>
                    <div>
                        <table className='fs-14' style={{ width: '100%' }}>
                            <DealTR
                                right='Title Insurance / Search Costs'
                                input={<CalculationInput type='percentage' name='titleInsurance' />}
                            />
                            <DealTR right='Miscellaneous Buying Costs' input={<CalculationInput name='miscellaneousBuyingCosts' />} />
                            <DealTR right='Escrow / Attorney Fees ' input={<CalculationInput name='escrowAttorneyFees' />} />
                            <DealTR right='Property Taxes (annually)  ' input={<CalculationInput name='propertyTaxes' />} />
                            <DealTR right='Insurance (annually)' input={<CalculationInput name='insurance' />} />
                            <DealTR right='Utilities (monthly)' input={<CalculationInput name='utilities' />} />
                            <DealTR right='HOA & Condo Fees (monthly)' border={true} input={<CalculationInput name='HOACondoFees' />} />
                        </table>
                    </div>
                </div>
            </ExtendTableCard>
        </div>
    );
};

export default BuyingCosts;
