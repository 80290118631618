import React, { useState } from "react";
import Sidebar from "../sidebar";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/img/logo.png";

import "./mobileDashboardNav.css";

function MobileDashboardNav() {
	const [showSidebar, setShowSidebar] = React.useState(false);

	const handleOpenSidebar = () => {
		setShowSidebar(true);
	};

	const handleCloseSidebar = () => {
		setShowSidebar(false);
	};
	return (
		<div className="dashboard-navbar">
			<div className="left">
				<NavLink to="/">
					<img src={Logo} alt="logo" />
				</NavLink>
			</div>
			<div className="right">
				<div className="hamburger" onClick={handleOpenSidebar}>
					<div className="bar"></div>
					<div className="bar"></div>
				</div>
			</div>
			<div className={showSidebar ? "sidebar-holder active" : "sidebar-holder"} onClick={handleCloseSidebar}>
				<div className={showSidebar ? "sidebar-holder-sidebar active" : "sidebar-holder-sidebar"}>
					<Sidebar />
				</div>
			</div>
		</div>
	);
}

export default MobileDashboardNav;
