import { Checkbox, Dialog } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import PasswordInput from "components/Input/passwordInput";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Account from "../../assets/img/user.svg";
import FbButton from "../../components/signin/fbButton";
import GoogleButton from "../../components/signin/googleButton";
import SigninAndSignupTab from "../../components/signin/SigninAndSignupTab";
import SinginAndSingupWraper from "../../hoc/signupAndSIgnPage";
// import getFistNameAndLastName from '../../utils/getFirstNameAndLastName';
import useError from "../../hooks/useError";
import getFirstNameAndLastName from "../../utils/getFirstNameAndLastName";
const useStyles = makeStyles(() => ({
	formControl: {
		minWidth: "100%",
		height: "5rem"
	},
	selectEmpty: {
		fontSize: "1.4rem"
	},
	root: {
		color: "#4e77b6",
		fontSize: "2rem",
		width: "2rem",
		height: "2rem",
		"&$checked": {
			color: "#4e77b6"
		}
	},
	dialog: {
		padding: "3rem"
	}
}));

interface State {
	first_name: string;
	last_name: string;
	email: string;
	password: string;
	id_token: string;
	profile_avatar: String;
}

// import EmailIcon from "../../assets/img/"
const Signup: React.FC = () => {
	const history = useHistory();
	const classes = useStyles();
	const [state, setState] = useState<State>({
		email: "",
		password: "",
		first_name: "",
		last_name: "",
		id_token: "",
		profile_avatar: ""
	});

	const { error, setError } = useError();
	const [signinWith, setSigninWith] = useState<{ type: "email" | "facebook" | "google" }>({
		type: "email"
	});
	const [checkAgreeInTerms, setCheckAgreeTerms] = useState<boolean>(false);
	const [checkAgreeNewsletter, setCheckAgreeNewsletter] = useState<boolean>(false);
	const [open, setOpen] = useState<boolean>(false);
	const [interest, setInterest] = useState<string>("Homeowner");
	const onChange = (event: any) => {
		setState({ ...state, [event.target.name]: event.target.value });
	};

	const handleChange = (event: any) => {
		setInterest(event.target.value);
	};

	const showAndRemoveError = (time: number, errorMessage: string): void => {
		setError({
			isError: true,
			errorMessage: errorMessage
		});
		setTimeout(() => {
			setError({
				isError: false,
				errorMessage: ""
			});
		}, time);
	};

	const responseGoogle = async (response: any) => {
		if (!!response) {
			console.log(response);
			try {
				const userData = response?.getBasicProfile();
				const profile_avatar = userData.getImageUrl();

				const email = userData?.getEmail();
				const id_token = response?.getAuthResponse()?.id_token;
				const name = userData.getName();
				const { first_name, last_name } = getFirstNameAndLastName(name);
				if (!!email && !!id_token) {
					setState({
						...state,
						first_name: first_name,
						last_name: last_name,
						email,
						id_token,
						profile_avatar
					});
					setSigninWith({
						type: "google"
					});
					setOpen(true);
				}
			} catch (error) {
				console.log(error);
			}
		}
	};
	const modalOnClose = () => {
		setOpen(false);
		setState({
			email: "",
			password: "",
			first_name: "",
			last_name: "",
			id_token: "",
			profile_avatar: ""
		});
	};

	const responseFacebook = (response: any) => {
		const { first_name, last_name } = getFirstNameAndLastName(response.name);
		if (!!response) {
			if (!!response.email && !!response.accessToken) {
				setState({
					...state,
					first_name: first_name,
					last_name: last_name,
					email: response.email,
					profile_avatar: response.picture.data.url,
					id_token: response.accessToken
				});
				setSigninWith({
					type: "facebook"
				});
				setOpen(true);
			}
		}
	};

	const onContinue = (event: any) => {
		event.preventDefault();
		setSigninWith({ type: "email" });
		if (!!state.first_name && !!state.email) {
			setOpen(true);
		} else {
			showAndRemoveError(4000, "Before continue you should fill first name and email");
		}
	};

	const onSubmit = async (event: any) => {
		event.preventDefault();
		if (!!checkAgreeInTerms) {
			try {
				const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/general/signup`, {
					...state,
					reg_by: signinWith.type,
					interested_in: interest,
					subs_newsletter: checkAgreeNewsletter ? 1 : 0
				});

				if (response.status === 200) {
					Cookies.set("token", response.data.access_token, { expires: 30 });
					history.push("/dashboard");
				}
			} catch (err) {
				showAndRemoveError(4000, "The email already used");
			}
		} else {
			showAndRemoveError(4000, "Please accept Terms of Use and Privacy policy");
		}
	};
	return (
		<div className="tntro-leftside pt-40 md:pt-92 pb-40 md:pb-92 pl-15 md:pl-59 pr-15 md:pr-59 signup">
			<Dialog open={open} onClose={modalOnClose}>
				<div className={classes.dialog}>
					<h4 className="fs-14 fw-500 c-242424  mb-20">Area of Real Estate Investing you are most interested in?</h4>

					<div>
						<FormControl className={classes.formControl}>
							<Select value={interest} onChange={handleChange} displayEmpty className={classes.selectEmpty} inputProps={{ "aria-label": "Without label" }}>
								<MenuItem className={classes.selectEmpty} value={"Homeowner"}>
									Homeowner
								</MenuItem>
								<MenuItem className={classes.selectEmpty} value={"Wholesales"}>
									Wholesales
								</MenuItem>
								<MenuItem className={classes.selectEmpty} value={"Rehabs"}>
									Rehabs
								</MenuItem>
								<MenuItem className={classes.selectEmpty} value={"Traditional Rentals"}>
									Traditional Rentals
								</MenuItem>
								<MenuItem className={classes.selectEmpty} value={"Short Term Rentals"}>
									Short Term Rentals
								</MenuItem>
								<MenuItem className={classes.selectEmpty} value={"Notes Investing"}>
									Notes Investing
								</MenuItem>
							</Select>
						</FormControl>
					</div>
					<div className="flex items-center mb-20">
						<Checkbox checked={checkAgreeInTerms} className={classes.root} color="default" value={checkAgreeInTerms} onChange={() => setCheckAgreeTerms(!checkAgreeInTerms)} />
						<h5 className="fs-14 fw-500 c-919191 pl-8">
							{" "}
							I accept the{" "}
							<a href="/terms-and-condition" className="underline" target="_blank">
								Terms of Use and Privacy policy
							</a>
						</h5>
					</div>
					<div className="flex items-center mb-20">
						<Checkbox checked={checkAgreeNewsletter} className={classes.root} color="default" value={checkAgreeNewsletter} onChange={() => setCheckAgreeNewsletter(!checkAgreeNewsletter)} />
						<h5 className="fs-14 fw-500 c-919191 pl-8">Subscribe to our newsletter. (Don't worry... we won't spam you, and you can unsubscribe anytime.)</h5>
					</div>

					<div onClick={onSubmit} className="signin--cehckbox">
						<button className="flex justify-center items-center">Submit</button>
					</div>
					{error.isError && (
						<div className="fs-14 mt-14 " style={{ color: "#c71f66" }}>
							{error.errorMessage}
						</div>
					)}
				</div>
			</Dialog>
			<SigninAndSignupTab />

			<div className="flex justify-between flex-row login-buttons">
				<FbButton responseFacebook={responseFacebook} />
				<GoogleButton responseGoogle={responseGoogle} />
			</div>
			<div className="signup--or mb-15 mt-15 md:mb-20 md:mt-20"> - or - </div>
			<form onSubmit={onContinue}>
				<div className="flex justify-between flex-row sign-up-row-input">
					<div className="signin-input flex w-full h-50 pl-18 md:pl-26 overflow-hidden mb-15" style={{ width: "48%" }}>
						<div className="input--img">
							<img className="w-20 h-20 mr-15 " src={Account} alt="first name" />
						</div>
						<input name="first_name" className="w-full h-full outline-none" type="text" placeholder="First Name" onChange={onChange} value={state.first_name} />
					</div>
					<div className="signin-input flex w-full h-50 pl-18 md:pl-26 overflow-hidden mb-15" style={{ width: "48%" }}>
						<div className="input--img">
							<img className="w-20 h-20 mr-15 " src={Account} alt="first name" />
						</div>
						<input name="last_name" className="w-full h-full outline-none" type="text" placeholder="Last name" onChange={onChange} value={state.last_name} />
					</div>
				</div>
				<div className="signin-input flex w-full h-50 pl-18 md:pl-26 overflow-hidden mb-15">
					<div className="input--img">
						<img className="w-20 h-20 mr-15 " src="https://cdn.zeplin.io/5ef9b0be7b4748819ae0decf/assets/c8bda553-9c90-4075-883c-c29966d45a29.svg" alt="" />
					</div>
					<input name="email" className="w-full h-full outline-none" type="email" placeholder="Write your email" onChange={onChange} value={state.email} />
				</div>
				<PasswordInput name="password" placeholder="Write your password" onChange={onChange} value={state.password} />

				<div onClick={onContinue} className="signin--cehckbox mt-20">
					<button className="flex justify-center items-center">Continue</button>
				</div>
			</form>
		</div>
	);
};

export default SinginAndSingupWraper(Signup);
