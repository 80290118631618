import { createSlice } from '@reduxjs/toolkit';
export const UserSlice = createSlice({
    name: 'user',
    initialState: {
        active: null,
        address: null,
        dob: null,
        email: '',
        first_name: '',
        id: undefined,
        last_name: '',
        phone: '',
        photo: undefined,
    },
    reducers: {
        setUser: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
    },
});

export const { setUser } = UserSlice.actions;

export const userState = (state) => state.userReducer;

export default UserSlice.reducer;
