import LoadingSkeleton from "components/loadingSkeleton";
import PaginationLink from "components/paginationLink";
import PropertyTR from "components/propertyTR";
import TableCompoent from "components/table/index.js";
import usePropertydata from "hooks/usePropertydata";
import React from "react";
import { useParams } from "react-router-dom";
import TableHeader from "./batch.tableHeader";

//Analytics
import ReactGA from "react-ga";

function Table() {
	let { id, name } = useParams();
	const { searchHead, loading, changeFavourite, page, propertyData } = usePropertydata(`/api/v1/general/batch/get-batch-property-list/${id}`);

	React.useEffect(() => {
		ReactGA.event({
			category: "Dashboard",
			action: "Batch list to batch details"
		});
	}, []);

	return (
		<div className="bg-white mt-20 table--top--wraper">
			<TableHeader name={name} />
			{loading ? (
				<LoadingSkeleton Head={searchHead} />
			) : (
				<div>
					<TableCompoent Th={searchHead}>
						{propertyData.data.map((value, index) => {
							return <PropertyTR value={value} changeFavourite={changeFavourite} />;
						})}
					</TableCompoent>
					{propertyData.meta.last_page !== 1 ? (
						<div className="py-25 flex items-center justify-center">
							<PaginationLink page={page} count={propertyData.meta.last_page} link={`/dashboard/batch/${id}/${name}/view?`} />
						</div>
					) : null}
				</div>
			)}
		</div>
	);
}

export default Table;
