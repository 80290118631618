import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { setMatix } from 'reducers/matix';
import { setList } from '../reducers/subscriptionList';
import { setUser } from '../reducers/user';
// import setIndentify from './setIndentyfyFreshchat';

const PrivateRoute = (props: any) => {
    const token = Cookies.get('token');
    const { children: Children, ...rest } = props;
    const [islogin, setIslogin] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [failed, setFailed] = useState<boolean>(false);
    const dispatch = useDispatch();

    const getMatix = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/general/get-user-matrix`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            dispatch(
                setMatix({
                    total_search: response.data.this_month_search.total_search,
                    number_of_batch: response.data.batch.number_of_batch,
                    total_number_of_properties: response.data.total_number_of_properties,
                    property_deal_analyze: response.data.property_deal_analyze,
                })
            );
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const getPackageData = async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/api/v1/general/subscriptions/available-packages/get
            `,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            dispatch(setList(response.data.data));
        };
        const getUserData = async () => {
            try {
                if (!!token) {
                    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/general/get-user-info`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    localStorage.setItem('profile', response.data.data.profile_avatar);
                    dispatch(setUser(response.data.data));

                    setIslogin(true);
                    setLoading(false);
                    // const { id, first_name, email, subs_info } = response.data.data;
                    // setIndentify(id, first_name, email, subs_info.subs_package);
                } else {
                    setFailed(true);
                }
            } catch (e) {
                Cookies.remove('token');
                setFailed(true);
            }
        };

        getUserData();
        getMatix();
        getPackageData();
        //eslint-disable-next-line
    }, []);

    if (islogin === true) {
        return <Route {...rest}> {Children}</Route>;
    }

    if (failed) {
        return <Redirect to='/signin' />;
    }

    return (
        <Backdrop open={loading === false ? true : false} style={{ background: 'white' }}>
            <CircularProgress style={{ color: '#50bfd8' }} />
        </Backdrop>
    );
};

export default PrivateRoute;
