import React from 'react';
import DealTR from 'components/DealTR/dealTR';
import ExtendTableCard from 'components/extendableCard';
import useAnalyzer from '../../hooks/useAnalyzer';
import commas from '../../utils/numberWithCommas';
const PotentialAnalysis = () => {
    const {
        refairEstimtorPerSq,
        getDownpaymentRequire,
        myCommittedCapital,
        myAnnualizedCash,
        totalAnnualized,
        rehabReturn,
    } = useAnalyzer();
    return (
        <div>
            <ExtendTableCard className='dealinformation__1' title='Potential Return & Profit Analysis' isOpen={true}>
                <div className='border'>
                    <div>
                        <table className='fs-14' style={{ width: '100%' }}>
                            <DealTR right='Assumes Sale is on or before  ' input={<input type='text' value='$4000' />} />
                            <DealTR
                                right='Purchase + Repair Estimate Cost Per Sq. Ft'
                                input={<input type='text' value={'$' + commas(refairEstimtorPerSq())} />}
                            />
                            <DealTR
                                right='Down Payment Required at Closing'
                                input={<input type='text' value={'$' + commas(getDownpaymentRequire())} />}
                            />
                            <DealTR right='My Committed Capital' input={<input type='text' value={'$' + commas(myCommittedCapital())} />} />
                            <DealTR
                                right='My Annualized Cash on Cash Return'
                                input={<input type='text' value={commas(myAnnualizedCash()) + '%'} />}
                            />
                            <DealTR
                                right='Total Annualized Cash on Cash Return'
                                input={<input type='text' value={commas(totalAnnualized()) + '%'} />}
                            />
                            <DealTR
                                right='Purchase + Rehab Return on Investment (ROI)'
                                border={true}
                                input={<input type='text' value={commas(rehabReturn()) + '%'} />}
                            />
                        </table>
                    </div>
                </div>
            </ExtendTableCard>
        </div>
    );
};

export default PotentialAnalysis;
