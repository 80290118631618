import ExtendTableCard from 'components/extendableCard';
import React from 'react';
import { devided } from 'utils/math';
import numberWithCommas from 'utils/numberWithCommas';
import useAnalyzer from '../../hooks/useAnalyzer';
import DefaultCard from './DefaultCard';

const TraditionalFinanceFurchage = () => {
    const { financedGrossIncome, financedPurchaseParcentage, totalCashNeeded } = useAnalyzer();

    const data = [
        { title: 'Annual Gross Income', value: 100 - Number(financedPurchaseParcentage()), color: '#50bfd818' },
        { title: 'Monthly Gross Income', value: Number(financedPurchaseParcentage()), color: '#50bfd8' },
    ];

    return (
        <div>
            <ExtendTableCard className='dealinformation__1' title='FINANCED PURCHASE' isOpen={true}>
                <DefaultCard
                    data={data}
                    parcent={financedPurchaseParcentage()}
                    value_1={numberWithCommas(financedGrossIncome())}
                    value_2={numberWithCommas(devided(financedGrossIncome(), 12))}
                    capital={numberWithCommas(totalCashNeeded())}
                />
            </ExtendTableCard>
        </div>
    );
};

export default TraditionalFinanceFurchage;
