const data = {
	address: "",
	favorite: false,
	lat: null,
	lng: null,
	id: 0,
	estated: {
		metadata: { publishing_date: "" },
		data: {
			address: {
				street_number: "",
				street_pre_direction: "",
				street_name: "",
				street_suffix: "",
				street_post_direction: "",
				unit_type: "",
				unit_number: "",
				formatted_street_address: "",
				state: "",
				zip_code: "",
				zip_plus_four_code: "",
				carrier_code: "",
				latitude: 0,
				longitude: 0,
				geocoding_accuracy: "",
				census_tract: ""
			},
			parcel: {
				apn_original: "",
				apn_unformatted: "",
				apn_previous: "",
				fips_code: "",
				depth_ft: "",
				frontage_ft: "",
				area_sq_ft: 0,
				area_acres: 0,
				county_name: "",
				county_land_use_code: "",
				county_land_use_description: "",
				standardized_land_use_category: "",
				standardized_land_use_type: "",
				location_descriptions: [],
				zoning: "",
				building_count: 0,
				tax_account_number: 0,
				legal_description: 0,
				lot_code: "",
				lot_number: "",
				subdivision: "",
				municipality: "",
				section_township_range: ""
			},
			structure: {
				year_built: 0,
				effective_year_built: 0,
				stories: 0,
				rooms_count: 0,
				beds_count: 0,
				baths: 0,
				partial_baths_count: 0,
				units_count: 0,
				parking_type: "",
				parking_spaces_count: "",
				pool_type: "",
				architecture_type: "",
				construction_type: "",
				exterior_wall_type: "",
				foundation_type: "",
				roof_material_type: "",
				roof_style_type: "",
				heating_type: "",
				heating_fuel_type: "",
				air_conditioning_type: "",
				fireplaces: "",
				basement_type: "",
				quality: "",
				condition: "",
				flooring_types: "",
				plumbing_fixtures_count: "",
				interior_wall_type: "",
				water_type: "",
				sewer_type: "",
				total_area_sq_ft: 0,
				other_areas: { type: "", sq_ft: "" },
				other_features: { type: "", sq_ft: "" },
				other_improvements: { type: "", sq_ft: "" },
				other_rooms: [],
				amenities: []
			},
			taxes: [],
			assessments: [],
			market_assessments: [],
			valuation: {
				value: 0,
				high: 0,
				low: 0,
				forecast_standard_deviation: [],
				date: ""
			},
			owner: {
				name: "",
				second_name: "",
				formatted_street_address: "",
				unit_type: "",
				unit_number: "",
				city: "",
				state: "",
				zip_code: "",
				zip_plus_four_code: "",
				owner_occupied: ""
			},
			deeds: []
		}
	},
	realtyMole: {
		rent: 0,
		rentRangeLow: 0,
		rentRangeHigh: 0,
		longitude: -98.35147909999999,
		latitude: 29.4759532,
		listings: []
	},
	airDna: {
		property_details: {
			bathrooms: 0,
			bedrooms: 0,
			zipcode: "",
			accommodates: 0,
			location: {
				lat: 23.799898,
				lng: 90.420766
			},
			address: "",
			address_lookup: ""
		},
		property_stats: {
			adr: {
				ltm: 0
			},
			occupancy: {
				ltm: 0
			},
			revenue: {
				ltm: 0
			}
		},
		permission: "full"
	},
	realty_mole_property: null
};

export default data;
