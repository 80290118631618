import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropertyInformationState, setInformation } from '../../reducers/propertyInformation';
import numberWithCommas from '../../utils/numberWithCommas';
type Name =
    | 'purchasePrice'
    | 'afterRepairValue'
    | 'holdTime'
    | 'estimatedRepairs'
    | 'closingCosts'
    | 'loantoValue'
    | 'loanTerminMonths'
    | 'mortgageInterest'
    | 'capitalExpenses'
    | 'estimatedMaintenanceCosts'
    | 'rentalEstimatedVacancyRate'
    | 'rentalManagementFee'
    | 'STRManagementFee'
    | 'STRFurnishings'
    | 'titleInsurance'
    | 'miscellaneousBuyingCosts'
    | 'escrowAttorneyFees'
    | 'propertyTaxes'
    | 'insurance'
    | 'utilities'
    | 'exitStrategy'
    | 'HOACondoFees'
    | 'firstMortgagePoints'
    | 'firstMortgagePoints2'
    | 'firstMortgageInterest'
    | 'secondMortgagePoints'
    | 'secondMortgageInterest'
    | 'miscMortgageLienAmount'
    | 'miscMortgagePoints'
    | 'miscMortgageInterest'
    | 'miscellaneousFinancingCosts'
    | 'escrowAttorneyFees'
    | 'sellingRecordingFees'
    | 'realtorFees'
    | 'transferConveyanceFees'
    | 'homeWarranty'
    | 'stagingCosts'
    | 'marketingCosts'
    | 'miscellaneousSellingCosts'
    | 'rentalIncome'
    | 'strAnnaulRevenue'
    | 'managementPercent';
type Input = {
    name: Name;
    type?: 'text' | 'percentage' | 'currency';
};

const CalculationInput: React.FC<Input> = ({ name, type = 'currency' }) => {
    const dispatch = useDispatch();
    const PropertyInformation = useSelector(PropertyInformationState);
    const onChange = React.useCallback(
        (event: any) => {
            dispatch(setInformation({ name: event.target.name, value: event.target.value }));
        },
        [PropertyInformation[name]]
    );

    const [inputType, setInputType] = React.useState<'text' | 'number'>('text');

    const getValue = (value: number | string) => {
        if (type === 'currency') {
            return `$${numberWithCommas(value)}`;
        } else if (type === 'percentage') {
            return `${value}%`;
        } else {
            return value;
        }
    };

    const getValueMemo = React.useCallback(() => {
        return getValue(PropertyInformation[name]);
    }, [PropertyInformation[name]]);
    const getNumberValue = React.useCallback(() => {
        return PropertyInformation[name];
    }, [PropertyInformation[name]]);

    return inputType === 'text' ? (
        <input type='text' value={getValueMemo()} onFocus={() => setInputType('number')} className='analyzer__input' />
    ) : (
        <input
            onChange={onChange}
            name={name}
            type='number'
            value={getNumberValue()}
            onBlur={() => setInputType('text')}
            className='analyzer__input'
        />
    );
};

export default CalculationInput;
