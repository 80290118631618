import React from "react";
import { useSelector } from "react-redux";
import CheckImg from "../../assets/svg/check.svg";
import { userState } from "../../reducers/user";
// import Bookmark from '../../assets/svg/bookmark.svg';
// import BookMarkWhite from '../../assets/svg/bookmark-white.svg';
import commas from "../../utils/numberWithCommas";
const SubcriptionModalItem = ({ information, changePakage }: { information: any; changePakage: any }) => {
	const { subs_info } = useSelector(userState);
	return (
		<div onClick={() => changePakage(information.id)} className={`subscription__item  relative flex items-center ${!!information.default ? "subscription__item__active" : ""}`}>
			<div className="flex justify-between cursor-pointer" style={{ width: "100%", alignContent: "space-between" }}>
				{/* {!!information.best_value ? (
                    <div className='absolute ' style={{ top: '-0.1rem', right: '20px' }}>
                        <img src={!!information.default ? BookMarkWhite : Bookmark} width='20px' alt='bookmark' />
                    </div>
                ) : null} */}
				<div className="flex items-center" style={{ alignContent: "space-between" }}>
					<div className={`rounded w-30 h-30 mr-20 overflow-hidden ${!!information.default ? "" : "border_in_img"}`}>
						<div style={{ background: "white" }}>{!!information.default ? <img src={CheckImg} alt="check for select subscription package" /> : null}</div>
					</div>
					<div>
						<h3 className="fs-16">{information.package_name}</h3>
						<div className="flex">
							{!!information.best_value ? (
								<div className="mr-5">
									<div className={!information.default ? "sticker_best" : "sticker_white"}>Best Value</div>{" "}
								</div>
							) : null}
							{information.id == subs_info.subs_package_id ? (
								<div className="">
									<div className={!information.default ? "sticker" : "sticker_white"}>Current Plan</div>{" "}
								</div>
							) : null}
						</div>
					</div>
				</div>
				<div className="flex items-center ">
					<div></div>
					<div className="flex items-center price-section" style={{ marginLeft: "1rem" }}>
						<h4 className="fs-20 font-bold">${commas(information.regular_rate)}</h4> <span style={{ marginTop: "0.8rem", color: information.default ? "white" : "#978f8f", marginLeft: "0.3rem" }}>/{information.sub_title}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SubcriptionModalItem;
