import { createSlice } from '@reduxjs/toolkit';
export const csvSlice = createSlice({
    name: 'csv',
    initialState: {
        csv: {
      
            
        },
    },
    reducers: {
        setCsv: (state, action) => {
            return {
                csv: action.payload,
            };
        },
        deleteCsv : (state,action)=>{
        
            let csv =  {...state.csv};
            delete csv[action.payload];
            return {
                csv : !!csv ? csv : {} 
            }
        },
        addCsv : (state,action) =>{
            return {
                csv :{ ...state.csv , ...action.payload}
            }
        }

    },
});

export const { setCsv , deleteCsv,addCsv} = csvSlice.actions;
export const csvState = (state) => state.csvReducer;
export default csvSlice.reducer;
