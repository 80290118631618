import axios from 'axios';
import Cookies from 'js-cookie';
const getPropertyDataFromOurServer = async (address: string, id: string | number) => {
    const token = Cookies.get('token');
    try {
        if (!id) {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/api/v1/general/address/check`,
                { address: address },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            if (response.status === 200) {
                return response;
            } else {
                return {
                    status: 404,
                };
            }
        } else {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/general/address/single-property/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            return response;
        }
    } catch (error) {
        return false;
    }
};

export default getPropertyDataFromOurServer;
