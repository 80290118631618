import DealTab from "components/DealTab/DealTab";
import Navbar from "components/navbar";
import React from "react";
import { Link } from "react-router-dom";
import Pdf from "react-to-pdf";
import { ContainerBox } from "styles/Box";
import BuyAndHold from "./buyAndHold";
import BuyingCosts from "./buyingCosts";
import CashParchaced from "./cashParchase";
import DealFactors from "./dealFactors";
import FixAndFlip from "./fixAndFlip";
import NetProfit from "./netProfit";
import PrivateModney from "./PrivateMoney";
import ProjectDetails from "./projectDetails";
import PropertyDetails from "./propertyDetails";
import StrFinancePurchase from "./strFinancePurchase";
import Summary from "./Summery";
import TraditionalCashPurchage from "./TraditionalCashPurchage";
import TraditionalFinance from "./TraditionalFinancePurchage";

//Analytics
import ReactGA from "react-ga";

function Analyzer() {
	const ref = React.createRef();
	const options = {
		unit: "in",
		format: [1400, 1800]
	};

	React.useEffect(() => {
		ReactGA.event({
			category: "Dashboard",
			action: "Deal Analyzer Information"
		});
	}, []);

	return (
		<div className="dashboard--wraper pt-15 pl-15 pr-15 pb-15">
			<Navbar />

			<ContainerBox className="mt-20 ">
				<Pdf targetRef={ref} filename="deal_information.pdf" options={options} scale={1} x={0.1} y={0.5}>
					{({ toPdf }) => <DealTab tabName={"dealInformation"} onClick={toPdf} />}
				</Pdf>
				<div className="px-20 pb-20" ref={ref}>
					<div className="flex -mx-15 mb-20">
						<PropertyDetails />
						<ProjectDetails />
					</div>
					<div className="flex -mx-15 mb-20">
						<div className="w-4/12 px-15">
							<FixAndFlip />
							<BuyingCosts />
						</div>
						<BuyAndHold />
						<DealFactors />
					</div>
					<div className="flex -mx-15 mb-20">
						<div className="w-4/12 px-15">
							<h3 className="fs-16 mb-20 brand-text-color font-bold">
								Rehab
								<Link to="/deal-analyzer/deal-analyzer">
									<span className="fs-12 brand-text-color">(VIEW DETAILS)</span>
								</Link>
							</h3>
							<div className="mb-20">
								<NetProfit />
							</div>
							<div className="mb-20">
								<PrivateModney />
							</div>
							<div className="mb-20">
								<Summary />
							</div>
						</div>

						<div className="w-4/12 px-15">
							<h3 className="fs-16 mb-20 brand-text-color font-bold">
								TRADITIONAL RENTAL
								<Link to="/deal-analyzer/str">
									<span className="fs-12 brand-text-color">(VIEW DETAILS)</span>
								</Link>
							</h3>
							<div className="mb-20">
								<TraditionalCashPurchage />
							</div>
							<div className="mb-20">
								<TraditionalFinance />
							</div>
						</div>
						<div className="w-4/12 px-15">
							<h3 className="fs-16 mb-20 brand-text-color font-bold">
								STR
								<Link to="/deal-analyzer/traditional-rental">
									<span className="fs-12 brand-text-color">(VIEW DETAILS)</span>
								</Link>
							</h3>
							<div className="mb-20">
								<CashParchaced />
							</div>
							<div className="mb-20">
								<StrFinancePurchase />
							</div>
						</div>
					</div>
				</div>
			</ContainerBox>
		</div>
	);
}

export default Analyzer;
