import ExtendTableCard from 'components/extendableCard';
import React from 'react';
import { devided } from 'utils/math';
import numberWithCommas from 'utils/numberWithCommas';
import useAnalyzer from '../../hooks/useAnalyzer';
import DefaultCard from './DefaultCard';

const TraditionalCashPurchage = () => {
    const { AnnaulgrossIncome, purchaseParcentage, totolCashOutlay } = useAnalyzer();
    const data = [
        {
            title: 'Annual Gross Income',
            value: 100 - Number(purchaseParcentage()),
            color: '#50bfd818',
        },
        {
            title: 'Monthly Gross Income',
            value: Number(purchaseParcentage()),
            color: '#50bfd8',
        },
    ];

    return (
        <div>
            <ExtendTableCard className='dealinformation__1' title='CASH PURCHASE' isOpen={true}>
                <DefaultCard
                    data={data}
                    parcent={purchaseParcentage()}
                    value_1={numberWithCommas(AnnaulgrossIncome())}
                    value_2={numberWithCommas(devided(AnnaulgrossIncome(), 12))}
                    capital={numberWithCommas(totolCashOutlay())}
                />
            </ExtendTableCard>
        </div>
    );
};

export default TraditionalCashPurchage;
