import React from "react";
import { useSelector } from "react-redux";
import numberWithCommas from "utils/numberWithCommas";
import TableCompoent from "../../components/table/index.js";
import { singlePropertyState } from "../../reducers/singleProperty";
import "./comparable.scss";

//Analytics
import ReactGA from "react-ga";

const TableHead = ["Address", "Bedroom", "Bathroom", "Square foot", "Price"];

function RentalComparable() {
	const { realtyMole } = useSelector(singlePropertyState);

	React.useEffect(() => {
		ReactGA.event({
			category: "Property Details Page",
			action: "Property Rental Comparable View"
		});
	}, []);

	return (
		<>
			<div className="table-container">
				<TableCompoent Th={TableHead}>
					{realtyMole.listings.map((list: any) => {
						return (
							<tr>
								<td>{list.formattedAddress}</td>
								<td>{list.bedrooms ? list.bedrooms : 0}</td>
								<td>{list.bathrooms ? list.bathrooms : 0}</td>
								<td>{numberWithCommas(list.squareFootage ? list.squareFootage : 0)}</td>
								<td>{"$" + numberWithCommas(list.price ? list.price : 0)}</td>
							</tr>
						);
					})}
				</TableCompoent>
			</div>
			<div className="secondary-table-container">
				{realtyMole.listings.map((list: any) => {
					return (
						<div className="secondary-table-content">
							<div className="top">
								<div className="left">
									<p>{list.formattedAddress}</p>
								</div>
								<div className="right">
									<p>
										Beds: <span>{list.bedrooms ? list.bedrooms : 0}</span>
									</p>
									<p>
										Bathrooms: <span>{list.bathrooms ? list.bathrooms : 0}</span>
									</p>
								</div>
							</div>
							<div className="bottom">
								<div className="left">
									<p>
										Square foot: <span>{numberWithCommas(list.squareFootage ? list.squareFootage : 0)}</span>
									</p>
								</div>
								<div className="right">
									<p>
										Price: <span>{"$" + numberWithCommas(list.price ? list.price : 0)}</span>
									</p>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
}

export default RentalComparable;
