import React, { Component } from "react";
import "./deeds.css";

export class Deeds extends Component {
	render() {
		var { deed } = this.props;
		return (
			<div className="deed-container">
				<div className="deed-section">
					<div className="deed-section-left">
						<div className="deed-section-content">
							<h1>Document Type</h1>
							<p>{deed.document_type ? deed.document_type : "-"}</p>
						</div>
					</div>
					<div className="deed-section-right">
						<div className="deed-section-content">
							<h1>Sale Price</h1>
							<p className="deed-section-content-highlight">{deed.sale_price ? "$" + deed.sale_price : "-"}</p>
						</div>
						<div className="deed-section-content">
							<h1>Recorded Date</h1>
							<p className="deed-section-content-highlight">{deed.recording_date ? deed.recording_date : "-"}</p>
						</div>
						<div className="deed-section-content"></div>
					</div>
				</div>
				<div className="deed-section">
					<div className="deed-section-left">
						<div className="deed-section-content">
							<h1>Sales Information</h1>
							<p className="deed-section-content-highlight">{deed.seller_first_name}</p>
							<p className="deed-section-content-highlight">{deed.seller_last_name}</p>
							{deed.seller_address ? (
								<p className="deed-section-content-grey">
									{deed.seller_address}, {deed.seller_city}, {deed.seller_state}, {deed.seller_zip_code}
								</p>
							) : null}
						</div>
					</div>
					<div className="deed-section-right">
						<div className="deed-section-content">
							<h1>Buyer Information</h1>
							<p className="deed-section-content-highlight">{deed.buyer_first_name}</p>
							<p className="deed-section-content-highlight">{deed.buyer_last_name}</p>
							<p className="deed-section-content-grey">
								{deed.buyer_address}, {deed.buyer_city}, {deed.buyer_state}, {deed.buyer_zip_code}
							</p>
						</div>
						<div className="deed-section-content"></div>
						<div className="deed-section-content"></div>
					</div>
				</div>
				<div className="deed-section">
					<div className="deed-section-left">
						<div className="deed-section-content">
							<h1>Lendor Information</h1>
							<p>{deed.lender_name ? deed.lender_name : "-"}</p>
						</div>
					</div>
					<div className="deed-section-right">
						<div className="deed-section-content">
							<h1>Loan Amount</h1>
							<p>{deed.loan_amount ? "$" + deed.loan_amount : "-"}</p>
						</div>
						<div className="deed-section-content">
							<h1>Lender Type</h1>
							<p>{deed.lender_type ? deed.lender_type : "-"}</p>
						</div>
						<div className="deed-section-content">
							<h1>Loan Due Date</h1>
							<p>{deed.loan_due_date ? deed.loan_due_date : "-"}</p>
						</div>
					</div>
				</div>
				<div className="deed-section">
					<div className="deed-section-left">
						<div className="deed-section-content">
							<h1>Transfer Tax</h1>
							<p>{deed.transfer_tax ? "$" + deed.transfer_tax : "-"}</p>
						</div>
					</div>
					<div className="deed-section-right">
						<div className="deed-section-content">
							<h1>Original Contract Date</h1>
							<p>{deed.original_contract_date ? deed.original_contract_date : "-"}</p>
						</div>
						<div className="deed-section-content">
							<h1>Deed Book</h1>
							<p>{deed.deed_book ? deed.deed_book : "-"}</p>
						</div>
						<div className="deed-section-content">
							<h1>Deed Page</h1>
							<p>{deed.deed_page ? deed.deed_page : "-"}</p>
						</div>
					</div>
				</div>
				<div className="deed-section">
					<div className="deed-section-left">
						<div className="deed-section-content">
							<h1>Sales Price Description</h1>
							<p>{deed.sale_price_description ? deed.sale_price_description : "-"}</p>
						</div>
					</div>
					<div className="deed-section-right">
						<div className="deed-section-content"></div>
						<div className="deed-section-content"></div>
						<div className="deed-section-content"></div>
					</div>
				</div>
			</div>
		);
	}
}

export default Deeds;
