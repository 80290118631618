import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import FbIcon from '../../assets/img/facebook-button.svg';
function FbButton(props) {
    return (
        <React.Fragment>
            <FacebookLogin
                appId={`${process.env.REACT_APP_FB_APP_ID}`}
                onClick={props.responseFacebook}
                callback={props.responseFacebook}
                fields='name,email,picture'
                render={(renderProps) => (
                    <button onClick={renderProps.onClick} className='facebook--button'>
                        <span>
                            <img className='w-13 h-23' src={FbIcon} alt='Facebook login' />
                        </span>
                        <span>With Facebook</span>
                    </button>
                )}
            />
        </React.Fragment>
    );
}

export default FbButton;
