import Pie from 'components/pie';
import React from 'react';
const DefaultCard = ({ data, parcent, value_1, value_2, capital }: any) => {
    return (
        <div className='py-30 px-20'>
            <div className='w-12/12 relative'>
                <div className='absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center'>
                    <h3 className='fs-20 font-bold brand-text'>{parcent}%</h3>
                    <p className='second-text'>Cap Rate (ROI)</p>
                </div>
                <Pie data={data} />
            </div>
            <div className='flex my-20'>
                <div className='w-6/12 pr-20' style={{ borderRight: '0.1rem dotted #7b87ae' }}>
                    <h5 className='text-right fs-16 second-color'>Annual Net Income</h5>
                    <h3 className='text-right fs-20 brand-text'>${value_1}</h3>
                </div>
                <div className='w-6/12 pl-20'>
                    <h5 className=' fs-16 second-color'>Monthly Net Income</h5>
                    <h3 className='fs-20 brand-text'>${value_2}</h3>
                </div>
            </div>
            <div className='flex fs-18 justify-between font-bold' style={{ color: '#8983ff' }}>
                <h3>CAPITAL NEEDED</h3>
                <h3>${capital}</h3>
            </div>
        </div>
    );
};

export default DefaultCard;
