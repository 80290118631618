import React from 'react';
import Logo from '../assets/img/logo.png';
import BannerImg from '../assets/img/signup.png';
import './style.css';
const SignupAndSIgnPage = (Component) => {
    return () => {
        return (
                <div className='flex sign-main-container' style={{ zIndex: '0' }}>   
                    <div className="md:w-8/12 lg:w-6/12 w-full sign-main-container-left">
                        <div className='navbar lg:pl-40' style={{ justifyContent:"flex-start"}} >
                            <div className='h-80 flex'>
                                {/* <a href='/'> */}
                                    <img src={Logo} alt='logo' className='w-70 h-50' />
                                {/* </a> */}
                            </div>
                        </div>
                        <div className='startpage-left  sm:w-full pl-10 md:pl-20 lg:pl-0 pr-10  flex justify-center items-center'>
                            <div className="flex items-center justify-center sign-main-container-form">
                                <Component />
                            </div>
                        </div>
                    </div>
                    <div
                        className='sm:w-0 md:w-4/12 lg:w-6/12 bg-black sign-main-container-right'
                        style={{
                            background: `url(${BannerImg}`,
                            backgroundSize: 'cover',
                            height: '100vh',
                        }}
                    ></div>
                </div>
        );
    };
};

export default SignupAndSIgnPage;
