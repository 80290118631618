import styled from 'styled-components';

export const Button = styled.button`
    width: 206px;
    height: 48px;
    border-radius: 0.8rem;
    border: solid 0.1rem #4e77b6;
    background-color: #ffffff;
    color: #4e77b6;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.56;
`;
export const ButtonSecondary = styled.button`
    width: 217px;
    height: 48px;
    border-radius: 0.8rem;
    background-color: #50bfd8;
    font-family: Poppins;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.56;
    color: #ffffff;
`;
