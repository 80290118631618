import { Dialog } from "@material-ui/core";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Card1 from "assets/img/1.png";
import Card2 from "assets/img/2.png";
import Card3 from "assets/img/3.png";
import Card4 from "assets/img/4.png";
// import Stripe from '../../assets/img/stripe2.jpg';
import Snackbar from "components/snackbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setList, subscriptionListState } from "reducers/subscriptionList";
import { setModal, subscriptionState } from "reducers/subscriptionModal";
import { userState } from "reducers/user";
import commas from "utils/numberWithCommas";
import CheckoutForm from "../stripePaymentModal/checkoutForm";
import CardInfor from "./cardInfor";
import SubcriptionModalItem from "./SubcriptionModal.Item";
import "./subscriptionModal.scss";

//Analytics
import ReactGA from "react-ga";

const SubscriptionModal = (props: any) => {
	const { subscription } = useSelector(subscriptionState);
	const { list, details } = useSelector(subscriptionListState);
	const { subs_info, card_info } = useSelector(userState);
	const dispatch = useDispatch();
	const [message, setMeassage] = React.useState("");
	const [selected, setSelected] = React.useState<boolean>(false);
	const [errorOnPyment, setError] = React.useState<boolean>(false);
	const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
	const ref = React.useRef<HTMLDivElement>(null);
	const ref2 = React.useRef<HTMLDivElement>(null);
	const choosePlan = () => {
		ReactGA.event({
			category: "Profile",
			action: "Click_Choose_Plan_button"
		});
		if (subs_info?.subs_package_id !== details.id) {
			setSelected(true);
		}
	};

	const changePakage = (id: string) => {
		if (subs_info.subs_package_id == id) {
			setMeassage("You are already subscribed to this package.");
		} else {
			const value: any = list.map((data: any) => (data.id == id ? { ...data, default: 1 } : { ...data, default: 0 }));
			dispatch(setList(value));
		}
	};

	const onClose = () => {
		dispatch(setModal(false));
		setSelected(false);
	};

	return (
		<Dialog open={subscription} onClose={onClose} maxWidth="md" className="subscription__modal trasition_on_item">
			<Snackbar open={!!message ? true : false} setError={setMeassage} message={message} autoHideTime={1500} bg="#50bfd8" />;
			<div className="p-40 overflow-hidden subscription-dialog-box">
				<h3 className="brand-text fs-24 text-center uppercase">Choose the plan that works best for you</h3>

				<div className="flex subscription__modal__div mt-40" style={{ position: "relative" }}>
					<div className="subscription__modal__div__group__main">
						<div ref={ref} className="w-6/12 subscription__modal__plans px-10 trasition_on_item" style={{ transform: selected ? "translateY(700px)" : "translateY(0px)" }}>
							<div>
								{list.map((data: any) => {
									return <SubcriptionModalItem key={data.id} information={data} changePakage={changePakage} />;
								})}
							</div>
						</div>
						<div
							className="w-6/12 px-10 trasition_on_item"
							style={{
								transform: selected ? `translateX(-${ref.current?.clientWidth === undefined ? 0 : (ref.current?.clientWidth / 10).toFixed(2)}rem)` : "translateX(0px)"
							}}>
							<div className={`${selected ? "" : "subscription__modal__details"} p-30 trasition_on_item`}>
								<h3 className="uppercase fs-18 text-center brand-text">{details.package_name}</h3>
								<div className="mt-30">
									<div className="flex items-center justify-between subscription__modal__details__single">
										<p className="fs-16"># of Searches {details.package_name == "Pay Per Use" ? "Limited" : "Included"} /Month</p>
										<p className="fs-16 brand-text">{details.search_per_month}</p>
									</div>
									<div className="flex items-center justify-between subscription__modal__details__single">
										<p className="fs-16">{details.sub_title}</p>
										<p className="fs-16 brand-text">${commas(details.regular_rate)}</p>
									</div>
									<div className="flex items-center justify-between subscription__modal__details__single">
										<p className="fs-16">Overage</p>
										<p className="fs-16 brand-text">${details.over_use_rate}</p>
									</div>
									<div className="flex items-center justify-between subscription__modal__details__single">
										<p className="fs-16">Market Data Report </p>
										<p className="fs-16 brand-text">$10</p>
									</div>

									<div className="flex items-center justify-between subscription__modal__details__single">
										<p className="fs-16">Batch Upload</p>
										<p className="fs-16 brand-text">{details.batch ? "Yes" : "N/A"}</p>
									</div>
								</div>
							</div>

							{selected ? (
								<button className="outline__button mt-30 ml-30 trasition_on_item" style={{ width: "8rem", height: "4rem" }} onClick={() => setSelected(false)}>
									{" "}
									Back
								</button>
							) : (
								<div className="trasition_on_item">
									<div className="mt-20 flex justify-end items-center brand-logos">
										<div className="fs-16">
											<img src={"https://motorbreaker.co.uk/images/stripe_logos_2.png"} alt="" style={{ height: "4.5rem", marginRight: "1rem" }} />
										</div>
										<div className="flex ">
											<img width="40" src={Card1} alt="" className="mr-5" />
											<img width="40" src={Card2} alt="" className="mr-5" />
											<img width="40" src={Card3} alt="" className="mr-5" />
											<img width="40" src={Card4} alt="" />
										</div>
									</div>
									<div className="subscribe__button_wraper mt-20 pt-30 flex justify-end">
										<button
											className="submit__button trasition_on_item"
											style={{
												width: "15rem",
												height: "4rem ",
												display: selected ? "none" : "block",
												opacity: subs_info?.subs_package_id == details.id ? "0.3" : "1",
												cursor: subs_info?.subs_package_id == details.id ? "not-allowed" : "pointer"
											}}
											onClick={choosePlan}>
											Choose Plan
										</button>
									</div>
								</div>
							)}
						</div>
						<div
							className={`w-6/12 trasition_on_item px-10 StripeElement--focus ${selected ? "subscription__modal__details" : ""}  `}
							style={{
								position: "absolute",
								top: selected ? (!!card_info?.status ? "3.8rem" : "0px") : "100%",
								right: "0px",
								opacity: selected ? "1" : "0"
							}}>
							<div className="p-30">
								{!!card_info?.status ? (
									<CardInfor
										errorOnPyment={errorOnPyment}
										card_brand={card_info?.card_brand}
										card_last_four={card_info?.card_last_four}
										setSelected={setSelected}
										setError={setError}
										measseWhenChangePackage={props.measseWhenChangePackage}
									/>
								) : (
									<Elements stripe={stripePromise}>
										<CheckoutForm errorOnPyment={errorOnPyment} setSelected={setSelected} measseWhenChangePackage={props.measseWhenChangePackage} setError={setError} />
									</Elements>
								)}

								<div className="mt-20 flex items-center justify-end brand-logos" style={{ marginTop: !!card_info?.status ? "3.5rem" : "2rem" }}>
									<div className="fs-16">
										<img src={"https://motorbreaker.co.uk/images/stripe_logos_2.png"} alt="" style={{ height: "4.5rem", marginRight: "1rem" }} />
									</div>
									<div className="flex ">
										<img width="40" src={Card1} alt="" className="mr-5" />
										<img width="40" src={Card2} alt="" className="mr-5" />
										<img width="40" src={Card3} alt="" className="mr-5" />
										<img width="40" src={Card4} alt="" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="subscription__modal__div__group__main__phone">
						<div
							className={`w-6/12 trasition_on_item px-10 StripeElement--focus ${selected ? "subscription__modal__details" : ""}  `}
							style={{
								position: "absolute",
								top: selected ? "50%" : "0%",
								right: "0px",
								opacity: selected ? "1" : "0"
							}}>
							<div className="p-30">
								{!!card_info?.status ? (
									<CardInfor
										errorOnPyment={errorOnPyment}
										card_brand={card_info?.card_brand}
										card_last_four={card_info?.card_last_four}
										setSelected={setSelected}
										setError={setError}
										measseWhenChangePackage={props.measseWhenChangePackage}
									/>
								) : (
									<Elements stripe={stripePromise}>
										<CheckoutForm errorOnPyment={errorOnPyment} setSelected={setSelected} measseWhenChangePackage={props.measseWhenChangePackage} setError={setError} />
									</Elements>
								)}

								<div className="mt-20 flex items-center justify-end brand-logos" style={{ marginTop: !!card_info?.status ? "3.5rem" : "2rem" }}>
									<div className="fs-16">
										<img src={"https://motorbreaker.co.uk/images/stripe_logos_2.png"} alt="" style={{ height: "4.5rem", marginRight: "1rem" }} />
									</div>
									<div className="flex ">
										<img width="40" src={Card1} alt="" className="mr-5" />
										<img width="40" src={Card2} alt="" className="mr-5" />
										<img width="40" src={Card3} alt="" className="mr-5" />
										<img width="40" src={Card4} alt="" />
									</div>
								</div>
							</div>
						</div>
						<div ref={ref2} className="w-6/12 subscription__modal__plans px-10 trasition_on_item" style={{ transform: selected ? "translateY(-700px)" : "translateY(0px)" }}>
							<div>
								{list.map((data: any) => {
									return <SubcriptionModalItem key={data.id} information={data} changePakage={changePakage} />;
								})}
							</div>
						</div>
						<div
							className="w-6/12 px-10 trasition_on_item"
							style={{
								transform: selected ? "translateY(-400px)" : "translateY(0px)"
							}}>
							<div className={`${selected ? "" : "subscription__modal__details"} p-30 trasition_on_item`}>
								<h3 className="uppercase fs-18 text-center brand-text">{details.package_name}</h3>
								<div className="mt-30">
									<div className="flex items-center justify-between subscription__modal__details__single">
										<p className="fs-16"># of Searches {details.package_name == "Pay Per Use" ? "Limited" : "Included"} /Month</p>
										<p className="fs-16 brand-text">{details.search_per_month}</p>
									</div>
									<div className="flex items-center justify-between subscription__modal__details__single">
										<p className="fs-16">{details.sub_title}</p>
										<p className="fs-16 brand-text">${commas(details.regular_rate)}</p>
									</div>
									<div className="flex items-center justify-between subscription__modal__details__single">
										<p className="fs-16">Overage</p>
										<p className="fs-16 brand-text">${details.over_use_rate}</p>
									</div>
									<div className="flex items-center justify-between subscription__modal__details__single">
										<p className="fs-16">Market Data Report </p>
										<p className="fs-16 brand-text">$10</p>
									</div>

									<div className="flex items-center justify-between subscription__modal__details__single">
										<p className="fs-16">Batch Upload</p>
										<p className="fs-16 brand-text">{details.batch ? "Yes" : "N/A"}</p>
									</div>
								</div>
							</div>

							{selected ? (
								<button className="outline__button mt-30 ml-30 trasition_on_item" style={{ width: "8rem", height: "4rem" }} onClick={() => setSelected(false)}>
									{" "}
									Back
								</button>
							) : (
								<div className="trasition_on_item">
									<div className="mt-20 flex justify-end items-center brand-logos">
										<div className="fs-16">
											<img src={"https://motorbreaker.co.uk/images/stripe_logos_2.png"} alt="" style={{ height: "4.5rem", marginRight: "1rem" }} />
										</div>
										<div className="flex ">
											<img width="40" src={Card1} alt="" className="mr-5" />
											<img width="40" src={Card2} alt="" className="mr-5" />
											<img width="40" src={Card3} alt="" className="mr-5" />
											<img width="40" src={Card4} alt="" />
										</div>
									</div>
									<div className="subscribe__button_wraper mt-20 pt-30 flex justify-end">
										<button
											className="submit__button trasition_on_item"
											style={{
												width: "15rem",
												height: "4rem ",
												display: selected ? "none" : "block",
												opacity: subs_info?.subs_package_id == details.id ? "0.3" : "1",
												cursor: subs_info?.subs_package_id == details.id ? "not-allowed" : "pointer"
											}}
											onClick={choosePlan}>
											Choose Plan
										</button>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default SubscriptionModal;
