import React from 'react'

function BatchExcel() {
    return (
        <div>
            <svg className='w-21 h-30 cls-1' xmlns="http://www.w3.org/2000/svg" width="19.835" height="18.595" viewBox="0 0 19.835 18.595">
                <g className='cls-1' id="excel" transform="translate(0 -16.01)">
                    <g id="Group_2926" data-name="Group 2926" transform="translate(0 16.01)">
                    <g id="Group_2925" data-name="Group 2925" transform="translate(0 0)">
                        <path className='cls-1' id="Path_1356" data-name="Path 1356" d="M10.933,16.152a.624.624,0,0,0-.51-.131L.506,17.88A.618.618,0,0,0,0,18.488V32.125a.619.619,0,0,0,.506.609l9.918,1.86a.593.593,0,0,0,.114.011.62.62,0,0,0,.62-.62V16.629A.618.618,0,0,0,10.933,16.152ZM9.918,33.237,1.24,31.611V19l8.678-1.626Z" transform="translate(0 -16.01)" fill="#4d4d4d"/>
                    </g>
                    </g>
                    <g id="Group_2928" data-name="Group 2928" transform="translate(9.918 18.488)">
                    <g id="Group_2927" data-name="Group 2927" transform="translate(0 0)">
                        <path className='cls-1' id="Path_1357" data-name="Path 1357" d="M265.3,79.99H256.62a.62.62,0,0,0,0,1.24h8.058V92.387H256.62a.62.62,0,0,0,0,1.24H265.3a.62.62,0,0,0,.62-.62V80.61A.62.62,0,0,0,265.3,79.99Z" transform="translate(-256 -79.99)" fill="#4d4d4d"/>
                    </g>
                    </g>
                    <g id="Group_2930" data-name="Group 2930" transform="translate(9.918 20.968)">
                    <g id="Group_2929" data-name="Group 2929">
                        <path className='cls-1' id="Path_1358" data-name="Path 1358" d="M259.1,143.99H256.62a.62.62,0,1,0,0,1.24H259.1a.62.62,0,1,0,0-1.24Z" transform="translate(-256 -143.99)" fill="#4d4d4d"/>
                    </g>
                    </g>
                    <g id="Group_2932" data-name="Group 2932" transform="translate(9.918 23.447)">
                    <g id="Group_2931" data-name="Group 2931">
                        <path className='cls-1' id="Path_1359" data-name="Path 1359" d="M259.1,207.99H256.62a.62.62,0,1,0,0,1.24H259.1a.62.62,0,1,0,0-1.24Z" transform="translate(-256 -207.99)" fill="#4d4d4d"/>
                    </g>
                    </g>
                    <g id="Group_2934" data-name="Group 2934" transform="translate(9.918 25.927)">
                    <g id="Group_2933" data-name="Group 2933">
                        <path className='cls-1' id="Path_1360" data-name="Path 1360" d="M259.1,271.99H256.62a.62.62,0,0,0,0,1.24H259.1a.62.62,0,1,0,0-1.24Z" transform="translate(-256 -271.99)" fill="#4d4d4d"/>
                    </g>
                    </g>
                    <g id="Group_2936" data-name="Group 2936" transform="translate(9.918 28.406)">
                    <g id="Group_2935" data-name="Group 2935">
                        <path className='cls-1' id="Path_1361" data-name="Path 1361" d="M259.1,335.99H256.62a.62.62,0,0,0,0,1.24H259.1a.62.62,0,1,0,0-1.24Z" transform="translate(-256 -335.99)" fill="#4d4d4d"/>
                    </g>
                    </g>
                    <g id="Group_2938" data-name="Group 2938" transform="translate(14.876 20.968)">
                    <g id="Group_2937" data-name="Group 2937">
                        <path className='cls-1' id="Path_1362" data-name="Path 1362" d="M385.86,143.99h-1.24a.62.62,0,1,0,0,1.24h1.24a.62.62,0,1,0,0-1.24Z" transform="translate(-384 -143.99)" fill="#4d4d4d"/>
                    </g>
                    </g>
                    <g id="Group_2940" data-name="Group 2940" transform="translate(14.876 23.447)">
                    <g id="Group_2939" data-name="Group 2939">
                        <path className='cls-1' id="Path_1363" data-name="Path 1363" d="M385.86,207.99h-1.24a.62.62,0,1,0,0,1.24h1.24a.62.62,0,1,0,0-1.24Z" transform="translate(-384 -207.99)" fill="#4d4d4d"/>
                    </g>
                    </g>
                    <g id="Group_2942" data-name="Group 2942" transform="translate(14.876 25.927)">
                    <g id="Group_2941" data-name="Group 2941">
                        <path className='cls-1' id="Path_1364" data-name="Path 1364" d="M385.86,271.99h-1.24a.62.62,0,0,0,0,1.24h1.24a.62.62,0,0,0,0-1.24Z" transform="translate(-384 -271.99)" fill="#4d4d4d"/>
                    </g>
                    </g>
                    <g id="Group_2944" data-name="Group 2944" transform="translate(14.876 28.406)">
                    <g id="Group_2943" data-name="Group 2943">
                        <path className='cls-1' id="Path_1365" data-name="Path 1365" d="M385.86,335.99h-1.24a.62.62,0,0,0,0,1.24h1.24a.62.62,0,0,0,0-1.24Z" transform="translate(-384 -335.99)" fill="#4d4d4d"/>
                    </g>
                    </g>
                    <g id="Group_2946" data-name="Group 2946" transform="translate(3.099 22.207)">
                    <g id="Group_2945" data-name="Group 2945">
                        <path className='cls-1' id="Path_1366" data-name="Path 1366" d="M85.43,181.155,81.092,176.2a.62.62,0,0,0-.933.816L84.5,181.97a.62.62,0,0,0,.933-.816Z" transform="translate(-80.004 -175.984)" fill="#4d4d4d"/>
                    </g>
                    </g>
                    <g id="Group_2948" data-name="Group 2948" transform="translate(3.099 21.588)">
                    <g id="Group_2947" data-name="Group 2947">
                        <path className='cls-1' id="Path_1367" data-name="Path 1367" d="M85.339,160.123a.62.62,0,0,0-.87.108L80.13,165.81a.62.62,0,0,0,.978.762l4.339-5.579A.619.619,0,0,0,85.339,160.123Z" transform="translate(-79.999 -159.992)" fill="#4d4d4d"/>
                    </g>
                    </g>
                </g>
            </svg>
        </div>

    )
}

export default BatchExcel
